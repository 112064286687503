import { useCallback } from 'react';
import { StoreApi, useStore } from 'zustand';
import { IPaginationMutableState } from './Pagination';
import { Module } from '../../types/AccessTypes';
import { PagedTableState, usePagedTableStore } from './PagedTableStore';
import { PageableColumnDef } from './PagedTable';
import { useModules } from '../../providers/ModuleContext';

export enum Direction {
  none = 0,
  desc = 1,
  asc = 2
}

export interface ISorting {
  property:string,
  direction:Direction,
}

const directionToSearchString = (direction:Direction) => {
  switch (direction) {
  case Direction.asc:
    return 'asc';
  case Direction.desc:
    return 'desc';
  case Direction.none:
  default:
    return '';
  }
};

export const convertSortingToSortBy = (sorting:ISorting[]) => {
  if (!sorting.length) return null;
  let sortBy = '';
  sorting.forEach((s) => {
    if (s.direction === Direction.none) return;
    const direction = directionToSearchString(s.direction);
    sortBy += `${sortBy.length ? ',' : ''}${s.property}${direction ? ` ${direction}` : ''}`;
  });
  return sortBy;
};

export interface IPagedTableFilter<TQuery> {
  id:string,
  query:IPaginationMutableState&{sortBy?:string}&TQuery,
  setPage: (newPaging:IPaginationMutableState) => void,
  setQuery: (newQuery:TQuery) => void,
  setSorting: (newSorting:ISorting[]) => void,
  sorting:ISorting[],
  appendQuery: (newQuery:TQuery) => void,
  isFiltered:boolean,
  isColumnFiltered:(columndDef:PageableColumnDef<unknown, unknown, unknown>) => boolean,
  reset: () => void,
  tableStore: StoreApi<PagedTableState<TQuery>>
}

export const useAllVulnerabilityModuleOptions = () => {
  const { customerModules } = useModules();

  const modules = customerModules
    .filter((module) => (
      module.id > 0
      && module.id !== Module.customerAdmin
      && (module.jobTypeKeys?.includes('scan') || module.id === Module.assessment)
    ));

  return modules.map((m) => m.id);
};

export const usePagedTableFilter = <TQuery extends object, > (
  id:string,
  emptyQuery:TQuery,
  options?: {
    initialQuery?:TQuery,
    initialSorting?:ISorting[],
    appendSortingFilters?:(sorting:ISorting[], query:TQuery) => TQuery | undefined | null,
    fixedPage?:IPaginationMutableState,
  },
):IPagedTableFilter<TQuery> => {
  const {
    initialQuery, initialSorting, appendSortingFilters, fixedPage,
  } = options ?? {};

  const { store } = usePagedTableStore(id, {
    initialFilters: {
      ...emptyQuery,
      ...initialQuery,
    },
    initialSorting,
  });

  const tableState = useStore(store);

  const appendQuery = useCallback((newQuery:TQuery) => {
    tableState.setQueryFilters({
      ...tableState.queryFilters,
      ...newQuery,
    });
    if (!fixedPage) {
      tableState.setPagination({
        ...tableState.pagination,
        pageIndex: 0,
      });
    }
  }, [fixedPage, tableState]);

  const pageableQuery:IPaginationMutableState&{sortBy?:string}&TQuery = {
    ...tableState.queryFilters,
    ...{
      page: fixedPage?.page ?? tableState.pagination.pageIndex,
      pageSize: fixedPage?.pageSize ?? tableState.pagination.pageSize,
    },
    ...appendSortingFilters ? appendSortingFilters(tableState.sorting, tableState.queryFilters) : {},
  };

  const sortBy = convertSortingToSortBy(tableState.sorting);
  if (sortBy) pageableQuery.sortBy = sortBy;

  return {
    id,
    tableStore: store,
    query: {
      ...pageableQuery,
      page: pageableQuery.page + 1,
    },
    setPage: (newPaging:IPaginationMutableState) => {
      if (newPaging.page === 0) {
        throw new Error(`Invalid page: ${newPaging.page}`);
      }
      tableState.setPagination({
        pageSize: fixedPage?.pageSize ?? newPaging.pageSize,
        pageIndex: fixedPage?.page ?? (newPaging.page - 1),
      });
    },
    setQuery: tableState.setQueryFilters,
    setSorting: tableState.setSorting,
    sorting: tableState.sorting,
    appendQuery,
    isFiltered: JSON.stringify(emptyQuery) !== JSON.stringify(tableState.queryFilters),
    isColumnFiltered: (columnDef:PageableColumnDef<unknown, unknown, unknown>) => (
      pageableQuery[(columnDef.filterPropertyName ?? columnDef.accessorKey) as keyof TQuery] !== undefined
    ),
    reset: () => {
      const newQuery = emptyQuery as TQuery;
      tableState.setQueryFilters(newQuery);
    },
  };
};
