import React, { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { ICustomerEssentials } from '../types/AdminTypes';
import ROUTES from '../routing/Routes';
import useSettingsContext from '../contexts/SettingsContext';
import { useAccount } from '../providers/AccountContext';
import { LoadingCard } from '../routing/LoadingTemplate';

const activateCustomer = async (
  customerToActivate:ICustomerEssentials,
  primaryCustomer:ICustomerEssentials|undefined,
  setActiveCustomer:(id:string|undefined) => void,
  refreshAccess:() => void,
  queryClient:QueryClient,
  navigate:NavigateFunction,
) => {
  if (customerToActivate.id === primaryCustomer?.id) {
    setActiveCustomer(undefined);
  } else {
    setActiveCustomer(customerToActivate.id);
  }

  // Clear query cache, to remove any potential queries for other customer
  queryClient.clear();
  await refreshAccess();
  navigate(ROUTES.dashboard.uri);
};

interface IProps {
  children?:React.JSX.Element | React.JSX.Element[] | string | null | (React.JSX.Element | null)[],
  variant?:string,
}

export const ReturnToPrimaryCustomerButton = (props:IProps) => {
  const { children, variant } = props;
  const {
    refreshAccess, primaryCustomer,
  } = useAccount();

  const { setActiveCustomerId } = useSettingsContext();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const returnToCustomerFn = async () => (!primaryCustomer
    ? undefined
    : activateCustomer(primaryCustomer, primaryCustomer, setActiveCustomerId, refreshAccess, queryClient, navigate));

  return (
    <Button variant={variant} onClick={async () => returnToCustomerFn()}>
      {children ?? 'Return to your primary customer'}
    </Button>
  );
};

export const MultiTenantSelectCustomerPage = () => {
  const {
    customer, isAssociatedTo, canAssociate, refreshAccess, primaryCustomer,
  } = useAccount();

  const { setActiveCustomerId } = useSettingsContext();
  const [hasSelection, setHasSelection] = useState(false);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  useEffect(() => {
    if (canAssociate === false) {
      navigate(-1);
    }
  }, [canAssociate, navigate]);

  const canSwitchTo = useMemo(() => (primaryCustomer && isAssociatedTo
    ? [primaryCustomer, ...isAssociatedTo].filter((c) => c.id !== customer?.id)
    : undefined), [customer?.id, isAssociatedTo, primaryCustomer]);

  return hasSelection
    ? (
      <LoadingCard>
        Activating customer
      </LoadingCard>
    )
    : (
      <Card>
        <Card.Header>
          <Card.Title>
            Switch active organization
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12} className="pb-3">
              { canSwitchTo && customer
                ? (
                  <Typeahead
                    id="multiTenantCustomerSelector"
                    labelKey="name"
                    className="multi-tenant-customer-selector"
                    options={canSwitchTo}
                    positionFixed
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.code === 'Escape') {
                        navigate(-1);
                      }
                    }}
                    placeholder="Select organization..."
                    clearButton
                    renderMenuItemChildren={(o) => {
                      const item = o as ICustomerEssentials;
                      const isPrimary = primaryCustomer?.id === item.id;

                      return (
                        <div className={isPrimary ? 'primary' : ''}>
                          {item.name}
                          {isPrimary ? ' (primary organization)' : ''}
                        </div>
                      );
                    }}
                    onChange={(options) => {
                      if (options.length > 0) {
                        setHasSelection(true);
                        activateCustomer(
                          options.map((o) => o as ICustomerEssentials)[0],
                          primaryCustomer,
                          setActiveCustomerId,
                          refreshAccess,
                          queryClient,
                          navigate,
                        );
                      }
                    }}
                  />
                )
                : <Spinner animation="border" /> }
            </Col>
            <Col md={12}>
              <Stack gap={3} direction="horizontal">
                <Button
                  variant="secondary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
};

export default MultiTenantSelectCustomerPage;
