import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { createAlgizRouter } from './Router';
import { useAccount } from '../providers/AccountContext';
import { ValidPageSizes } from '../common/table/Pagination';
import { getDefaultPagination } from '../common/table/PagedTableStore';
import { useActivateCustomerFromQuery } from './useActivateCustomerFromQuery';

export const AuthenticatedApp = () => {
  const queryClient = useQueryClient();
  const account = useAccount();

  const { pagination } = getDefaultPagination();

  const router = createAlgizRouter(
    queryClient,
    account,
    pagination.pageSize as ValidPageSizes,
  );

  useActivateCustomerFromQuery();

  return (
    <RouterProvider
      router={router}
    />
  );
};
