/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Button,
  Form, ListGroup, ListGroupItem, Modal,
} from 'react-bootstrap';
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useDashboardStore } from './DashboardStore';
import { DashboardTile, useDashboardTiles } from './DashboardPage';

const GrabHandle = () => (
  <svg height="24" width="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="9" cy="5" r="1" />
    <circle cx="15" cy="5" r="1" />
    <circle cx="9" cy="12" r="1" />
    <circle cx="15" cy="12" r="1" />
    <circle cx="9" cy="19" r="1" />
    <circle cx="15" cy="19" r="1" />
  </svg>
);

const DraggableTile = ({
  tile,
  removeTile,
}:{
  tile: DashboardTile,
  removeTile: () => void,
 }) => {
  const {
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: tile.id });

  const style: React.CSSProperties = {
    transform: transform ? `translate3d(0px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  const [zIndex, setZIndex] = useState(0);

  return (
    <ListGroupItem
      ref={setNodeRef}
      className="list-group-item d-flex justify-content-between"
      style={{
        ...style,
        zIndex,
      }}
      onPointerDown={() => setZIndex(1)}
      onPointerUp={() => setZIndex(0)}
      onKeyDown={() => setZIndex(1)}
      onKeyUp={() => setZIndex(0)}
    >
      <Form.Check
        disabled={tile.disableHide}
        id={tile.id.toString()}
        type="checkbox"
        className="flex-glow-1"
        label={tile.title}
        checked
        onPointerDown={(e) => e.stopPropagation()}
        onChange={removeTile}
      />
      {!tile.fixedPosition && (
        <Button
          variant="white"
          className="icon-wrapper"
          {...listeners}
        >
          <GrabHandle />
        </Button>
      )}
    </ListGroupItem>
  );
};

export const DashboardSelectTilesModal = ({
  show,
  handleClose,
}:{
  show: boolean;
  handleClose: () => void;
}) => {
  const { tileIds, updateTileIds } = useDashboardStore();
  const dashboardTiles = useDashboardTiles();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = tileIds.indexOf(active.id as number);
      const newIndex = tileIds.indexOf(over.id as number);
      if (newIndex >= 0 && newIndex < tileIds.length) {
        updateTileIds(arrayMove(tileIds, oldIndex, newIndex));
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="dashboard-select-tiles">
      <Form>
        <Modal.Header closeButton>
          <strong>Select modules and drag to re-order</strong>
        </Modal.Header>
        <Modal.Body>
          {dashboardTiles.length ? (
            <>
              {tileIds.some((id) => dashboardTiles.some((t) => t.id === id)) && (
                <Form.Group>
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragOver={handleDragEnd}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  >
                    <SortableContext items={tileIds} strategy={verticalListSortingStrategy}>
                      <ListGroup variant="flush">
                        {tileIds.map((tileId) => {
                          const tile = dashboardTiles.find((t) => t.id === tileId && !t.hideFromSelector);
                          return tile
                            ? (
                              <DraggableTile
                                key={tile.id}
                                tile={tile}
                                removeTile={() => {
                                  updateTileIds([...tileIds.filter((id) => id !== tile.id)]);
                                }}
                              />
                            )
                            : null;
                        })}
                      </ListGroup>
                    </SortableContext>
                  </DndContext>
                </Form.Group>
              )}
              <Form.Group className={tileIds.length ? 'mt-3' : ''}>
                {dashboardTiles.filter((x) => !x.hideFromSelector && !tileIds.includes(x.id)).map((tile) => (
                  <Form.Check
                    key={tile.id}
                    id={tile.id.toString()}
                    type="checkbox"
                    label={tile.title}
                    checked={tileIds.includes(tile.id)}
                    onChange={() => updateTileIds([...tileIds, tile.id])}
                  />
                ))}
              </Form.Group>
            </>
          ) : (
            <div className="mb-3">You do not have access to any dashboard tiles.</div>
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default DashboardSelectTilesModal;
