import { ActiveStatus, ICustomerEssentials } from './AdminTypes';
import { Indexable, SettingValue } from './Types';

export const ALL_ROLES = ['none', 'readOwn', 'read', 'readWrite', 'admin'] as const;
export type Role = (typeof ALL_ROLES)[number];

// `modules` table in database is the master for these ids.
// These ids should be kept up to date with database ids for module check to
// work as expected.
export enum Module {
  none = 0,
  assets = 1,
  risk = 2,
  m365 = 3,
  admin = 4,
  customerAdmin = 12,
  vulnerability = 13,
  assessment = 14,
  mailAuthenticity = 15,
  m365Configure = 16,
  defender = 17,
  entraId = 18,
  riskUnmanaged = 19,
  secOpsAccess = 20,
  reporting = 21,
  entraIdAssets = 22,
  entraIdIdentities = 23,
  identity = 24
}

/**
 * Modules that are exposed to customers, and that customers should be
 * able to toggle permissions and visibility of.
 */
export const CustomerVisibleModules:Module[] = [
  Module.assets,
  Module.risk,
  Module.customerAdmin,
  Module.vulnerability,
  Module.risk,
  Module.riskUnmanaged,
  Module.reporting,
  Module.identity,
];

export const VulnerabilityModules:Module[] = [
  Module.assessment,
  Module.defender,
  Module.entraId,
  Module.m365,
  Module.mailAuthenticity,
];

export const ALL_JOB_TYPE_KEYS = ['scan', 'get-control-actions', 'execute-control-action'];
ALL_JOB_TYPE_KEYS.sort((a, b) => a.localeCompare(b));
export type JobTypeKeys = (typeof ALL_JOB_TYPE_KEYS)[number];

export interface IBaseModule {
  id: number,
  name: string,
}

export interface IModule extends IBaseModule {
  parent: IModule|undefined,
  jobTypeKeys?: JobTypeKeys[]|undefined
}

export interface ICustomerUpdateModule {
  moduleId: number,
  expires?: string|undefined
}

export interface ICustomerModule {
  module: IBaseModule,
  customerId?: string|undefined,
  expires?: string|undefined
}

export interface ICompany extends ICustomerEssentials {
  customerModules: ICustomerModule[],
  trialModules: ICustomerModule[],
}

export type AccountProvider = 'azureEntraIdUser'|'azureAppRegistration';

export interface IUser {
  id:string,
  name?:string,
  email?:string,
  externalId:string,
  active:boolean,
  provider:AccountProvider,
  status:ActiveStatus
}

export interface IAccount extends IUser {
  associatedTenantIds: string[]
}

export type IAccess = Record<Exclude<Module, 'customer_admin'>, boolean> & Indexable<boolean>
export type IAccessLevel = Record<number, Role> & Indexable<Role>;

export interface IModuleRole {
  module: IModule,
  role: Role
}

export interface IHaveModuleRoles {
  moduleRoles: Record<number, Role>,
}

export interface AccessDTO extends IAccount {
  customer: ICompany,
  customerSettings:Record<number, Record<string, SettingValue>>,
  modules: Record<number, {moduleId:number, role:Role}>,
  canAssociate: boolean,
  isAssociatedTo: ICustomerEssentials[]|undefined
  primaryCustomer: ICustomerEssentials,
  tenantIds:string
}

export interface IEffectiveRoles {
  userRoles: Record<number, Role>,
  effectiveRoles: Record<number, Role>,
}
