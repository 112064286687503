import React from 'react';
import { Formik } from 'formik';
import {
  Button, Col, Form, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import { IAsset } from '../../types/AssetsTypes';
import { assetTypesAsString } from '../../types/Types';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { useModules } from '../../providers/ModuleContext';
import { FormSignificanceSelector } from '../../common/FormSignificanceSelector';
import ROUTES from '../../routing/Routes';
import { useAccount } from '../../providers/AccountContext';
import { Module } from '../../types/AccessTypes';

export const AssetDetailTab = ({
  asset,
}:{
  asset: IAsset
}) => {
  const { getModuleNameOrDefault } = useModules();
  const { hasModuleRole } = useAccount();
  const assetTypeAsText = useAssetTypeAsText();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  const saveMutation = useMutation({
    mutationFn: async (data:IAsset) => axios.put(`/api/v1/assets/${encodeURIComponent(asset.id)}`, {
      ...data,
      significance: data.significance?.length ? data.significance : null,
    }),
    onSuccess: async () => {
      toast.success('Asset was saved', {
        toastId: 'asset-save',
        updateId: 'asset-save',
      });
      await invalidateVulnerabilities();
    },
  });

  return (
    <Formik
      initialValues={asset}
      onSubmit={async (values, helpers) => {
        await saveMutation.mutateAsync(values);
        helpers.resetForm({ values });
      }}
    >
      {({
        values, handleSubmit, handleChange, dirty, handleReset,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8} className="mb-3">
              <div>
                <Form.Label>Name:</Form.Label>
                <Form.Control disabled value={values.name} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Significance:</Form.Label>
                <FormSignificanceSelector item={values} onChange={handleChange} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Unique ID:</Form.Label>
                <Form.Control disabled value={values?.friendlyId} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label>Source module:</Form.Label>
              <Form.Control
                disabled
                value={getModuleNameOrDefault(asset.sourceModuleId)}
              />
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Type:</Form.Label>
                <Form.Select value={values?.type} disabled>
                  {assetTypesAsString.map((type) => (
                    <option key={type} value={type}>{assetTypeAsText(type, false)}</option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          { asset.owners?.length
            ? (
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Label>Relations:</Form.Label>
                  <ul className="m-0">
                    { asset.owners.map((owner) => (
                      <li key={owner.id}>
                        Owner:
                        {' '}
                        { hasModuleRole(Module.identity, 'read')
                          ? (
                            <Link to={`${ROUTES.identities.uri}/${owner.id}`}>
                              { owner.name }
                            </Link>
                          ) : owner.name }
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            ) : null }
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button type="submit" disabled={!dirty}>Save</Button>
                <Button type="reset" variant="secondary" disabled={!dirty} onClick={handleReset}>Undo</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
