import { useMemo, useState } from 'react';
import { PageableColumnDef } from './PagedTable';
import { IPagedTableFilter } from './PagedResultFilter';
import { getNestedValue } from '../../utils/Utils';

export interface IHeader {
  id?: string,
  canSort: boolean,
  accessorKey: string,
  name: string,
  columnDef: unknown
}

export interface IColumn<TObject, TValue, TFilterValue> {
  id: string,
  isFiltered: boolean,
  isVisible: boolean,
  toggleVisibility: () => void
  canHide: boolean,
  header: IHeader,
  columnDef: PageableColumnDef<TObject, TValue, TFilterValue>
}

export interface ICell<TObject, TValue, TFilterValue> {
  id: string,
  value: TValue,
  columnDef: PageableColumnDef<TObject, TValue, TFilterValue>,
  render: () => string|React.JSX.Element|React.JSX.Element[]|undefined|null
}

export interface IRow<TObject, TValue, TFilterValue> {
  id: string,
  original: TObject,
  visibleCells: ICell<TObject, TValue, TFilterValue>[],
  isExpanded: boolean,
  toggleExpanded: () => void
}

const hasFilterValue = (value:unknown) => {
  if (value === null) {
    return false;
  }
  if (value instanceof Array) {
    return value && value.length > 0;
  }
  return !!value;
};

export type VisibilityState = Record<string, boolean>;

const isFiltered = <TObject, TValue, TFilterValue, TQuery, >(
  tableFilter:IPagedTableFilter<TQuery>,
  columnDef:PageableColumnDef<TObject, TValue, TFilterValue>,
) => {
  const filterPropertyName = columnDef.filterPropertyName ?? columnDef.accessorKey;
  if (filterPropertyName) {
    const entry = Object.entries(tableFilter.query as object).find(([k]) => k === filterPropertyName);
    if (entry) {
      const [, value] = entry;
      return hasFilterValue((value === undefined || Array.isArray(value))
        ? value
        : [value] as object[]);
    }
  }
  return false;
};

export const useTableModel = <TObject, TValue, TFilterValue, TQuery, >(
  items:TObject[]|undefined,
  columnDefs:PageableColumnDef<TObject, TValue, TFilterValue>[],
  visibilityState:VisibilityState,
  setVisibilityState:(VisibilityState:VisibilityState) => void,
  tableFilter:IPagedTableFilter<TQuery>,
) => {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const allColumns:IColumn<TObject, TValue, TFilterValue>[] = useMemo(() => (
    columnDefs.map((columnDef) : IColumn<TObject, TValue, TFilterValue> => ({
      id: columnDef.accessorKey,
      isVisible: visibilityState[columnDef.accessorKey] !== false,
      isFiltered: isFiltered(tableFilter, columnDef),
      canHide: !columnDef.disableHiding,
      columnDef,
      header: {
        id: columnDef.accessorKey,
        name: columnDef.header ? `${columnDef.header}` : columnDef.accessorKey,
        canSort: columnDef.disableSorting !== true,
        accessorKey: columnDef.accessorKey,
        columnDef,
      },
      toggleVisibility: () => {
        setVisibilityState({
          ...visibilityState,
          [columnDef.accessorKey]: !visibilityState[columnDef.accessorKey],
        });
      },
    }))), [columnDefs, setVisibilityState, tableFilter, visibilityState]);

  const visibleColumns = allColumns.filter((c) => c.isVisible);
  const headers = visibleColumns.map((c) => c.header);

  const rows:IRow<TObject, TValue, TFilterValue>[] = useMemo(() => {
    let rowId = 0;
    if (!items) {
      return [];
    }
    return items.map((i) => {
      rowId += 1;
      const row:IRow<TObject, TValue, TFilterValue> = {
        id: `${rowId}`,
        original: i,
        isExpanded: expandedRows[rowId] === true,
        toggleExpanded: () => {
          setExpandedRows({
            [rowId]: expandedRows[rowId],
          });
        },
        visibleCells: [],
      };

      row.visibleCells = visibleColumns.map((column) => {
        const cellValue = getNestedValue(i, column.id) as TValue;

        return {
          id: column.id,
          columnDef: column.columnDef,
          value: cellValue,
          render: () => (typeof column.columnDef.cell === 'function'
            ? column.columnDef.cell({
              value: cellValue,
              row,
            })
            : cellValue === undefined || cellValue === null ? '' : `${cellValue}`),
        };
      });

      return row;
    });
  }, [expandedRows, items, visibleColumns]);

  return {
    headers,
    visibleColumns,
    allColumns,
    rows,
  };
};
