import React, { RefObject, useCallback, useMemo } from 'react';
import {
  Badge, OverlayTrigger, ProgressBar, Table, Tooltip,
} from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ROUTES from '../../routing/Routes';
import {
  IVulnerability, IControl, VulnerabilityStatus, SecurityLevel, IAggregatedVulnerabilityStatus,
} from './Types';
import {
  aggregateVulnerabilities, findMostSevereVulnerabilityStatus,
  vulnerabilityStatusAndSeverityAsColorClassNames,
} from './Utils';
import {
  useVulnerabilityStatusAsControlImplementationText, useGetSeverityAsText,
} from '../../utils/TranslationUtils';
import RenderHtml from '../../components/RenderHtml';
import { severityAsCssClassName } from '../vulnerabilities/Utils';

interface IControlSummary {
  aggregatedVulnerabilityStatus:IAggregatedVulnerabilityStatus,
  control:IControl,
  vulnerabilities:IVulnerability[],
}

const hasVisibleVulnerabilities = (
  hiddenStatuses:VulnerabilityStatus[]|undefined,
  summary:IControlSummary[],
) => summary.reduce(
  (prev, item) => prev || (
    !hiddenStatuses || item.aggregatedVulnerabilityStatus.statuses.some((s) => !hiddenStatuses.includes(s))
  ),
  false,
);

/**
 * React component for displaying vulnerabilities grouped by topic.
 * Topic implementation percent is also presented.
 *
 * @param props any attributes set on this component
 */
export function VulnerabilityTopicTable(props: {
  name: string,
  progress: number,
  data: Record<string, {control:IControl, vulnerabilities:IVulnerability[]}>,
  hiddenStatuses?: VulnerabilityStatus[],
  hiddenSecurityLevels?: SecurityLevel[]
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    name, progress, data, hiddenStatuses, hiddenSecurityLevels,
  } = props;

  const severityAsText = useGetSeverityAsText();
  const vulnerabilityStatusAsControlImplementationText = useVulnerabilityStatusAsControlImplementationText();

  const refs = useMemo(
    () => (
      Object.values(data).reduce(
        (acc, v) => {
          // eslint-disable-next-line no-param-reassign
          acc[v.control.id] = React.createRef<HTMLTableRowElement|null>();
          return acc;
        },
        {} as Record<string, RefObject<HTMLTableRowElement|null>>,
      )),
    [data],
  );

  // Groups vulnerabilities by control
  const vulnsByControl = useMemo(() => Object.values(data).map((value) : IControlSummary => {
    const { control, vulnerabilities } = value;
    return {
      aggregatedVulnerabilityStatus: aggregateVulnerabilities(vulnerabilities),
      control,
      vulnerabilities,
    };
  }), [data]);

  // A value indicating whether the topic has any visible controls
  const anyVisibleControls = useMemo(() => Object.values(data).some((i) => {
    if (i.control && !hiddenSecurityLevels?.includes(i.control.securityLevel)) {
      return i.vulnerabilities.some((v) => (
        !hiddenStatuses || !hiddenStatuses.includes(v.status)
      ));
    }
    return false;
  }), [data, hiddenSecurityLevels, hiddenStatuses]);

  const framework = searchParams.get('framework');

  const getControlUrl = useCallback((control:IControl) => {
    const params = new URLSearchParams();
    if (framework && framework !== 'ivolv') {
      params.append('framework', framework);
    }
    return `${ROUTES.control.uriWithoutQuestion()}/${control.id}?${params.toString()}`;
  }, [framework]);

  // Do not output topic if there are no visible controls and vulnerabilities
  return (
    <div className="vulnerability-topic-table">
      <h3>{name}</h3>
      <ProgressBar striped now={progress} label={`${progress}%`} />
      { anyVisibleControls && hasVisibleVulnerabilities(hiddenStatuses, vulnsByControl)
        ? (
          <Table className="table-sm vuln-control-table" hover>
            <thead>
              <tr>
                <th style={{ width: '10%' }}>Control</th>
                <th style={{ width: '45%' }}>Name</th>
                <th style={{ width: '45%' }}>Description</th>
              </tr>
            </thead>
            <tbody>
              {vulnsByControl.map((value) => {
                const { control, aggregatedVulnerabilityStatus } = value;

                if (hiddenSecurityLevels?.includes(control.securityLevel)) {
                  return null;
                }

                const status = findMostSevereVulnerabilityStatus(aggregatedVulnerabilityStatus.statuses);
                const aggregateHasVisibleStatuses = aggregatedVulnerabilityStatus.statuses.some((s) => (
                  !hiddenStatuses?.includes(s)
                ));

                return !aggregatedVulnerabilityStatus.severity || !aggregateHasVisibleStatuses
                  ? null
                  : (
                    <tr
                      ref={refs[control.id]}
                      key={control.id}
                      role="button"
                      onClick={() => navigate(getControlUrl(control))}
                    >
                      <td>
                        <OverlayTrigger
                          overlay={(
                            <Tooltip>
                              <div className="status">
                                { status === VulnerabilityStatus.Unknown
                                  ? 'Implementation status not yet reported'
                                  : vulnerabilityStatusAsControlImplementationText(status)}
                              </div>
                              { status === VulnerabilityStatus.Open
                                ? (
                                  <div>
                                    Severity:
                                    {' '}
                                    <Badge
                                      style={{ fontSize: '0.7rem' }}
                                      bg="none"
                                      pill
                                      className={`text-shadow ${severityAsCssClassName(aggregatedVulnerabilityStatus.severity)}`}
                                    >
                                      {severityAsText(aggregatedVulnerabilityStatus.severity)}
                                    </Badge>
                                  </div>
                                ) : null }
                            </Tooltip>
                          )}
                        >
                          <Badge bg="none" className={`id-badge ${vulnerabilityStatusAndSeverityAsColorClassNames(status, aggregatedVulnerabilityStatus.severity)}`}>
                            {control.friendlyId}
                          </Badge>
                        </OverlayTrigger>
                      </td>
                      <td>{control.name}</td>
                      <RenderHtml as="td">
                        { control.description }
                      </RenderHtml>
                    </tr>
                  );
              })}
            </tbody>
          </Table>
        )
        : (
          <div className="my-3">
            There are no visible vulnerabilities for this topic.
            <hr />
          </div>
        ) }
    </div>
  );
}

export default VulnerabilityTopicTable;
