import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAsset } from '../../types/AssetsTypes';
import { PagedResult } from '../../types/PagedResult';
import { useAssetTypeAsText, useGetSignificanceAsText } from '../../utils/TranslationUtils';
import {
  AssetType, assetTypesAsString, IAssetListOptions, IKeyValue,
} from '../../types/Types';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import {
  createPagedColumnHelper, PagedTable, TableCellDateFormatted, IPagedTableFilter,
  usePagedTableFilter,
} from '../../common/table';
import { useModules } from '../../providers/ModuleContext';
import { useAccount } from '../../providers/AccountContext';
import { Module } from '../../types/AccessTypes';
import { useApi } from '../../query/GenericQuery';
import { Significance } from '../vulnerabilities/Types';
import { compareSignificance } from '../vulnerabilities/Utils';
import { allSignificances } from '../vulnerabilities/VulnerabilitiesTable';

interface IAssetAndAdvisories extends IAsset {
  securityAdvisoryIds?:string[]
}

const assetProvidingModules = [
  Module.assessment,
  Module.entraId,
  Module.mailAuthenticity,
];

/**
 * Table for showing assets.
 */
export const AssetsTable = ({
  pagedAssets,
  pagedFilters,
  isLoading,
}:{
  pagedAssets?: PagedResult<IAsset>,
  pagedFilters: IPagedTableFilter<IAssetListOptions>,
  isLoading?: boolean
}) => {
  const navigate = useNavigate();
  const assetTypeAsText = useAssetTypeAsText();
  const { getModuleNameOrDefault } = useModules();
  const { customerHasModule } = useAccount();

  const columnHelper = createPagedColumnHelper<IAssetAndAdvisories>();

  const { appendQuery, query } = pagedFilters;

  const significanceAsText = useGetSignificanceAsText();

  const assetModuleIds = useMemo(() => (
    assetProvidingModules.filter((m) => customerHasModule(m))
  ), [customerHasModule]);

  const assetColumns = React.useMemo(() => {
    const columns = [
      columnHelper.accessor('friendlyId', {
        header: 'Unique ID',
        cell: ({ value }) => (value ? <ClipboardCopy>{value}</ClipboardCopy> : null),
        disableColumnDefault: true,
        defaultHidden: true,
        sortPropertyName: 'friendlyId',
        filterPropertyName: 'assetFriendlyIds',
        updateFilterFn: (values: string[]) => {
          appendQuery({
            assetFriendlyIds: values,
          });
        },
      }),
      {
        ...columnHelper.accessor('sourceModuleId', {
          header: 'Module',
          cell: ({ value }) => (value ? getModuleNameOrDefault(value) : null),
          filterPropertyName: 'sourceModuleId',
          formatter: (moduleId) => getModuleNameOrDefault(moduleId),
          defaultHidden: true,
          selectOptions: assetModuleIds,
          updateFilterFn: (values: string[]) => {
            const moduleId = values.length
              ? parseInt(values[0], 10)
              : undefined;
            appendQuery({
              sourceModuleId: Number.isNaN(moduleId) ? undefined : moduleId,
            });
          },
        }),
      },
      {
        ...columnHelper.accessor('name', {
          header: 'Name',
          disableHiding: true,
          cell: ({ value }) => <ClipboardCopy>{value}</ClipboardCopy>,
          filterPropertyName: 'name',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              name: values.length ? values[0] : undefined,
            });
          },
        }),
      },
      columnHelper.accessor('type', {
        header: 'Type',
        cell: ({ value }) => (value ? <span>{assetTypeAsText(value)}</span> : null),
        formatter: (v: AssetType) => assetTypeAsText(v, false),
        filterPropertyName: 'types',
        sortPropertyName: 'type',
        updateFilterFn: (values: AssetType[]) => {
          appendQuery({
            types: values,
          });
        },
        selectOptions: assetTypesAsString,
        supportMultiSelect: true,
      }),
      columnHelper.accessor('created', {
        header: 'Created',
        cell: ({ value }) => TableCellDateFormatted(value),
        disableFilter: true,
        filterPropertyName: 'created',
      }),
      columnHelper.accessor('significance', {
        header: 'Significance',
        cell: ({ value, row }) => significanceAsText(value ?? row.original.systemSignificance),
        formatter: (significance) => significanceAsText(significance),
        defaultHidden: true,
        selectOptions: allSignificances,
        sortFn: (a, b) => compareSignificance(a, b),
        updateFilterFn: (values: Significance[]) => {
          appendQuery({
            significance: values.length ? values : undefined,
          });
        },
      }),
      columnHelper.accessor('vulnerabilityCount', {
        header: 'Vulnerability count',
        cell: ({ value }) => TableCellDateFormatted(value),
        disableFilter: true,
        filterPropertyName: 'vulnerabilityCount',
      }),
      {
        ...columnHelper.accessor('properties', {
          header: 'Properties',
          cell: ({ value }) => (
            <ul>
              {value?.map((p) => (
                <li key={p.key}>
                  {p.key}
                  =
                  {p.value}
                </li>
              ))}
            </ul>
          ),
          defaultHidden: true,
          filterPropertyName: 'properties',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              properties: values.length
                ? values[0].split(',').reduce((acc, pair) => {
                  const [key, value] = pair.split('=');
                  if (key && value) {
                    acc.push({ key: key.trim(), value: value.trim() });
                  }
                  return acc;
                }, [] as IKeyValue[])
                : undefined,
            });
          },
          filterFormatter: (values: unknown[]) => (values
            ? (values as IKeyValue[])
              .map((v) => `${v.key}=${v.value}`, '')
              .join(', ')
            : ''),
        }),
      },
    ];

    // Only add securityAdvisoryIds if filtered. If filtered, we know the
    // asset has the advisory and add that from the filter. We do not
    // expand this server side, due to the database cost.
    if (query.securityAdvisoryIds?.length) {
      columns.push(
        columnHelper.accessor('securityAdvisoryIds', {
          header: 'Security Advisory',
          cell: ({ value }) => (value ? (
            <>
              {value.map((advisoryId) => (
                <ClipboardCopy key={advisoryId}>{advisoryId}</ClipboardCopy>
              ))}
            </>
          ) : null),
          defaultHidden: true,
          filterPropertyName: 'securityAdvisoryIds',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              securityAdvisoryIds: values.length ? values : undefined,
            });
          },
        }),
      );
    }

    return columns;
  }, [
    columnHelper,
    assetModuleIds,
    query.securityAdvisoryIds?.length,
    appendQuery,
    getModuleNameOrDefault,
    assetTypeAsText,
    significanceAsText,
  ]);

  const onRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, object: IAssetAndAdvisories) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/asset/${object?.id}`, '_blank');
      } else {
        navigate(`/asset/${object?.id}`);
      }
    }
  };

  return (
    <div>
      <PagedTable
        columnDefs={assetColumns}
        data={pagedAssets}
        filters={pagedFilters}
        onRowClick={onRowClick}
        isLoading={isLoading}
      />
    </div>
  );
};

export const IdentityAssetsTable = ({
  identityId,
}:{
  identityId:string
}) => {
  const pagedTableFilter = usePagedTableFilter<IAssetListOptions>(
    'identity-assets-table',
    {},
    {
      initialQuery: { identityId },
    },
  );

  const { data: pagedAssets } = useApi<PagedResult<IAsset>>(
    'assets',
    {
      ...pagedTableFilter.query,
      identityId,
    },
  );

  return <AssetsTable pagedAssets={pagedAssets} pagedFilters={pagedTableFilter} />;
};
