import React, { useMemo } from 'react';
import { ISupportProperties, IProperty } from '../types/Types';
import { Direction, usePagedTableFilter } from '../common/table/PagedResultFilter';
import { PagedTable, copyableTableValueCell, createPagedColumnHelper } from '../common/table/PagedTable';
import { useArrayAsPagedResult } from '../common/table/useArrayAsPagedResult';

interface IPropertyFilter {
  namespace?:string,
  key?:string,
  value?:string
}

export const PropertiesTable = ({ properties }:ISupportProperties&{entityId:string}) => {
  const columnHelper = createPagedColumnHelper<IProperty>();

  const tableFilters = usePagedTableFilter<IPropertyFilter>(
    'entity-properties-table',
    {},
    {
      initialSorting: [
        { property: 'key', direction: Direction.asc },
      ],
    },
  );

  const { appendQuery } = tableFilters;

  const { namespaces, keys } = useMemo(() => {
    if (!properties) {
      return { namespaces: [], keys: [] };
    }
    const namespaceMap:Record<string, boolean> = {};
    const keyMap:Record<string, boolean> = {};
    properties?.forEach((p) => {
      namespaceMap[p.namespace] = true;
      keyMap[p.key] = true;
    });

    return {
      namespaces: Object.keys(namespaceMap),
      keys: Object.keys(keyMap),
    };
  }, [properties]);

  const tableColumnDefs = useMemo(() => {
    const c = [];

    c.push({
      ...columnHelper.accessor('namespace', {
        header: 'Namespace',
        selectOptions: namespaces,
        updateFilterFn: (values:string[]) => {
          appendQuery({ namespace: values ? values[0] : undefined });
        },
      }),
    });

    c.push({
      ...columnHelper.accessor('key', {
        header: 'Key',
        cell: copyableTableValueCell,
        selectOptions: keys,
        updateFilterFn: (values:string[]) => {
          appendQuery({ key: values ? values[0] : undefined });
        },
      }),
    });

    c.push({
      ...columnHelper.accessor('value', {
        header: 'Value',
        cell: copyableTableValueCell,
        updateFilterFn: (values:string[]) => {
          appendQuery({ value: values ? values[0] : undefined });
        },
      }),
    });

    return c;
  }, [appendQuery, columnHelper, keys, namespaces]);

  const pagedProperties = useArrayAsPagedResult(
    properties,
    tableFilters,
    tableColumnDefs,
  );

  if (!pagedProperties) return null;

  return (
    <PagedTable
      columnDefs={tableColumnDefs}
      data={pagedProperties}
      filters={tableFilters}
      disableColumnSelect
    />
  );
};
