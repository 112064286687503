import React from 'react';
import {
  Navigate,
  Outlet, createBrowserRouter,
} from 'react-router-dom';
import ROUTES from './Routes';
import {
  NoSidebarTemplate,
} from '../AuthenticatedAppTemplate';
import { SignupCard } from '../pages/signin/SignupCard';
import { SignInCard } from '../pages/signin/SignIn';
import { TermsAndConditionsCard } from '../pages/signin/TermsAndConditionsCard';
import { AcceptInviteUnauthenticatedCard } from '../pages/signin/AcceptInviteUnauthenticatedCard';
import { ActionConfirmReturnPage } from '../pages/ActionConfirmReturnPage';

export const createUnauthenticatedAlgizRouter = () => (
  createBrowserRouter(
    [
      {
        element: (
          <NoSidebarTemplate>
            <Outlet />
          </NoSidebarTemplate>
        ),
        children: [
          {
            path: ROUTES.signup.uri,
            element: <SignupCard />,
          },
          {
            path: ROUTES.acceptInvite.uri,
            element: <AcceptInviteUnauthenticatedCard />,
          },
          {
            path: ROUTES.actionTokenReturnPage.uri,
            element: (
              <>
                <ActionConfirmReturnPage />
                <Navigate to="/" replace />
              </>
            ),
          },
          {
            path: '*',
            element: <SignInCard />,
          },
          {
            path: ROUTES.termsAndConditions.uri,
            element: (
              <TermsAndConditionsCard />
            ),
          },
        ],
      },
    ],
  )
);
