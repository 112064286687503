import React, { useCallback } from 'react';
import { NoClipDropdown } from '../../NoClipDropdown';
import { getFilterName } from './FilterHelpers';
import { PageableColumnDef } from '../PagedTable';

export const SelectFilter = <TObject, TValue, TFilterValue>({
  columnId,
  columnDef,
  filterValues,
  options,
  size,
  setFilterValues,
  multiselect,
  disabled,
}:{
  columnId: string,
  columnDef: PageableColumnDef<TObject, TValue, TFilterValue>,
  filterValues: unknown[]|null,
  options: readonly unknown[],
  className?: string,
  size?:'sm'|'lg'|undefined,
  setFilterValues:(values:unknown[]|null) => Promise<void>|void,
  multiselect?:boolean,
  disabled?:boolean
}) => {
  const getSanitizedValue = (value:unknown) : unknown|null => (value === undefined || value === null ? null : value);

  const handleChecked = async (checked: boolean, value: unknown) => {
    const sanitizedValue = getSanitizedValue(value);
    if (checked && sanitizedValue) {
      if (!filterValues) {
        await setFilterValues([sanitizedValue]);
      } else {
        const newFilterValue = multiselect ? [...filterValues, sanitizedValue] : [sanitizedValue];
        await setFilterValues(newFilterValue);
      }
    } else if (Array.isArray(filterValues)) {
      const newFilterValue = filterValues.filter((v) => v !== sanitizedValue);
      await setFilterValues(newFilterValue);
    } else {
      await setFilterValues([]);
    }
  };

  const formatValue = useCallback((value:unknown) => {
    if (columnDef.formatter) {
      return columnDef.formatter(value as TValue);
    }
    if (typeof value !== 'undefined' && value !== null && value !== '') {
      return String(value);
    }
    return '<empty>';
  }, [columnDef]);

  return (
    <NoClipDropdown
      className={filterValues?.length ? 'has-selection' : undefined}
      filterLabel={getFilterName(filterValues, 'All', false, formatValue)}
      itemId={(item) => String(item)}
      itemLabel={formatValue}
      filterId={columnId}
      checked={(i) => !!(i && (filterValues ?? []).includes(i))}
      onCheck={handleChecked}
      disabled={options.length === 0 || disabled}
      size={size}
      values={options}
      multiselect={multiselect}
    />
  );
};
