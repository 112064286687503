import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { IControl } from '../vulnerabilities/Types';
import RenderHtml from '../../components/RenderHtml';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import AssessmentDiscardAssetButton from './AssessmentDiscardAssetButton';
import { AssessmentStateManager } from './AssessmentStateManager';

interface IProps {
  stateManager:AssessmentStateManager,
  getControlDetails: (friendlyId:string) => IControl|undefined
}

const AssessmentControlHeaderCard = (props:IProps) => {
  const { stateManager, getControlDetails } = props;

  const assetTypeAsText = useAssetTypeAsText();

  const { asset, control: assessmentControl } = stateManager.getActive();

  if (!assessmentControl) {
    return null;
  }

  const control = getControlDetails(assessmentControl.id);

  return (
    <Card className="header">
      <Card.Header>
        <Card.Title>
          Assessing:
          {' '}
          {asset.name}
          { asset.type !== 'unknown'
            ? ` (${assetTypeAsText(asset.type, false)})`
            : null }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <h5>
          Control:
          {' '}
          {control?.friendlyId}
          {'. '}
          {control?.name}
        </h5>
        <RenderHtml>{control?.description}</RenderHtml>

        <Stack direction="vertical" gap={2} className="pt-3">
          <AssessmentDiscardAssetButton
            stateManager={stateManager}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default AssessmentControlHeaderCard;
