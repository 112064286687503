import React, { useMemo, useState } from 'react';
import {
  Link, useParams,
} from 'react-router-dom';
import {
  Alert, Button, Card, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { FormModal } from '../../common/modal';
import { ProbabilityAndImpactMatrix } from './RiskMatrix';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import ROUTES from '../../routing/Routes';
import { EntityType } from '../../types/EntityTypes';
import { getOrFetchFromApi, useApi, useApiLoaderData } from '../../query/GenericQuery';
import RiskStatusBadge from './RiskStatus';
import { VulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';
import RenderHtml from '../../components/RenderHtml';
import { isRiskManaged } from './Utils';
import RiskForm from './RiskForm';
import {
  IVulnerability, Significance, VulnerabilityStatus,
} from '../vulnerabilities/Types';
import { PagedResult } from '../../types/PagedResult';
import { Module } from '../../types/AccessTypes';
import { IAccountDetails, useAccount } from '../../providers/AccountContext';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { ValidPageSizes } from '../../common/table/Pagination';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IVulnerabilityListOptions } from '../../types/Types';
import { ClipboardCopy } from '../../components/ClipboardCopy';

interface IData {
  risk:IRisk,
  successor:IVulnerability|null,
  matrixSummary:Record<Significance, Record<Significance, number>>|null
}

/**
 * React component (using hooks) for showing details about a given risk. This includes the risk placement in the risk
 * matrix, risk details and changelog.
 */
export const RiskDetailPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{id?:string}|undefined,
  ) => {
    const risk = await getOrFetchFromApi<IRisk>(
      queryClient,
      args?.id && `risks/${args?.id}`,
    );

    return {
      risk,
      successor: risk.moduleRef
        ? await getOrFetchFromApi<IVulnerability>(
          queryClient,
          `risks/successor/${risk?.moduleRef}`,
        )
        : null,
      matrixSummary: args?.id
        ? await getOrFetchFromApi<Record<Significance, Record<Significance, number>>>(
          queryClient,
          `vulnerabilities/severityMatrixSummary?riskId=${encodeURIComponent(risk.riskId)}`,
        )
        : null,
    };
  },
  Component: () => {
    const { id } = useParams();
    const { hasModuleRole } = useAccount();

    const [showEditModal, setShowEditModal] = useState(false);
    const closeEditModal = () => setShowEditModal(false);

    const { data: risk } = useApiLoaderData<IRisk, IData>(
      `risks/${id}`,
      (loaderData) => loaderData.risk,
    );

    const { data: successor } = useApiLoaderData<IVulnerability|null, IData>(
      risk.moduleRef && `risks/successor/${risk.moduleRef}`,
      (loaderData) => loaderData.successor,
    );

    const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
      `risk-${risk.id}-vulnerabilities`,
      {},
      {
        initialQuery: {
          status: [VulnerabilityStatus.Open],
        },
        initialSorting: [
          { property: 'severity', direction: Direction.desc },
        ],
      },
    );

    const { data: matrixSummary } = useApiLoaderData<Record<Significance, Record<Significance, number>>|null, IData>(
      `vulnerabilities/severityMatrixSummary?riskId=${encodeURIComponent(id!)}`,
      (loaderData) => loaderData.matrixSummary,
    );

    const maxMatrixCount = useMemo(() => (
      matrixSummary ? Math.max.apply(null, Object.values(matrixSummary).flatMap((r) => Object.values(r))) : 0
    ), [matrixSummary]);

    const { query, appendQuery } = pagedTableFilter;

    const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
      risk && 'vulnerabilities',
      {
        ...pagedTableFilter.query,
        riskId: risk.riskId,
      },
    );

    return maxMatrixCount === undefined || !risk ? <Spinner animation="border" /> : (
      <div>
        <Row>
          <Col sm={12}>
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title>
                  <Icon name={ROUTES.risk.icon} />
                  {' '}
                  { risk.riskId }
                  {' '}
                  { risk.name }
                  <CardCloseBackButton />
                </Card.Title>
              </Card.Header>
              <Card.Body className="py-3 overflow-auto">
                { successor
                  ? (
                    <Row>
                      <Col md={12}>
                        <Alert variant="info" className="p-3">
                          <div>
                            This is a modified vulnerability-driven risk that is no longer maintained by
                            the application.
                            {' '}
                            { hasModuleRole(Module.risk, 'readWrite')
                              ? 'You '
                              : 'A user with access to the risk module ' }
                            have to manually manage and close the
                            risk as appropriate.
                            <br />
                            <br />
                            Vulnerability
                            {' '}
                            <Link to={`${ROUTES.vulnerabilities.uri}/${successor.id}`}>
                              {successor.control.friendlyId}
                              {'. '}
                              {successor.control.name}
                            </Link>
                            {' '}
                            { successor.risk
                              ? (
                                <span>
                                  associated with risk
                                  {' '}
                                  <Link to={`${ROUTES.risk.uri}/${successor.risk.riskId}`}>
                                    {successor.risk?.riskId}
                                    {'. '}
                                    {successor.risk.name}
                                  </Link>
                                  {' '}
                                </span>
                              )
                              : null }
                            has been added as this risk&apos;s successor
                            {successor.risk ? 's' : ''}
                            .
                            <br />
                            <br />
                            <Stack direction="horizontal" gap={2}>
                              <Button href={`${ROUTES.vulnerabilities.uri}/${successor.id}`} variant="secondary">
                                Goto vulnerability
                                {' '}
                                {successor.control.friendlyId}
                              </Button>
                              { successor.risk
                                ? (
                                  <Button href={`${ROUTES.risk.uri}/${successor.risk?.riskId}`} variant="secondary">
                                    Goto risk
                                    {' '}
                                    {successor.risk?.riskId}
                                  </Button>
                                )
                                : null }
                            </Stack>
                          </div>
                        </Alert>
                      </Col>
                    </Row>
                  )
                  : null }
                <Row>
                  <Col md={risk.status === RiskStatus.Open ? 6 : 12} className="mb-3">
                    <Form>
                      <Row>
                        <Col md={8} className="mb-3">
                          <div className="form-label">Name:</div>
                          <div>{risk.name}</div>
                        </Col>
                        <Col md={4} className="mb-3">
                          <div className="form-label">Status:</div>
                          <RiskStatusBadge status={risk.status} />
                        </Col>
                        <Col md={12} className="mb-3">
                          <div className="form-label">Description:</div>
                          <RenderHtml>
                            {risk.description}
                          </RenderHtml>
                        </Col>
                        { isRiskManaged(risk) ? null : (
                          <Col md={12} className="mb-3">
                            <div className="form-label">Owner:</div>
                            <div>{risk.owner}</div>
                          </Col>
                        ) }
                      </Row>
                    </Form>
                  </Col>
                  { risk.status === RiskStatus.Open
                    ? (
                      <Col md={6} className="mb-4">
                        <ProbabilityAndImpactMatrix
                          getCount={(item) => (
                            matrixSummary
                              ? (
                                matrixSummary[item.probability]
                                  ? matrixSummary[item.probability][item.impact]
                                  : 0
                              ) ?? 0
                              : 0
                          )}
                          maxCount={maxMatrixCount}
                          single={maxMatrixCount === 0}
                          onClick={(item) => {
                            if (
                              query.impact?.includes(item.impact)
                              && query.probability?.includes(item.probability)
                            ) {
                              appendQuery({
                                ...query,
                                impact: [],
                                probability: [],
                              });
                            } else {
                              appendQuery({
                                ...query,
                                impact: [item.impact],
                                probability: [item.probability],
                                status: [VulnerabilityStatus.Open],
                              });
                            }
                          }}
                          cellClassName={(data) => (query.impact?.includes(data.impact) && query.probability?.includes(data.probability) ? 'highlight' : '')}
                        />
                      </Col>
                    ) : null }
                </Row>
                { isRiskManaged(risk) ? null : (
                  <>
                    <Row>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Consequence:</div>
                        <div>{risk.consequence}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Response:</div>
                        <div>{risk.response}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Solution:</div>
                        <div>{risk.solution}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Reason:</div>
                        <div>{risk.reason}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Category:</div>
                        <div>{risk.category}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Asset:</div>
                        <div>{risk.asset}</div>
                      </Col>
                    </Row>
                  </>
                ) }
                { isRiskManaged(risk) || !hasModuleRole(Module.riskUnmanaged, 'readWrite')
                  ? null
                  : (
                    <Row className="mb-3">
                      <Col md={12}>
                        <Button type="button" onClick={() => setShowEditModal(true)}>Edit</Button>
                      </Col>
                    </Row>
                  ) }
                <EntityCardFooter
                  timestamps={risk}
                  entity={{ entityId: risk.id, entityType: EntityType.Risk }}
                  visibleId={`${risk.riskId}`}
                  linkRoute={ROUTES.risk}
                  additionalDetails={(
                    <span>
                      <span>
                        Id:
                        <ClipboardCopy className="ms-1 me-2">
                          {risk.id}
                        </ClipboardCopy>
                      </span>
                      <span>
                        RiskId:
                        <ClipboardCopy className="ms-1 me-2">
                          {risk.riskId}
                        </ClipboardCopy>
                      </span>
                    </span>
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { isRiskManaged(risk)
          ? (
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Card.Title>
                      Vulnerabilities
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="overflow-auto">
                    { risk.hasVulnerabilities
                      ? (
                        <VulnerabilitiesTable
                          data={pagedVulnerabilities}
                          filters={pagedTableFilter}
                          hide={{ risk: true }}
                        />
                      )
                      : hasModuleRole(Module.vulnerability, 'read')
                        ? 'The risk has no vulnerabilities.'
                        : 'None of the risks vulnerabilities are assigned to you.' }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )
          : (
            <FormModal
              itemType={`risk ${risk.riskId}`}
              isAdd={false}
              show={showEditModal}
              handleClose={closeEditModal}
              Form={<RiskForm risk={risk} handleClose={closeEditModal} />}
            />
          ) }
      </div>
    );
  },
};

export default RiskDetailPage;
