import React from 'react';
import {
  Alert, Button, Card, Col, Row, Stack,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import AssetSelector from './AssetSelector';
import { IAsset } from '../../types/AssetsTypes';
import AssessmentDiscardButton from './AssessmentDiscardButton';
import { AssessmentStateManager } from './AssessmentStateManager';

interface IProps {
  stateManager: AssessmentStateManager
  sha512: string,
  customerAssets: IAsset[]
}

const AssessmentStartCard = (props:IProps) => {
  const {
    stateManager,
    sha512,
    customerAssets,
  } = props;

  const navigate = useNavigate();

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          { stateManager.getAssetCount() > 0
            ? 'Complete the assessment or select another asset to assess'
            : 'Select an asset to start the assessment' }
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          { stateManager.getAssetCount() === 0
            ? (
              <Col md={12} className="mb-3">
                <p>
                  To start your assessment please select one of your existing assets,
                  or enter the name of a new asset.
                </p>
              </Col>
            )
            : (
              <Col md={12} className="mb-3">
                <p>
                  {stateManager.getAssets()
                    .map<React.ReactNode>((a) => <span key={a.uniqueId} className="fw-bold">{a.name}</span>)
                    .reduce((prev, curr) => [prev, ', ', curr])}
                  {' '}
                  have been assessed,
                  {' '}
                  { stateManager.getVulnerabilityCount() === 0
                    ? 'and no '
                    : (
                      <>
                        and a total of
                        {' '}
                        <span className="fw-bold">{stateManager.getVulnerabilityCount()}</span>
                        {' '}
                      </>
                    ) }
                  {stateManager.getVulnerabilityCount() === 1 ? 'vulnerability' : 'vulnerabilities'}
                  {' '}
                  have been identified.
                </p>
                { stateManager.getSkippedQuestionsCount() > 0
                  ? (
                    <Alert variant="warning" className="p-2">
                      <div>
                        <span className="fw-bold">
                          {stateManager.getSkippedQuestionsCount()}
                        </span>
                        {' '}
                        question
                        {stateManager.getSkippedQuestionsCount() === 1 ? '' : 's'}
                        {' '}
                        have been skipped.

                        <div className="mt-3">
                          The skipped questions has to be answered or asset assessment discarded before
                          the assessment can be completed.
                        </div>
                      </div>
                    </Alert>
                  ) : null }
                { stateManager.canBeFinalized()
                  ? (
                    <>
                      <p>
                        Please select or add another asset, or click
                        {' '}
                        <span className="fst-italic">Complete assessment</span>
                        {' '}
                        to see more details about the findings.
                      </p>
                      <p>
                        You&apos;ll be prompted to save the vulnerabilities on the completion page.
                      </p>
                    </>
                  ) : null }
              </Col>
            )}
          <Col md={6} className="mb-3">
            <AssetSelector
              customerAssets={customerAssets}
              stateManager={stateManager}
            />
          </Col>
          { stateManager.getAssetCount() > 0
            ? (
              <Col md={12} className="mb-3">
                <Stack gap={2} direction="horizontal">
                  { /* Disable complete button if any skipped questions exists */ }
                  { stateManager.canBeFinalized()
                    ? (
                      <Link
                        className="btn btn-primary"
                        role="button"
                        to={`/assessment/confirm/${encodeURIComponent(sha512)}`}
                      >
                        Complete assessment
                      </Link>
                    )
                    : <Button disabled>Complete assessment</Button> }
                  <AssessmentDiscardButton
                    stateManager={stateManager}
                    onDiscarded={() => navigate('/')}
                  />
                </Stack>
              </Col>
            ) : null }
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AssessmentStartCard;
