import { Formik } from 'formik';
import React, { useMemo } from 'react';
import {
  Button, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ModulePermissionsSelect } from './ModulePermissions';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { Role, ICustomerModule } from '../../types/AccessTypes';
import { AdminCustomer, AdminAccount, GroupRole } from '../../types/AdminTypes';
import { filterProperties } from '../../utils/Utils';
import { PagedResult } from '../../types/PagedResult';
import useModalContext from '../../contexts/ModalContext';
import { useAccount } from '../../providers/AccountContext';

interface IProps {
  group:GroupRole,
  customer:AdminCustomer,
  setGroup:(group:GroupRole|undefined) => void,
  isUniqueName:(name:string) => boolean,
  disabled?:boolean,
}

interface IAccountGroup {
  name: string,
  members: string[],
  customerId: string,
}

export const GroupDetails = (props:IProps) => {
  const {
    group, customer, disabled, setGroup, isUniqueName,
  } = props;
  const { refreshAccess } = useAccount();

  const { openConfirmLegacy } = useModalContext();

  const { data: customerModules } = useApi<ICustomerModule[]>(
    `module/admin/customers/${encodeURIComponent(customer.id)}/modules`,
  );

  const invalidateAdminUserPermissions = useInvalidateQueries('module/admin/accounts');

  const invalidateGroupsAndRoles = useInvalidateQueries(
    `module/admin/groups/${ encodeURIComponent(customer.id) }`,
  );

  const { data: pagedCustomerAccounts } = useApi<PagedResult<AdminAccount>>(
    'module/admin/accounts',
    {
      customerId: customer.id,
      sortBy: 'name',
      providers: ['azureEntraIdUser'],
    },
  );

  const saveMutation = useMutation({
    mutationFn: async (data: { groupId: string, group: IAccountGroup }) => axios.put(
      `/api/v1/module/admin/groups/${encodeURIComponent(data.groupId)}`,
      data.group,
    ),
    onSuccess: () => {
      invalidateGroupsAndRoles();
      invalidateAdminUserPermissions();
    },
  });

  const savePermissionsMutation = useMutation({
    mutationFn: async (data: { groupId:string, moduleRoles: Record<number, Role> }) => axios.put(
      `/api/v1/module/admin/groups/${encodeURIComponent(data.groupId)}/permissions`,
      filterProperties(data.moduleRoles, (value:Role) => value === 'none'),
    ),
    onSuccess: async () => {
      await refreshAccess();
      await invalidateGroupsAndRoles();
      await invalidateAdminUserPermissions();
    },
  });

  const deleteGroup = useMutation({
    mutationFn: async (data: {groupId:string}) => axios.delete(
      `/api/v1/module/admin/groups/${encodeURIComponent(data.groupId)}`,
    ),
    onSuccess: async () => {
      setGroup(undefined);
      await invalidateGroupsAndRoles();
      await invalidateAdminUserPermissions();
      toast.success('Role group was deleted');
    },
  });

  const initialValues = useMemo(() => (
    group && pagedCustomerAccounts ? {
      name: group.name,
      members: pagedCustomerAccounts.items.filter(({ id }) => group.members.includes(id)),
      moduleRoles: group.moduleRoles,
    } : undefined
  ), [group, pagedCustomerAccounts]);

  return initialValues
    ? (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, helpers) => {
          // If group name has changed, ensure the new name is unique
          if (values.name !== group.name && !isUniqueName(values.name)) {
            helpers.setFieldError('name', 'A group with this name already exists.');
          } else {
            await saveMutation.mutateAsync({
              groupId: group.id,
              group: {
                name: values.name,
                members: values.members.map((m) => m.id),
                customerId: customer.id,
              },
            });
            await savePermissionsMutation.mutateAsync({
              groupId: group.id,
              moduleRoles: values.moduleRoles,
            });
            toast.success('Group was saved');
            helpers.resetForm({ values });
          }
        }}
      >
        {({
          values, setFieldValue, handleChange, handleSubmit, errors, handleReset, dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Label>Group name:</Form.Label>
                <Form.Control
                  value={values.name}
                  onChange={handleChange}
                  type="text"
                  isInvalid={!!errors.name}
                  disabled={disabled}
                  autoComplete="off"
                  name="name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Label>Accounts:</Form.Label>
                <div>
                  { pagedCustomerAccounts ? (
                    <Typeahead
                      options={pagedCustomerAccounts?.items}
                      selected={values.members}
                      id="membersUpdate"
                      labelKey="name"
                      disabled={disabled}
                      onChange={(selected) => {
                        setFieldValue('members', selected);
                      }}
                      placeholder="Select users"
                      multiple
                    />
                  ) : <Spinner animation="border" /> }
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <Form.Label>Permissions:</Form.Label>
                { customerModules
                  ? (
                    <ModulePermissionsSelect
                      customerModules={customerModules}
                      moduleRoles={values.moduleRoles}
                      disabled={disabled}
                      onChange={(moduleRoles) => {
                        setFieldValue('moduleRoles', moduleRoles);
                      }}
                    />
                  ) : <Spinner animation="border" /> }
              </Col>
              { group && !disabled ? (
                <Col md={12}>
                  <Stack direction="horizontal" gap={2}>
                    <Button
                      type="submit"
                      disabled={!dirty}
                    >
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={!dirty}
                      onClick={handleReset}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      disabled={dirty}
                      onClick={async () => openConfirmLegacy(
                        async (confirmed) => confirmed && deleteGroup.mutateAsync({ groupId: group.id }),
                        'Delete group?',
                      `Are you sure you would like to delete '${group.name}'?`,
                      )}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Col>
              ) : null }
            </Row>
          </Form>
        )}
      </Formik>
    )
    : <Spinner animation="border" />;
};
