import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmModalProps {
  show: boolean,
  title?: string,
  content?: string | React.JSX.Element | React.JSX.Element[],
  okText?: string,
  cancelText?: string,
  value: unknown|undefined,
  onClose: (confirmed: boolean, value: unknown) => void,
  size?: 'sm'|'lg'|'xl',
  okDisabled?:boolean,
  onExited:() => void
}

/**
 * Confirm Modal for creating a react bootstrap confirm instead of relying on browser default/implementation
 *
 * This is added to the GlobalContext, call useGlobalContext() and call the openConfirm
 * with a callback function to call after user has confirmed (or not)
 */
export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    show, onClose, title, content, okText, cancelText, value, size, okDisabled, onExited,
  } = props;

  return (
    <Modal
      show={show}
      onHide={() => onClose(false, undefined)}
      onExited={onExited}
      size={size ?? 'sm'}
    >
      <Modal.Header>
        <Modal.Title>
          {title ?? 'Are you sure?'}
        </Modal.Title>
      </Modal.Header>
      {content ? (
        <Modal.Body>
          {content}
        </Modal.Body>
      ) : null}
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={okDisabled}
          onClick={async () => { onClose(true, value); }}
        >
          { okText ?? 'Ok' }
        </Button>
        { cancelText
          ? (
            <Button
              variant="secondary"
              onClick={async () => { onClose(false, value); }}
            >
              { cancelText ?? 'Cancel' }
            </Button>
          )
          : null }
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
