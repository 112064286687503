import React, { useState } from 'react';
import { useLocation, Link, useNavigation } from 'react-router-dom';
import {
  Button,
  Nav, Navbar, NavDropdown, Spinner,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import ROUTES, { ROUTES_OBJECT } from '../routing/Routes';
import SignOutDropdownItem from './SignOutDropdownItem';
import EntitySearch from './EntitySearch';
import { useSettingsContext } from '../contexts/SettingsContext';
import DashboardSettings from '../pages/dashboard/DashboardSettings';

import './Topbar.css';
import { ToastLogModal, useToastStore } from '../components/Toast';
import { Module } from '../types/AccessTypes';
import { useAccount } from '../providers/AccountContext';
import { useAvailableCustomerData } from '../providers/AvailableCustomerDataProvider';
import { useShowTerms } from '../components/useShowTerms';

interface TopbarProps {
  openCloseSidebar?: () => void,
}

const components = {
  dashboard: DashboardSettings,
} as Record<string, () => React.JSX.Element>;

const Settings = () => {
  const { setSettingsModalVisibile: show } = useSettingsContext();

  const settings = ROUTES_OBJECT.getSettings(window.location.pathname);
  if (!settings) {
    return null;
  }

  const SettingsComponent = components[settings];
  return (
    <div className="top-bar-settings">
      <Button variant="link" onClick={() => show(true)} className="m-0 p-0">
        <Icon name="settings" />
      </Button>
      <SettingsComponent />
    </div>
  );
};

/**
 * The webpage topbar, included automatically on all pages
 *
 * Customer settings and sign out is avaliable from here
 */
export function Topbar({ openCloseSidebar }: TopbarProps) {
  const {
    user, customer, hasModuleRole, hasAnyRole,
  } = useAccount();
  const location = useLocation();
  const [toastModalVisible, setToastModalVisible] = useState(false);
  const toastStore = useToastStore();
  const navigation = useNavigation();
  const availableData = useAvailableCustomerData();
  const showTerms = useShowTerms();

  return (
    <Navbar bg="white" className="pt-1 pb-1 top-bar">
      {openCloseSidebar ? (
        <div
          role="button"
          tabIndex={0}
          title="Sidebar toggle"
          className="sidebar-toggle"
          onClick={() => openCloseSidebar()}
          aria-label="Menu"
          onKeyDown={(e) => (e.key === 'Enter' ? openCloseSidebar() : undefined)}
        >
          <i className="hamburger align-self-center" />
        </div>
      ) : null}
      <Navbar.Brand className="fs-3">
        <span>
          {ROUTES_OBJECT.getTopbarText(
            navigation.state === 'loading' ? navigation.location.pathname : location.pathname,
          )}
        </span>
        { navigation.state === 'loading'
          ? <Spinner animation="border" className="ms-2" size="sm" style={{ height: '20px', width: '20px' }} />
          : null }
      </Navbar.Brand>
      <Settings />
      <Navbar.Collapse className="justify-content-end top-bar-end">
        { hasAnyRole && (availableData.hasAssets || availableData.hasRisks || availableData.hasVulnerabilities)
          ? <EntitySearch />
          : null }
        <Nav>
          <NavDropdown
            title={<Icon name="user" />}
            align="end"
            className="user-dropdown"
          >
            <div>
              <div className="company-name">
                <NavDropdown.ItemText>
                  {customer?.name}
                </NavDropdown.ItemText>
                <NavDropdown.Divider />
              </div>
              {
                user?.name !== '' && user?.name !== user?.externalId
                  ? <NavDropdown.ItemText>{user?.name}</NavDropdown.ItemText>
                  : ''
              }
              <NavDropdown.ItemText>
                {user?.externalId}
              </NavDropdown.ItemText>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to={ROUTES.usersettings.uri}>
                <Icon name="user" size={18} className="me-2" />
                User settings
              </NavDropdown.Item>
              { toastStore.messages.length > 0
                ? (
                  <NavDropdown.Item
                    onClick={() => setToastModalVisible(!toastModalVisible)}
                  >
                    <Icon name="bell" size={18} className="me-2" />
                    Message log
                  </NavDropdown.Item>
                )
                : null }
              <NavDropdown.Divider />
              {user?.active && hasModuleRole(Module.customerAdmin, 'read') ? (
                <>
                  <NavDropdown.Item as={Link} to={ROUTES.customersettings.uri}>
                    <Icon name="settings" size={18} className="me-2" />
                    Customer settings
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </>
              ) : null}
              <NavDropdown.Item onClick={showTerms}>
                <Icon name="file-text" size={18} className="me-2" />
                Terms of use
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <SignOutDropdownItem />
            </div>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <ToastLogModal
        visible={toastModalVisible}
        setVisibility={setToastModalVisible}
      />
    </Navbar>
  );
}

export default Topbar;
