import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card, Form, Spinner, Stack, Table,
} from 'react-bootstrap';
import { QueryClient, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLoaderData } from 'react-router-dom';
import { getOrFetchFromApi, useInvalidateQueries } from '../../query/GenericQuery';
import {
  ALL_JOB_TYPE_KEYS, IModule, JobTypeKeys, Module,
} from '../../types/AccessTypes';
import { toggleInArray } from '../../utils/Utils';
import { useAccount } from '../../providers/AccountContext';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';

interface IAdminModule extends IModule {
  jobTypeKeys: JobTypeKeys[]
}

interface IAdminModuleWithChildren extends IAdminModule {
  children:IAdminModuleWithChildren[]
}

const ModuleRow = ({
  className,
  module,
  disabled,
  onChange,
  leftPadding,
}: {
  className?: string,
  module: IAdminModule,
  disabled:boolean,
  onChange:(module:IAdminModule) => void,
  leftPadding:number
}) => (
  <tr key={module.id} className={className}>
    <td>
      <code>{module.id}</code>
    </td>
    <td style={{ paddingLeft: `${leftPadding}px` }}>
      {module.name}
    </td>
    { ALL_JOB_TYPE_KEYS.map((jobTypeKey) => (
      <td key={jobTypeKey} className="text-center em">
        <Form.Check
          onChange={async (e) => {
            onChange({
              ...module,
              jobTypeKeys: toggleInArray(module.jobTypeKeys, jobTypeKey, e.target.checked) as JobTypeKeys[],
            });
          }}
          checked={module.jobTypeKeys.includes(jobTypeKey)}
          disabled={disabled}
        />
      </td>
    ))}
  </tr>
);

interface IModulesProps {
  modules:IAdminModuleWithChildren[],
  disabled:boolean,
  onChange:(module:IAdminModule) => void,
  level?:number
}

const Modules = (props:IModulesProps) => {
  const {
    modules, disabled, onChange, level,
  } = props;

  return modules.map((module) => (
    <React.Fragment key={module.id}>
      <ModuleRow
        module={module}
        disabled={disabled}
        onChange={onChange}
        leftPadding={level ? level * 30 : 0}
      />
      { module.children
        ? <Modules modules={module.children} disabled={disabled} onChange={onChange} level={level ? level + 1 : 1} />
        : null }
    </React.Fragment>
  ));
};

export const AdminModulesPage:IComponentWithLoader<IAdminModule[], undefined> = {
  loader: (queryClient:QueryClient) => getOrFetchFromApi<IAdminModule[]>(
    queryClient,
    'module/admin/modules',
  ),
  Component: () => {
    const { hasModuleRole } = useAccount();

    const [saving, setSaving] = useState(false);
    const [modules, setModules] = useState<IAdminModule[]>();

    const initialModules = useLoaderData() as Awaited<IAdminModule[]>;
    const invalidate = useInvalidateQueries('module/admin/modules');

    useEffect(() => {
      setModules(initialModules);
    }, [initialModules]);

    const updateModule = (module:IAdminModule) => {
      if (!modules) throw new Error('"modules" not initialized');
      const i = modules?.findIndex((m) => m.id === module.id);
      if (i === undefined || i < 0) throw new Error('Unknown module');
      const updatedModules = [...modules];
      updatedModules.splice(i, 1, module);
      return updatedModules;
    };

    const saveModules = useMutation({
      mutationFn: async () => {
        setSaving(true);
        try {
          await axios.put('/api/v1/module/admin/modules', modules);
          invalidate();
          toast.success('Module jobs have been updated', {
            toastId: 'modules-updated',
            updateId: 'modules-updated',
          });
          setSaving(false);
        } catch (err) {
          toast.error(err as string);
        }
      },
    });

    const moduleHierarchy = useMemo(() : IAdminModuleWithChildren[] => {
      if (!modules) return [];
      const modulesWithChildren:Record<number, IAdminModuleWithChildren> = {};
      modules.forEach((m) => {
        if (m.id > 0) {
          modulesWithChildren[m.id] = { ...m, children: [] };
        }
      });
      Object.values(modulesWithChildren).forEach((m) => {
        if (m.parent) {
          modulesWithChildren[m.parent.id].children.push(m);
        }
      });
      return Object.values(modulesWithChildren).filter((m) => !m.parent);
    }, [modules]);

    if (!modules || !moduleHierarchy) {
      return <Spinner animation="border" />;
    }

    const disabled = saving || !hasModuleRole(Module.admin, 'admin');

    return (
      <Card>
        <Card.Header>
          <Card.Title>
            Modules
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th colSpan={2}>{' '}</th>
                <th colSpan={3} className="text-center em">Supported jobs</th>
              </tr>
              <tr>
                <th>Id</th>
                <th>Name</th>
                { ALL_JOB_TYPE_KEYS.map((k) => (
                  <th key={k} className="text-center em"><code>{k}</code></th>
                )) }
              </tr>
            </thead>
            <tbody>
              <Modules
                modules={moduleHierarchy}
                disabled={disabled}
                onChange={(m) => setModules(updateModule(m))}
              />
            </tbody>
          </Table>
          <Stack
            direction="horizontal"
            gap={2}
          >
            <Button
              onClick={async () => saveModules.mutateAsync()}
            >
              Save
            </Button>
          </Stack>
        </Card.Body>
      </Card>
    );
  },
};

export default AdminModulesPage;
