import { Icon, IconComponentProps } from '@ailibs/feather-react-ts';
import React, { useEffect, useState } from 'react';
import { Button, Collapse, Nav } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Route, Routes } from '../routing/Routes';
import { useDownloadFromApi } from '../common/useDownloadFromApi';

interface SidebarItemWithSubItemsProps {
  children: React.JSX.Element | (React.JSX.Element | null)[] | null,
  /**
   * The path where this item will show as active - use with subitems
   *
   * Uses `path.startsWith(activePath)` to check if active
   */
  activePath: string,
  icon: IconComponentProps['name'],
  label: string,
  route?: Route,
}

interface SidebarItemSingleProps {
  children?: never,
  activePath?: never,
  icon?: never,
  label?: never,
  /**
   * The route object with uri, sidebar name, and icon
  */
  route: Route,
}

type SidebarItemProps = (SidebarItemSingleProps | SidebarItemWithSubItemsProps)&{onOpenClick?: () => void};

/**
 * Component for displaying a page/route in the sidebar (or one with multiple subroutes)
 */
const SidebarItem = (props: SidebarItemProps) => {
  const {
    children, icon, label, activePath, route, onOpenClick,
  } = props;
  const { pathname: path } = useLocation();

  const isActive = route
    ? (route.uri.length > 1 && path.startsWith(route.uri)) || route?.uri === path
    : !!activePath && path.startsWith(activePath);

  const [open, setOpen] = useState<boolean>(isActive);

  // Open sidebar if item is activated
  useEffect(() => {
    if (isActive && !open) {
      setOpen(isActive);
    }
  }, [isActive, open]);

  return (
    <Nav.Item className={`sidebar-item${isActive ? ' active' : ''} ${activePath ? '' : 'navigatable'}`}>
      {children !== undefined ? (
        <>
          <Button
            aria-expanded={open}
            onClick={() => {
              if (onOpenClick) {
                if (!open) {
                  onOpenClick();
                } else {
                  setOpen((val) => !val);
                }
              } else {
                setOpen((val) => !val);
              }
            }}
            onKeyDown={(e) => (e.key === 'enter' ? setOpen((val) => !val) : undefined)}
            className={`sidebar-link${open ? '' : ' collapsed'}`}
            data-bs-toggle="collapse"
          >
            <Icon name={icon} className="align-middle" size="18px" />
            <span className="align-middle">{label}</span>
          </Button>
          <Collapse in={open}>
            <div>
              <ul className="sidebar-dropdown list-unstyled mb-0">
                {children}
              </ul>
            </div>
          </Collapse>
        </>
      ) : (
        <Link className="sidebar-link" to={route.uri}>
          <Icon name={route.icon} className="align-middle" size="18px" />
          <span className="align-middle">{route.sidebar}</span>
        </Link>
      )}
    </Nav.Item>
  );
};

interface SidebarSubItem {
  route: Route,
}

/**
 * Sub item for a SidebarItem
 */
const SidebarSubItem = ({ route }: SidebarSubItem) => {
  const { pathname: path } = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <li className={`sidebar-item${path === route.uri.replace(/\/\w+\?/, '') || path === route.uri.replace('?', '') ? ' active' : ''} navigatable`}>
      <Link className="sidebar-link" to={Routes.getRouteRelativeUri(route, Object.fromEntries(searchParams)).toString()}>
        {route.sidebar}
      </Link>
    </li>
  );
};

const SidebarSubDownloadItem = ({ uri, label }: { uri:string, label:string }) => {
  const downloadFromApi = useDownloadFromApi();

  return (
    <li className="sidebar-item navigatable">
      <Button
        className="sidebar-link download"
        onClick={() => downloadFromApi(uri, 'Vulnerability report.pdf')}
      >
        {label}
      </Button>
    </li>
  );
};

export default SidebarItem;
SidebarItem.SubItem = SidebarSubItem;
SidebarItem.DownloadItem = SidebarSubDownloadItem;
