/* eslint-disable no-await-in-loop */
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import {
  IJob, IErrorDetails, JobStatus, IJobWithOutput,
} from '../types/Types';
import PromiseUtils from './PromiseUtils';

const getJobStatus = async (jobId:number) => (await axios.get<IJobWithOutput>(`/api/v1/jobs/${jobId}`, {
  params: { fetchResult: true },
})).data;

const getConflictingJobIdFromError = (axiosError:AxiosError|undefined) : number|null => {
  if (!axiosError) {
    return null;
  }
  if (axiosError.response?.status !== 409) {
    return null;
  }

  const errorDetails = axiosError.response?.data as IErrorDetails;
  if (!errorDetails) {
    return null;
  }

  const details = errorDetails.errorDetails as {conflictingJobId:number};
  if (!details || !details.conflictingJobId) {
    return null;
  }

  return details.conflictingJobId;
};

const startJob = async (start:() => Promise<IJob>) => {
  try {
    const job = await start();
    return job.id;
  } catch (err) {
    const conflictingJobId = getConflictingJobIdFromError(err as AxiosError);
    if (conflictingJobId) {
      return conflictingJobId;
    }
  }
  return -1;
};

const isJobActive = (job:IJob) => job.status === JobStatus.Pending
  || job.status === JobStatus.Unknown
  || job.status === JobStatus.Waiting
  || job.status === JobStatus.Active;

export const startAutomationJobAndAwaitResult = async <T, > (
  start:() => Promise<IJob>,
  signal?:AbortSignal|undefined,
) => {
  const jobId = await startJob(start);
  if (!jobId || jobId < 0) {
    throw new Error('Job failed to start');
  }

  while (true) {
    if (signal?.aborted) {
      throw new Error('Task was aborted');
    }
    await PromiseUtils.wait(1000);
    const job = await getJobStatus(jobId);

    if (!isJobActive(job)) {
      if (job.status === JobStatus.Failed) {
        toast.error(`Job execution failed. Please contact Ivolv Cybersecurity if the problem persists (job:${job.id}).`, {
          autoClose: 10000,
        });
        throw new Error('Job execution failed');
      }
      return job.output as T;
    }
  }
};
