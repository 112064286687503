import React, { useState } from 'react';
import {
  Button, Card, Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { IErrorDetails } from '../../types/Types';
import { ISignup, hasRequiredSignupData } from './SignupFormCard';
import ROUTES from '../../routing/Routes';
import { useLogout } from '../../providers/BearerTokenProvider';
import { SignupPendingApprovalCard } from './SignupPendingApprovalCard';
import { TermsAndConditions } from './TermsAndConditions';

export const SignupTermsCard = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const data = JSON.parse(sessionStorage.signup) as ISignup;
  const [busy, setBusy] = useState(false);
  const [existingCustomer, setExistingCustomer] = useState(false);

  async function registerCustomer() {
    try {
      setBusy(true);
      await axios.put('/api/v1/customersignup', data);
      navigate(ROUTES.signupConfirm.uri);
    } catch (err) {
      const axiosError = err as AxiosError;
      const errorData = axiosError.response?.data as IErrorDetails;
      if (errorData.status === 409) {
        setExistingCustomer(true);
      } else {
        toast.error(`Unable to register: ${errorData.detail}`, {
          toastId: 'signup-error',
          updateId: 'signup-error',
        });
      }
    } finally {
      setBusy(false);
    }
  }

  if (existingCustomer) {
    return <SignupPendingApprovalCard />;
  }

  return (
    <Card className="fill card-md">
      <Card.Header>
        <Card.Title>
          Accept terms of use to register your account
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <TermsAndConditions />
      </Card.Body>
      <div className="card-button-footer">
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                onClick={() => registerCustomer()}
                variant="primary"
                disabled={!hasRequiredSignupData(data) || busy || existingCustomer}
              >
                Accept terms and register
                <span className="d-none d-sm-inline"> account</span>
                { busy ? <Spinner animation="border" size="sm" className="ms-2" /> : null }
              </Button>
              <Button
                variant="secondary"
                disabled={busy}
                onClick={() => {
                  logout();
                  navigate('/');
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
