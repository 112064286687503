import React, { ReactNode } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getAsStringOrDefault } from '../../../utils/StringUtils';
import { ColumnFilter } from '../PagedTable';

type ColumnFilterValue = ColumnFilter['value'];

type CheckDropdownToggleProps = {
  children: string | React.JSX.Element,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  size?: 'sm' | 'lg',
}

type CheckDropdownItemProps = {
  id: string,
  value: ColumnFilterValue,
  label?: React.ReactNode|string,
  checked: boolean,
  onCheck: (checked: boolean, value: ColumnFilterValue) => void,
  size?: 'sm' | 'lg',
  type?: 'checkbox'|'radio'
}

type SearchDropdownItemProps = {
  searchFilter: string | undefined,
  onSearchChange: (filter: string) => void,
  size?: 'sm' | 'lg',
}

/**
 * Get the table filter name
 *
 * E.g.
 * - "All" => all items selected
 * - Selected value when one is selected
 * - "10 items selected" for more
 */
export const getFilterName = (
  value: ColumnFilterValue,
  defaultName = 'All',
  range = false,
  formatter:((value:unknown) => React.ReactNode|string)|undefined = undefined,
) : string|ReactNode => {
  if (value instanceof Array) {
    if (value.length > 1) return `${value.length} items selected`;
    if (value.length > 0) {
      if (range) return (value[0] as string).replace('search_', '');
      if (value !== null && typeof value !== 'undefined' && value.length > 0) {
        if (typeof value[0] === 'string' && value[0] === '') {
          return '<empty>';
        }
        return formatter ? formatter(value[0]) : getAsStringOrDefault(value[0], '<empty>');
      }
    }
    return defaultName;
  }

  if (!value) {
    return defaultName;
  }
  return formatter ? formatter(value) : String(value);
};

/**
 * Custom form input (text) dropdown item component
 */
export const SearchDropdownItem = React.forwardRef<HTMLDivElement, SearchDropdownItemProps>(({
  searchFilter, onSearchChange, size,
}, ref) => (
  <Form.Group
    ref={ref}
    className={`dropdown-item dropdown-item-${size ?? 'sm'} check-dropdown-item check-dropdown-item-search`}
  >
    <OverlayTrigger
      placement="bottom"
      overlay={(
        <Tooltip id="search-range-tooltip">
          Filter on a number or custom range
        </Tooltip>
      )}
    >
      <Form.Control
        size="sm"
        value={searchFilter}
        onChange={(e) => {
          onSearchChange(e.target.value);
        }}
        placeholder="Custom"
      />
    </OverlayTrigger>
  </Form.Group>
));

/**
 * Custom Form Check Dropdown Item element
 */
export const CheckDropdownItem = React.forwardRef<HTMLDivElement, CheckDropdownItemProps>(({
  value, checked, onCheck, size, label, id, type,
}, ref) => {
  const textValue = value === '' ? '<empty>' : getAsStringOrDefault(value, '<empty>');
  return (
    <Form.Group
      ref={ref}
      className="dropdown-item dropdown-item-sm check-dropdown-item"
      onClick={(e) => {
      // @ts-ignore
        if (e.target.localName === 'label') return;
        onCheck(!checked, value);
      }}
    >
      <Form.Check
        id={id}
        type={type ?? 'checkbox'}
        className={`form-select-${size ?? 'sm'} check-dropdown-checkbox`}
        label={label ?? textValue}
        checked={checked}
        onChange={() => undefined}
      />
    </Form.Group>
  );
});

/**
 * Custom dropdown toggle component (the dropdown button itself)
 */
export const CheckDropdownToggle = React.forwardRef<HTMLDivElement, CheckDropdownToggleProps>(
  ({ children, onClick, size }, ref) => (
    <Form.Group ref={ref}>
      <button
        className={`form-select form-select-${size ?? 'sm'} text-start`}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <span className="check-dropdown-toggle-text">
          {children}
        </span>
      </button>
    </Form.Group>
  ),
);
