/* eslint-disable lines-between-class-members */
/* eslint-disable max-classes-per-file */
import { IconComponentProps } from '@ailibs/feather-react-ts';
import { VulnerabilityControlQueryUtil } from '../pages/vulnerabilities/VulnerabilityControlFilter';

export class Route {
  readonly uri: string;
  readonly topbar: string;
  readonly sidebar: string;
  readonly settings?: string;
  readonly icon: IconComponentProps['name'];
  readonly preserveSearchKeys?: string[];

  constructor(options:{
    uri: string,
    icon?: IconComponentProps['name'] | undefined,
    topbar: string,
    sidebar?: string,
    settings?: string,
    preserveSearchKeys?: string[]
  }) {
    const {
      uri, icon, topbar, sidebar, settings, preserveSearchKeys,
    } = options;

    this.uri = uri;
    this.icon = icon ?? 'box';
    this.topbar = topbar;
    this.sidebar = sidebar ?? topbar;
    this.settings = settings;
    this.preserveSearchKeys = preserveSearchKeys;
  }

  getUriId() {
    return `${this.uri}/:id`;
  }

  uriStartsWith(location: string) {
    const firstParam = this.uri.indexOf(':');
    if (firstParam >= 0) {
      const beforeParam = this.uri.substring(0, firstParam).toLowerCase();
      return beforeParam.length && beforeParam !== '/' && location.toLowerCase().startsWith(beforeParam);
    }
    return location.toLowerCase().startsWith(this.uri.toLowerCase());
  }

  uriEquals(location: string) {
    return location === this.uri;
  }

  uriPredicate(location: string, predicate: (location: string, uri: string) => boolean) {
    return predicate(location, this.uri);
  }

  uriWithoutQuestion() {
    return this.uri.replace('?', '');
  }

  uriWithoutOptional() {
    return this.uri.replace(/\/\w+\?/, '');
  }
}

type RouteNames =
  'dashboard'
  | 'signup'
  | 'signupTerms'
  | 'signupConfirm'
  | 'risk'
  | 'vulnerability'
  | 'vulnerabilities'
  | 'vulnerabilitiesByControl'
  | 'vulnerabilitySummary'
  | 'vulnerabilityHistory'
  | 'control'
  | 'customersettings'
  | 'assets'
  | 'identities'
  | 'assetsSecurityAdvisories'
  | 'signupConsent'
  | 'termsAndConditions'
  | 'search'
  | 'usersettings'
  | 'admin'
  | 'adminCustomers'
  | 'adminTools'
  | 'adminJobs'
  | 'adminAssessments'
  | 'adminModules'
  | 'adminConsents'
  | 'adminCves'
  | 'assessment'
  | 'assessmentConfirm'
  | 'multiTenantCustomerSelect'
  | 'reportVulnerabilities'
  | 'acceptInvite'
  | 'adminNotifications'
  | 'actionTokenReturnPage';

// TODO: Add access checks to routes
const ROUTES: Record<RouteNames, Route> = {
  signup: new Route({
    uri: '/signup',
    topbar: 'Signup',
  }),
  acceptInvite: new Route({
    uri: '/acceptInvite',
    topbar: 'Accept invite',
  }),
  signupTerms: new Route({
    uri: '/signup/terms',
    topbar: 'Signup',
  }),
  signupConfirm: new Route({
    uri: '/signup/confirm',
    topbar: 'Signup',
  }),
  actionTokenReturnPage: new Route({
    uri: '/action/:type',
    icon: 'home',
    topbar: 'Dashboard',
    sidebar: undefined,
    settings: 'dashboard',
  }),
  dashboard: new Route({
    uri: '/',
    icon: 'home',
    topbar: 'Dashboard',
    sidebar: undefined,
    settings: 'dashboard',
  }),
  risk: new Route({
    uri: '/risk',
    icon: 'alert-triangle',
    topbar: 'Risks',
  }),
  vulnerability: new Route({
    uri: '/vulnerabilities',
    icon: 'flag',
    topbar: 'Vulnerability',
  }),
  vulnerabilities: new Route({
    uri: '/vulnerabilities',
    icon: 'flag',
    topbar: 'Vulnerabilities',
    preserveSearchKeys: VulnerabilityControlQueryUtil.getSearchQueryKeys(),
  }),
  vulnerabilitySummary: new Route({
    uri: '/vulnerabilities/summary',
    icon: 'flag',
    topbar: 'Vulnerability Summary',
    sidebar: 'Summary',
    preserveSearchKeys: VulnerabilityControlQueryUtil.getSearchQueryKeys(),
  }),
  vulnerabilitiesByControl: new Route({
    uri: '/vulnerabilities/controls',
    icon: 'flag',
    topbar: 'Controls',
    preserveSearchKeys: VulnerabilityControlQueryUtil.getSearchQueryKeys(),
  }),
  vulnerabilityHistory: new Route({
    uri: '/vulnerabilities/trend',
    icon: 'flag',
    topbar: 'Vulnerability Trend',
    sidebar: 'Trend',
    preserveSearchKeys: VulnerabilityControlQueryUtil.getSearchQueryKeys(),
  }),
  control: new Route({
    uri: '/control',
    icon: 'flag',
    topbar: 'Control',
  }),
  assets: new Route({
    uri: '/asset',
    icon: 'archive',
    topbar: 'Assets',
  }),
  identities: new Route({
    uri: '/identity',
    icon: 'user',
    topbar: 'Identities',
  }),
  assetsSecurityAdvisories: new Route({
    uri: '/asset/advisories',
    icon: 'archive',
    topbar: 'Security Advisories',
  }),
  signupConsent: new Route({
    uri: '/:id/consent',
    icon: undefined,
    topbar: 'Consent',
  }),
  customersettings: new Route({
    uri: '/customersettings',
    icon: undefined,
    topbar: 'Customer Settings',
  }),
  usersettings: new Route({
    uri: '/usersettings',
    icon: 'user',
    topbar: 'User Settings',
  }),
  search: new Route({
    uri: '/search',
    icon: 'search',
    topbar: 'Search',
  }),
  admin: new Route({
    uri: '/admin',
    icon: 'tool',
    topbar: 'Admin',
  }),
  adminCustomers: new Route({
    uri: '/admin/customers',
    icon: 'users',
    topbar: 'Admin / Customers',
    sidebar: 'Customers',
  }),
  adminJobs: new Route({
    uri: '/admin/jobs',
    icon: 'list',
    topbar: 'Jobs',
  }),
  adminTools: new Route({
    uri: '/admin/tools',
    icon: 'tool',
    topbar: 'Admin / Tools',
    sidebar: 'Tools',
  }),
  adminCves: new Route({
    uri: '/admin/cves',
    icon: 'tool',
    topbar: 'Admin / CVEs',
    sidebar: 'CVEs',
  }),
  adminAssessments: new Route({
    uri: '/admin/assessments',
    icon: 'activity',
    topbar: 'Assessments',
  }),
  adminConsents: new Route({
    uri: '/admin/consents',
    icon: 'activity',
    topbar: 'Consents',
  }),
  adminModules: new Route({
    uri: '/admin/modules',
    icon: 'archive',
    topbar: 'Modules',
  }),
  adminNotifications: new Route({
    uri: '/admin/notifications',
    icon: 'mail',
    topbar: 'Notifications',
  }),
  multiTenantCustomerSelect: new Route({
    uri: '/multiTenant/customerSelect',
    icon: 'users',
    topbar: 'Customer select',
  }),
  assessment: new Route({
    uri: '/assessment',
    icon: 'activity',
    topbar: 'Cyber Security Assessment',
  }),
  assessmentConfirm: new Route({
    uri: '/assessment/confirm',
    icon: 'activity',
    topbar: 'Cyber Security Assessment / Save',
  }),
  termsAndConditions: new Route({
    uri: 'terms',
    icon: 'activity',
    topbar: 'Terms and Conditions',
  }),
  reportVulnerabilities: new Route({
    uri: '/report/vulnerabilities',
    icon: 'file-text',
    topbar: 'Vulnerability report',
  }),
};

export class Routes {
  root: Route;
  routes: { name: string, route: Route }[];

  constructor(routes: Record<RouteNames, Route>) {
    this.root = routes.dashboard;
    this.routes = Object.entries(routes).map((value) => ({ name: value[0], route: value[1] })).filter(({ name }) => name !== 'dashboard');
  }

  /** Routes method to get the topbar text automatically with the current uri */
  getTopbarText(location: string) {
    const locationRoute = this.getRouteFromLocation(location);
    if (!locationRoute) {
      return 'Page not found!';
    }

    return locationRoute?.topbar || '';
  }

  getTitle(location: string) : string|undefined {
    const locationRoute = this.getRouteFromLocation(location);
    if (!locationRoute) {
      return undefined;
    }

    // Using topbar in title for now, need to improve this to allow use to
    // provide entity spesific details in title, e.g. risk and control Id's.
    return locationRoute?.topbar ?? '';
  }

  getRouteFromLocation(location: string) {
    if (location === this.root.uri) return this.root;
    const exactMatch = this.routes.find((obj) => obj.route.uri.toLowerCase() === location.toLowerCase());
    if (exactMatch) return exactMatch.route;

    // If location is a subpath, with an ID-field, we won't get an exact match.
    // Find all routes that are matches for this location, and return the route
    // with the longest matching URI.
    const startsWith = this.routes.filter((obj) => obj.route.uriStartsWith(location.toLowerCase()));
    if (startsWith.length > 0) {
      startsWith.sort((a, b) => {
        if (a.route.uri.length === b.route.uri.length) {
          return 0;
        }
        return (a.route.uri.length > b.route.uri.length ? -1 : 1);
      });
      return startsWith[0].route;
    }
    return null;
  }

  getSettings(location: string) : string | undefined {
    const locationRoute = this.getRouteFromLocation(location);
    return locationRoute?.settings;
  }

  static getRouteRelativeUri(
    route:Route,
    searchParams?:Record<string, string>|null,
    keepSearch?:boolean|null,
    appendId?:string|number|null,
  ) {
    const routeUri = new URL(
      appendId
        ? route.uri[route.uri.length - 1] === '/'
          ? route.uri
          : `${route.uri}/${appendId}`
        : route.uri,
      window.location.origin,
    );
    if (searchParams && (route.preserveSearchKeys || keepSearch)) {
      Object.keys(searchParams).forEach((key) => {
        if (!keepSearch && !route.preserveSearchKeys?.includes(key)) return;
        routeUri.searchParams.append(key, searchParams[key]);
      });
    }
    return routeUri;
  }
}

export const ROUTES_OBJECT = new Routes(ROUTES);

export default ROUTES;
