import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PageableColumnDef } from '../PagedTable';
import { SelectFilter } from './SelectFilter';

/**
 * Table filter for use with server side paged data (TableFromPageable).
 */
export const PagedResultTableFilter = <TObject, TValue, TFilterValue, > ({
  columnDef,
  size,
  filterText,
  filterValues,
  setFilterValues,
  disabled,
}:{
  columnDef: PageableColumnDef<TObject, TValue, TFilterValue>,
  size?: 'sm' | 'lg',
  filterText: string,
  filterValues: TFilterValue[]|undefined|null,
  setFilterValues: (values:TFilterValue[]) => void,
  disabled?: boolean,
}) => {
  const [localFilterText, setLocalFilterText] = useState(filterText);

  useEffect(() => {
    setLocalFilterText(filterText);
  }, [filterText]);

  const activateFilterValues = async (values:unknown|unknown[]|null) => {
    const valuesAsArray = Array.isArray(values)
      ? values
      : values ? [values] : [];
    setFilterValues(valuesAsArray);
  };

  if (!columnDef) {
    return null;
  }

  if (columnDef.selectOptions) {
    return (
      <SelectFilter
        columnId={columnDef.id ?? columnDef.accessorKey ?? 'default'}
        columnDef={columnDef}
        size={size}
        disabled={disabled}
        options={columnDef.selectOptions}
        filterValues={filterValues ?? null}
        setFilterValues={activateFilterValues}
        multiselect={columnDef.supportMultiSelect}
      />
    );
  }

  if (disabled) {
    return localFilterText
      ? (
        <div
          className={`form-control form-border-radius ${localFilterText ? ' has-selection' : ''} form-control-sm`}
        >
          {localFilterText}
        </div>
      ) : null;
  }

  return (
    <Form.Control
      key={columnDef.id}
      size={size ?? 'sm'}
      type={columnDef.inputType ?? 'text'}
      disabled={disabled}
      className={`form-border-radius ${localFilterText ? ' has-selection' : ''}`}
      value={localFilterText}
      onKeyDown={(e) => {
        if (typeof columnDef.updateFilterFn !== 'function') return;
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          const inputElement = e.target as HTMLInputElement;
          activateFilterValues(inputElement.value);
        }
      }}
      onBlur={(e) => {
        const inputElement = e.target as HTMLInputElement;
        activateFilterValues(inputElement.value);
      }}
      onChange={async (e) => {
        setLocalFilterText(e.target.value);
      }}
      placeholder="Search"
    />
  );
};
