import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Module } from '../../types/AccessTypes';
import { ScanVulnerabilitiesButton, useCanSync } from '../../components/ScanVulnerabilitiesButton';
import { useModules } from '../../providers/ModuleContext';
import { m365PricipalRoleName } from '../../types/AdminTypes';

const Microsoft365Tab = () => {
  const canM365Sync = useCanSync(Module.m365);
  const canEntraIdSync = useCanSync(Module.entraId);

  const { getModuleNameOrDefault } = useModules();

  const m365AnalyticsName = getModuleNameOrDefault(Module.m365);
  const entraIdName = getModuleNameOrDefault(Module.entraId);

  return (
    <Row>
      { canEntraIdSync
        ? (
          <Col md={6}>
            <fieldset className="mb-3 pb-3">
              <legend>{entraIdName}</legend>
              <div className="mb-3">
                As long as
                {' '}
                {entraIdName}
                {' '}
                {}
                {' '}
                is enabled, we&apos;re doing nightly scans to keep your
                system safe.
                <br />
                <br />
                If needed, you can use the button below to trigger a manual scan.
              </div>
              <ScanVulnerabilitiesButton module={Module.entraId} />
            </fieldset>
          </Col>
        )
        : null }
      { canM365Sync
        ? (
          <Col md={6}>
            <fieldset className="mb-3 pb-3">
              <legend>{m365AnalyticsName}</legend>
              <div className="mb-3">
                As long as
                {' '}
                {m365AnalyticsName}
                {' '}
                is enabled, and our
                {' '}
                <em>{m365PricipalRoleName}</em>
                {' '}
                user is part of the
                {' '}
                <em>Global Reader</em>
                -role, we&apos;re doing nightly scans to keep your
                system safe.
                <br />
                <br />
                If needed, you can use the button below to trigger a manual scan.
              </div>
              <ScanVulnerabilitiesButton module={Module.m365} />
            </fieldset>
          </Col>
        )
        : null }
    </Row>
  );
};

export default Microsoft365Tab;
