import React, { useEffect } from 'react';
import { Formik } from 'formik';
import {
  Button, Col, Form, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { useModules } from '../../providers/ModuleContext';
import { IIdentity } from './IdentitiesPage';
import { FormSignificanceSelector } from '../../common/FormSignificanceSelector';
import ROUTES from '../../routing/Routes';

export const IdentityDetailTab = ({
  identity,
}:{
  identity: IIdentity
}) => {
  const { getModuleNameOrDefault } = useModules();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  useEffect(() => {

  }, [identity]);

  const saveMutation = useMutation({
    mutationFn: async (data:IIdentity) => axios.put(`/api/v1/identity/${encodeURIComponent(identity.id)}`, {
      ...data,
      significance: data.significance?.length ? data.significance : null,
    }),
    onSuccess: async () => {
      toast.success('Identity was saved', {
        toastId: 'identity-save',
        updateId: 'identity-save',
      });
      await invalidateVulnerabilities();
    },
  });

  return (
    <Formik
      initialValues={identity}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        await saveMutation.mutateAsync(values);
        helpers.resetForm({ values });
      }}
    >
      {({
        values, handleSubmit, handleChange, dirty, handleReset,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8} className="mb-3">
              <div>
                <Form.Label>Name:</Form.Label>
                <Form.Control disabled value={values.name} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Significance:</Form.Label>
                <FormSignificanceSelector item={values} onChange={handleChange} />
              </div>
            </Col>
            <Col md={8} className="mb-3">
              <div>
                <Form.Label>Email:</Form.Label>
                <Form.Control disabled value={values?.email} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <div>
                <Form.Label>Unique ID:</Form.Label>
                <Form.Control disabled value={values?.uniqueId} />
              </div>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Label>Source module:</Form.Label>
              <Form.Control
                disabled
                value={getModuleNameOrDefault(identity.sourceModuleId)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              { values?.manager
                ? (
                  <>
                    <Form.Label>Relations:</Form.Label>
                    <ul className="mb-0">
                      <li>
                        Manager:
                        {' '}
                        <Link
                          to={`${ROUTES.identities.uri}/${values.manager.id}`}
                          title="Manager"
                        >
                          {values?.manager?.name}
                        </Link>
                      </li>
                    </ul>
                  </>
                ) : null }
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button type="submit" disabled={!dirty}>Save</Button>
                <Button type="reset" variant="secondary" disabled={!dirty} onClick={handleReset}>Undo</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
