/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Button, Form, Modal, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useQueryClient } from '@tanstack/react-query';
import { EntityType, IHaveEntityId } from '../types/EntityTypes';
import { getOrFetchFromApi } from '../query/GenericQuery';
import { useEntityTypeAsText } from '../utils/TranslationUtils';
import { getStringDate } from '../utils/StringUtils';

type ObjectTypeName = 'Asset'|'Customer'|'Cve'|'EntityComment'|'Risk'|'Setting'|'Software'|'Vulnerability'|'User'|'Identity';

export const asObjectTypeName = (entityType:EntityType) : ObjectTypeName => {
  switch (entityType) {
  case EntityType.Asset:
    return 'Asset';
  case EntityType.Customer:
    return 'Customer';
  case EntityType.Cve:
    return 'Cve';
  case EntityType.Software:
    return 'Software';
  case EntityType.Risk:
    return 'Risk';
  case EntityType.User:
    return 'User';
  case EntityType.Vulnerability:
    return 'Vulnerability';
  case EntityType.Identity:
    return 'Identity';
  default:
    throw new Error(`EntityType ${entityType} cannot be converted to object type name`);
  }
};

interface IProps extends IHaveEntityId {
  show: boolean,
  handleClose: () => void,
  filterByObjectTypes?: ObjectTypeName|undefined,
  visibleId?: string
}

const FilterControl = ({
  value,
  activateValue,
}:{
  value:string|undefined,
  activateValue: (value:string|undefined) => void
}) => {
  const [localValue, setLocalValue] = useState(value ?? '');

  useEffect(() => {
    setLocalValue(value ?? '');
  }, [value]);

  const applyIfChanged = useCallback((e:React.UIEvent|React.FocusEvent) => {
    const inputElement = e.target as HTMLInputElement;
    const newValue = inputElement.value.length ? inputElement.value : undefined;
    if (newValue !== value) {
      activateValue(newValue);
    }
  }, [activateValue, value]);

  return (
    <Form.Control
      placeholder="Search"
      className={`form-border-radius ${localValue?.length ? ' has-selection' : ''}`}
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          applyIfChanged(e);
        }
      }}
      onBlur={(e) => {
        applyIfChanged(e);
      }}
      size="sm"
    />
  );
};

export const changeTypesAsStrings = [
  'added',
  'modified',
  'deleted',
] as const;
export type ChangeType = typeof changeTypesAsStrings[number];

interface IChangelog {
  timestamp:Date,
  changeType:ChangeType,
  objectTypeName:string,
  key?:string,
  valueFrom?:string,
  valueTo?:string,
  upn?:string,
  appId?:string,
  tenantId?:string,
  entityType?:string,
  partitionKey:string,
  rowKey:string
}

interface IChangeLogListOptions {
  objectType?: ObjectTypeName,
  key?: string,
  changeType?: string,
  account?: string,
}

interface TableStorageResult<T> {
  items:T[],
  continuationToken:string
}

const asChangeType = (value:string) : ChangeType|undefined => {
  if (!value.length) {
    return undefined;
  }
  const lowerValue = value.toLowerCase();
  for (let i = 0; i < changeTypesAsStrings.length; i += 1) {
    if (changeTypesAsStrings[i].toLocaleLowerCase().startsWith(lowerValue)) {
      return changeTypesAsStrings[i];
    }
  }
  return undefined;
};

export const EntityChangelogModal = ({
  show,
  handleClose,
  entityId,
  entityType,
  filterByObjectTypes,
  visibleId,
}: IProps) => {
  const queryClient = useQueryClient();

  const [continuationToken, setContinuationToken] = useState<string>();
  const [changes, setChanges] = useState<IChangelog[]>();
  const [loadingPage, setLoadingPage] = useState(false);
  const [filter, setFilter] = useState<IChangeLogListOptions>({
    objectType: filterByObjectTypes,
  });

  const hasResettableFilters = filter.account || filter.changeType || filter.key;

  const loadNextPage = useCallback(async () => {
    setLoadingPage(true);
    try {
      const result = await getOrFetchFromApi<TableStorageResult<IChangelog>>(
        queryClient,
      `changelog/${encodeURIComponent(entityType)}/${encodeURIComponent(entityId)}`,
      {
        ...filter,
        continuationToken,
      },
      {
        forceRefresh: true,
      },
      );
      setChanges(changes ? [...changes, ...result.items] : result.items);
      setContinuationToken(result.continuationToken);
    } finally {
      setLoadingPage(false);
    }
  }, [changes, continuationToken, entityId, entityType, filter, queryClient]);

  useEffect(() => {
    setChanges(undefined);
    setContinuationToken(undefined);
  }, [filter]);

  useEffect(() => {
    if (show && !changes) {
      loadNextPage();
    }
  }, [changes, loadNextPage, show]);

  const entityTypeAsText = useEntityTypeAsText();

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Changelog for
          {' '}
          {entityTypeAsText(entityType)}
          {' '}
          {visibleId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { !changes
          ? <Spinner animation="border" />
          : (
            <table className="table table-sm table-hover">
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>Change type</th>
                  <th>Property</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Account</th>
                </tr>
                <tr className="filter-row">
                  <th />
                  <th>
                    <FilterControl
                      value={filter.changeType}
                      activateValue={(value) => {
                        if (!value) return;
                        const changeType = asChangeType(value);
                        if (changeType !== filter.changeType) {
                          setFilter({
                            ...filter,
                            changeType,
                          });
                        }
                      }}
                    />
                  </th>
                  <th>
                    <FilterControl
                      value={filter.key}
                      activateValue={(value) => {
                        setFilter({
                          ...filter,
                          key: value,
                        });
                      }}
                    />
                  </th>
                  <th />
                  <th />
                  <th>
                    <FilterControl
                      value={filter.account}
                      activateValue={(value) => {
                        setFilter({
                          ...filter,
                          account: value,
                        });
                      }}
                    />
                  </th>
                </tr>
                <tr className="filter-toolbar">
                  <th colSpan={7}>
                    <div>
                      <span className="float-end">
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={!hasResettableFilters}
                          onClick={() => setFilter({
                            ...filter,
                            changeType: undefined,
                            key: undefined,
                            account: undefined,
                          })}
                        >
                          Clear filters
                          <Icon name="x-square" size="12px" className="ms-2" />
                        </Button>
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                { changes.map((c, i) => (
                  <tr key={c.rowKey} className={`${i % 2 === 0 ? 'even' : 'odd'}`}>
                    <td>{getStringDate(c.timestamp)}</td>
                    <td>{c.changeType}</td>
                    <td>{c.key}</td>
                    { c.valueFrom === undefined && c.valueTo === undefined
                      ? (
                        <td colSpan={2} className="text-muted">
                          (changes are not retained for this property)
                        </td>
                      )
                      : (
                        <>
                          <td>{c.valueFrom}</td>
                          <td>{c.valueTo}</td>
                        </>
                      )}
                    <td>{c.upn}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        {
          continuationToken
            ? (
              <Button
                disabled={loadingPage}
                onClick={async () => loadNextPage()}
              >
                { loadingPage
                  ? (
                    <>
                      Loading
                      <Spinner animation="border" size="sm" className="ms-2" />
                    </>
                  ) : 'Load more...' }
              </Button>
            ) : null
        }
      </Modal.Body>
    </Modal>
  );
};

export const EntityChangelogButton = ({
  entityId,
  entityType,
  visibleId,
  filterByObjectTypes,
}:{
  entityId:string|number,
  entityType:EntityType,
  visibleId?:string,
  filterByObjectTypes?: ObjectTypeName|undefined,
}) => {
  const [showChangelog, setShowChangelog] = useState(false);

  const openChangelog = () => {
    setShowChangelog(true);
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip>
            Show changelog
          </Tooltip>
        )}
      >
        <Button variant="link" onClick={openChangelog} className="px-0">
          <Icon name="clock" size="18" />
        </Button>
      </OverlayTrigger>
      { showChangelog ? (
        <EntityChangelogModal
          show
          handleClose={() => setShowChangelog(false)}
          entityId={entityId}
          entityType={entityType}
          filterByObjectTypes={filterByObjectTypes}
          visibleId={visibleId}
        />
      ) : null }
    </>
  );
};
