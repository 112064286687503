import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useSettingsContext from '../contexts/SettingsContext';
import { useAccount } from '../providers/AccountContext';

export const MultiTenantSearchParamDetector = ({ children }:{children:React.ReactNode}) => {
  const { setActiveCustomerId } = useSettingsContext();
  const { customer } = useAccount();
  const [searchParams, setSeachParams] = useSearchParams();

  useEffect(() => {
    const customerId = searchParams.get('customerId');
    if (customerId && customerId !== customer.id) {
      setActiveCustomerId(customerId);
      searchParams.delete('customerId');
      setSeachParams(searchParams, {
        replace: true,
      });
    }
  });

  return children;
};
