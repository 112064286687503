import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { Card, Tab } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { AxiosError } from 'axios';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { IVulnerability } from './Types';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import { RenderContentSegment } from './RenderContentSegment';
import RenderHtml from '../../components/RenderHtml';
import { VulnerabilityDetailsTab } from './VulnerabilityDetailsTab';
import { EntityCommentsCard } from '../../components/EntityCommentsCard';
import { EntityType } from '../../types/EntityTypes';
import ROUTES from '../../routing/Routes';
import { VulnerabilityCardFooter } from './VulnerabilityCardFooter';
import { SoftwareTable } from './SoftwareTable';
import { IdNameTable } from '../../common/table/IdNameTable';
import { StatefulTabs } from '../../components/StatefulTabs';

export const VulnerabilityPage = {
  loader: async (queryClient:QueryClient, id:string|undefined) => (
    getOrFetchFromApi<IVulnerability>(
      queryClient,
      id && `vulnerabilities/${id}`,
    )
  ),
  Component: () => {
    const [activeTab, setActiveTab] = useState<string>();
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: vulnerability, error } = useApiLoaderData<IVulnerability | undefined, IVulnerability | undefined>(
      `vulnerabilities/${id}`,
      (loaderData) => loaderData,
      undefined,
      {
        retry: false,
      },
    );

    useEffect(() => {
      if (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 403) {
          navigate(ROUTES.dashboard.uri);
        }
      }
    }, [navigate, error]);

    const [detailsSegment, segments] = useMemo(() => {
      if (!vulnerability) {
        return [undefined, undefined];
      }
      const mDetailsSegment = vulnerability?.detailsSegments.find((s) => s.header === undefined);
      const mSegments = mDetailsSegment
        ? vulnerability.detailsSegments.filter((s) => s.header !== undefined)
        : vulnerability.detailsSegments;

      return [mDetailsSegment, mSegments];
    }, [vulnerability]);

    if (!vulnerability || !segments) return null;

    return (
      <>
        <Card>
          <Card.Header>
            <Card.Title>
              <Icon name="flag" size="18" />
              {' '}
              {vulnerability.summary}
              <CardCloseBackButton />
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <StatefulTabs
              defaultActiveKey="details"
              className="mb-3"
              onActiveTabChanged={setActiveTab}
            >
              <Tab
                title="Details"
                eventKey="details"
              >
                <VulnerabilityDetailsTab vulnerability={vulnerability} details={detailsSegment} />
              </Tab>
              {vulnerability.remediation
                ? (
                  <Tab
                    title="Remediation"
                    eventKey="remediation"
                  >
                    <RenderHtml allowedTags={['a', 'br']} allowedAttributes={['target']}>
                      {vulnerability.remediation}
                    </RenderHtml>
                    <VulnerabilityCardFooter vulnerability={vulnerability} />
                  </Tab>
                )
                : null}
              { vulnerability.software?.length
                ? (
                  <Tab
                    title="Affected software"
                    eventKey="software"
                  >
                    <SoftwareTable software={vulnerability.software} />
                    <VulnerabilityCardFooter vulnerability={vulnerability} />
                  </Tab>
                )
                : null }
              { vulnerability.identities?.length
                ? (
                  <Tab
                    title="Affected identities"
                    eventKey="identities"
                  >
                    <IdNameTable
                      items={vulnerability.identities}
                      onRowClick={(_, identity) => {
                        navigate(`${ROUTES.identities.uri}/${identity.id}`);
                      }}
                    />
                  </Tab>
                )
                : null }
              {segments.length
                ? segments.map((s, i) => (
                  <Tab
                    eventKey={i}
                    key={s.header ?? 'default'}
                    title={s.header ?? 'Default'}
                  >
                    {s.contents.map((c, contentIndex) => (
                      <RenderContentSegment
                      // eslint-disable-next-line react/no-array-index-key
                        key={contentIndex}
                        id={`vulnerability-${vulnerability.id}-content-${i}`}
                        content={c}
                      />
                    ))}
                    <VulnerabilityCardFooter vulnerability={vulnerability} />
                  </Tab>
                )) : null}
            </StatefulTabs>
          </Card.Body>
        </Card>
        { activeTab === 'details'
          ? <EntityCommentsCard entity={{ entityType: EntityType.Vulnerability, entityId: vulnerability.id }} />
          : null }
      </>
    );
  },
};
