import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Alert, Button } from 'react-bootstrap';
import { IAvailableCustomerData } from './DashboardPage';
import { startVulnerabilityScan } from '../../components/ScanVulnerabilitiesButton';
import { Module } from '../../types/AccessTypes';
import { useApi } from '../../query/GenericQuery';
import {
  IErrorDetails, IJob, JobStatus, SettingValue,
} from '../../types/Types';
import { useAccount } from '../../providers/AccountContext';
import { useAvailableCustomerData, useInvalidateAvailableCustomerData } from '../../providers/AvailableCustomerDataProvider';
import { getStringDate } from '../../utils/StringUtils';

const getQuoteRequestedModules = (customerSettings:Record<number, Record<string, SettingValue>>) => {
  const salesQuoteRequestedSetting = customerSettings[Module.none]
    ? customerSettings[Module.none]['sales.quote.requested-modules']
    : undefined;

  if (salesQuoteRequestedSetting) {
    try {
      return JSON.parse(salesQuoteRequestedSetting.value) as Module[];
    } catch {
      // ignore
    }
  }
  return null;
};

const StatusView = ({ status, scanError }:{status:string, scanError?:AxiosError}) => {
  const {
    customer, refreshAccess, hasModuleRole, customerSettings,
  } = useAccount();

  const quoteRequstedModules = getQuoteRequestedModules(customerSettings);

  const requestQuote = useCallback(async () => {
    try {
      await axios.post('/api/v1/sales/requestQuote');
      refreshAccess();
      toast.success(
        <div>
          A sales quote have been requested. We&apos;ll get back to you
          as soon as possible.
        </div>,
      );
    } catch {
      toast.error(
        <div>
          Unable to send quote request, please contact
          {' '}
          <a href={`mailto:support@ivolv.no?subject=CSP sales request: ${customer.name}`}>support@ivolv.no</a>
          {' '}
          directly
        </div>,
      );
    }
  }, [customer.name, refreshAccess]);

  switch (status) {
  case 'notConnectedModules':
    return (
      <p>
        There&apos;s no information to display until you connect to your services
      </p>
    );
  case 'noContentAvailable':
    return (
      <>
        <p>
          There&apos;s no information to show.
        </p>
        <p>
          Please check back later, and don&apos;t hesitate to contact
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          {' '}
          if you need assistance connecting your services.
        </p>
      </>
    );
  case 'noPermittedContent':
    return (
      <>
        <p>
          Your account currently does not have access to any content.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'noContentIsAdmin':
    return (
      <>
        <p>
          You currently have no module permissions and no content to display.
        </p>
        <p>
          As an administrator, you can assign additional permissions via the
          {' '}
          <em>Customer settings</em>
          {' '}
          option in the dropdown menu at the top right of the application.
        </p>
      </>
    );
  case 'noContentAvailableCustomerIsMsp':
    return (
      <p>
        There&apos;s no information available for your tenant,
        but you have permission to access other customer tenants. Use the
        {' '}
        <em>Switch organization</em>
        {' '}
        button on your top right to select another customer.
      </p>
    );
  case 'noPermissions':
    return (
      <>
        <p>
          You currently don&apos;t have any permissions assigned to your account.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'onlyReadOwnAndNoAssigned':
    return (
      <>
        <p>
          You currently don&apos;t have any assigned vulnerabilities to view.
        </p>
        <p>
          For further assistance, please contact your organization&apos;s administrator or
          reach out to
          {' '}
          <a href="mailto:support@ivolv.no">Ivolv Support</a>
          .
        </p>
      </>
    );
  case 'scanError':
    return (
      <Alert variant="warning" className="p-3 pb-1">
        <div>
          <p>
            We&apos;ve tried to scan your service for vulnerabilities but encountered some issues
            { scanError
              ? (
                <span>
                  (
                  {scanError.response?.status}
                  {' '}
                  {scanError.response?.statusText}
                  )
                </span>
              )
              : null }
            .
          </p>
          <p>
            For further assistance, please contact reach out to
            {' '}
            <a href="mailto:support@ivolv.no">Ivolv Support</a>
            .
          </p>
        </div>
      </Alert>
    );
  case 'scanInProgress':
    return (
      <>
        <p>
          You have successfully connected your first service, and we&apos;ve started a vulnerability scan.
        </p>
        <p>
          In a few minutes, when the scan is done, the platform will be updated with the initial findings.
        </p>
      </>
    );
  case 'trial':
    // eslint-disable-next-line no-case-declarations
    const expiringModules = customer.trialModules.map((m) => ({
      ...m,
      expires: new Date(m.expires!),
    }));

    expiringModules.sort((a, b) => (a.expires > b.expires ? 1 : -1));
    return expiringModules.map((m) => {
      const hasExpired = m.expires <= new Date();

      return (
        <Alert
          key={m.module.id}
          className="p-3"
          variant={hasExpired ? 'danger' : 'warning'}
        >
          <div>
            <strong>
              The
              {' '}
              <em>
                {m.module.name}
              </em>
              {' '}
              module
              {' '}
              {hasExpired ? 'expired' : 'will expire'}
              {' '}
              on
              {' '}
              {getStringDate(m.expires)}
              .
            </strong>
            <br />
            { hasModuleRole(Module.customerAdmin, 'readWrite')
              ? hasExpired
                ? quoteRequstedModules?.includes(m.module.id)
                  ? (
                    <>
                      A sales quote request have been sent to support@ivolv.no.
                      <br />
                      We will get back to you shortly.
                    </>
                  ) : (
                    <>
                      To restore access, please request a sales quote with the button below.
                      {' '}
                      <Button
                        onClick={() => { requestQuote(); }}
                      >
                        Request sales quote
                      </Button>
                      .
                    </>
                  )
                : quoteRequstedModules?.includes(m.module.id)
                  ? (
                    <>
                      A sales quote request have been sent to support@ivolv.no.
                      <br />
                      We will get back to you shortly.
                    </>
                  )
                  : (
                    <>
                      To ensure uninterrupted access, please request a sales quote well in advance.
                      {' '}
                      <div className="mt-3">
                        <Button
                          onClick={() => { requestQuote(); }}
                        >
                          Request sales quote
                        </Button>
                      </div>
                    </>
                  )
              : (
                <>
                  For further assistance, please contact your organization&apos;s administrator or
                  reach out to
                  {' '}
                  <a href="mailto:support@ivolv.no">Ivolv Support</a>
                  .
                </>
              ) }
          </div>
        </Alert>
      );
    });
  default:
    break;
  }

  return null;
};

export const useGetDisplayStatus = (scanError:AxiosError|undefined) => {
  const {
    hasAnyRole, hasOnlyModuleRole, isAssociatedTo, customer,
  } = useAccount();
  const { hasModuleRole } = useAccount();
  const availableData = useAvailableCustomerData();

  const statuses = useMemo(() => {
    const mStatuses: string[] = [];

    const expiringModules = customer.customerModules.filter((m) => m.expires);

    if (expiringModules.length) {
      mStatuses.push('trial');
    }

    if (
      !availableData
      || !availableData.hasConsents
      || hasOnlyModuleRole(Module.customerAdmin, 'read')
    ) {
      if (hasModuleRole(Module.customerAdmin, 'readWrite')) {
        return [...mStatuses, 'notConnectedModules'];
      }

      if (isAssociatedTo?.length) {
        return [...mStatuses, 'noContentAvailableCustomerIsMsp'];
      }

      return [...mStatuses, 'noContentAvailable'];
    }

    if (hasOnlyModuleRole(Module.customerAdmin, 'readWrite')) {
      return [...mStatuses, 'noContentIsAdmin'];
    }

    if (!hasAnyRole) {
      return [...mStatuses, 'noPermissions'];
    }

    // This must be placed before 'noPermittedContent'
    if (
      hasModuleRole(Module.vulnerability, 'readOwn')
      && !hasModuleRole(Module.vulnerability, 'read')
      && !hasModuleRole(Module.admin, 'read')
      && !hasModuleRole(Module.risk, 'read')
      && !hasModuleRole(Module.assets, 'read')
    ) {
      return [...mStatuses, 'onlyReadOwnAndNoAssigned'];
    }

    if (
      !hasModuleRole(Module.vulnerability, 'read')
      && !hasModuleRole(Module.admin, 'read')
      && !hasModuleRole(Module.risk, 'read')
      && !hasModuleRole(Module.assets, 'read')
    ) {
      return [...mStatuses, 'noPermittedContent'];
    }

    if (scanError) {
      return [...mStatuses, 'scanError'];
    }

    if (!availableData.hasVulnerabilities) {
      return [...mStatuses, 'scanInProgress'];
    }

    return mStatuses;
  }, [
    availableData,
    customer.customerModules,
    hasAnyRole,
    hasModuleRole,
    hasOnlyModuleRole,
    isAssociatedTo?.length,
    scanError,
  ]);

  return statuses;
};

export const InitialScanContext = createContext<{
  scanError?:AxiosError,
  setScanError:(scanError:AxiosError|undefined) => void,
  jobId?:number,
  setJobId: (jobId:number|undefined) => void,
    }>({
      setScanError: () => {},
      setJobId: () => {},
    });

export const WelcomeTileProvider = ({ children }:{children:ReactNode}) => {
  useContext(InitialScanContext);

  const [scanError, setScanError] = useState<AxiosError|undefined>();
  const [jobId, setJobId] = useState<number|undefined>();

  const value = useMemo(() => ({
    scanError,
    setScanError,
    jobId,
    setJobId,
  }), [jobId, scanError]);

  return (
    <InitialScanContext.Provider value={value}>
      {children}
    </InitialScanContext.Provider>
  );
};

export const Welcome = ({ availableData }:{availableData?:IAvailableCustomerData}) => {
  const { customer } = useAccount();
  const jobStartedRef = useRef(false);
  const {
    jobId, setJobId, scanError, setScanError,
  } = useContext(InitialScanContext);
  const invalidateAvailableData = useInvalidateAvailableCustomerData();

  const refetchInterval = 5000;

  const { data: job } = useApi<IJob>(
    jobId ? `jobs/${jobId}` : undefined,
    undefined,
    {
      refetchInterval,
      enabled: !!jobId && !availableData?.hasVulnerabilities,
    },
  );

  useEffect(() => {
    // Stop job status polling when job has completed
    if ([JobStatus.Finalized, JobStatus.Succeeded, JobStatus.Failed].includes(job?.status ?? JobStatus.Unknown)) {
      if (job?.status === JobStatus.Failed) {
        toast.error('Scan failed');
      }
      setJobId(undefined);
    }

    if (job?.status === JobStatus.Finalized) {
      toast.info('We\'re done scanning for vulnerabilities and your dashboard has been updated! Your connected systems will be continouly scanned and your dashboard will always reflect the current status.', {
        autoClose: 30000,
      });
      invalidateAvailableData();
    }
  }, [invalidateAvailableData, job?.status, setJobId]);

  useEffect(() => {
    // Make sure we only start one job
    if (jobStartedRef.current) {
      return;
    }

    // TODO: Currently we trigger the entraID module consent from dashboard. Since all other
    // modules with consent is currently dependent on this, we can safely assume that if
    // consent is available, it is entraID. This will probably not be the case in the future
    // and we'll need to adress this at some point.
    if (!availableData || !availableData.hasConsents || availableData.hasVulnerabilities) {
      return;
    }

    startVulnerabilityScan(Module.entraId, customer.id)
      .then((id) => setJobId(id))
      .catch((err) => {
        const axiosError = err as AxiosError;
        if (axiosError.response?.status === 409) {
          const errorDetails = axiosError.response?.data as IErrorDetails;
          if (errorDetails) {
            const details = errorDetails.errorDetails as {conflictingJobId:number};
            if (details && details.conflictingJobId) {
              setJobId(details.conflictingJobId);
            }
          }
        } else {
          setScanError(axiosError);
          // eslint-disable-next-line no-console
          console.error(`Unable to start scan from welcome tile: ${err}`);
        }
      });

    // Set flag indicating that a scan job has been started
    jobStartedRef.current = true;
  }, [availableData, customer.id, setJobId, setScanError]);

  const displayStatuses = useGetDisplayStatus(scanError);

  return displayStatuses.map((status) => (
    <StatusView key={status} status={status} scanError={scanError} />
  ));
};
