import React, { createContext, useContext } from 'react';

const lang = new Map<string, string>([
  ['vulnerability.action.revert', 'Attempt revert'],
  ['vulnerability.action.configure', 'Remediate'],
  ['vulnerability.action.analyze', 'Update analysis'],
  ['risk-status.open', 'Open'],
  ['risk-status.closed', 'Closed'],
  ['control.status.open', 'Not implemented'],
  ['control.status.mitigated', 'Implemented'],
  ['control.status.unknown', 'Unknown'],
  ['vulnerability.status.open', 'Open'],
  ['vulnerability.status.mitigated', 'Mitigated'],
  ['vulnerability.status.unknown', 'Unknown'],
  ['entity-type.risk_register', 'Risk'],
  ['entity-type.asset_register', 'Asset'],
  ['entity-type.news', 'News'],
  ['entity-type.vulnerability', 'Vulnerability'],
  ['entity-type.m365', 'Microsoft 365'],
  ['entity-type.customer', 'Customer'],
  ['asset-type.unknown', 'Unknown'],
  ['asset-type.saas', 'SaaS'],
  ['asset-type.organization', 'Organization'],
  ['asset-type.enterprise', 'Enterprise'],
  ['asset-type.device', 'Device'],
  ['asset-type.emailDomain', 'Email Domain'],
  ['security-level.unknown', 'Not set'],
  ['security-level.basic', 'Minimum'],
  ['security-level.basic.description', 'Represents the minimum safeguards every organization should apply. A basic enterprise is typically small or medium-sized with limited IT and cyber security expertise.'],
  ['security-level.improved', 'Standard'],
  ['security-level.improved.description', 'Safeguards for maturing enterprises that will further increase the security level. Improved enterprises typically store and process sensitive client or enterprise information in the cloud.'],
  ['security-level.advanced', 'Advanced'],
  ['security-level.advanced.description', 'Threat protection to defend against sophisticated adversaries and reduce the impact of zero-day attacks. Advanced controls are primarily applicable for large, security-minded enterprises.'],
  ['significance.veryLow', 'Very low'],
  ['significance.low', 'Low'],
  ['significance.medium', 'Medium'],
  ['significance.high', 'High'],
  ['significance.veryHigh', 'Very high'],
  ['significance.none', 'None'],
  ['significance.unknown', 'Unknown'],
  ['role.none', 'None'],
  ['role.read', 'Read'],
  ['role.readOwn', 'Read assigned'],
  ['role.readWrite', 'Read and write'],
  ['role.admin', 'Admin'],
  ['entity.status.pending', 'Pending activation'],
  ['entity.status.active', 'Enabled'],
  ['entity.status.invited', 'Invited'],
  ['entity.status.disabled', 'Disabled'],
  ['entity.status.disabledAndHidden', 'Deleted'],
  ['account-provider.azureAppRegistration', 'Azure Application'],
  ['account-provider.azureEntraIdUser', 'Azure Entra ID'],
  ['account-provider.none', 'Unknown provider'],
  ['asset.well-known-name.m365', 'Microsoft 365'],
  ['asset.well-known-name.entraid', 'Entra ID'],

  ['user-notification.product-info', 'Product updates and important information'],
  ['user-notification.assigned', 'Vulnerability was assigned to you'],
  ['user-notification.mentioned', 'You were mentioned in a comment'],
  ['user-notification.assigned-commented', 'Someone posted a comment on a vulnerability assigned to you'],
  ['user-notification.interacted-commented', 'Someone posted a comment on a vulnerability you\'ve interacted with'],
  ['user-notification.opened', 'Vulnerability opened'],
  ['user-notification.closed', 'Vulnerability mitigated'],
  ['user-notification.status-change', 'Vulnerability status change'],
  ['user-notification.2.opened', 'Risk opened'],
  ['user-notification.2.closed', 'Risk closed'],
  ['user-notification.2.status-change', 'Risk status change'],

  ['notification-delivery-status.pending', 'Pending'],
  ['notification-delivery-status.inProgress', 'In progress'],
  ['notification-delivery-status.sent', 'Sent'],
  ['notification-delivery-status.failed', 'Failed'],
]);

interface ITranslator {
  getString: (key: string, defaultValue?:string|undefined) => string,
}

const translator: ITranslator = {
  getString: (key:string, defaultValue:string|undefined = undefined) => lang.get(key) ?? defaultValue ?? key,
};

const TranslationContext = createContext(translator);

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('No TranslationContext found when calling useTranslation');
  }
  return context;
};

export const TranslationProvider = ({ children }: { children: React.ReactNode }) => (
  <TranslationContext.Provider value={translator}>
    { children }
  </TranslationContext.Provider>
);

export default TranslationProvider;
