import { Instance, createPopper } from '@popperjs/core';
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { CheckDropdownItem, CheckDropdownToggle } from './table/filters/FilterHelpers';

/**
 * React bootstrap Dropdown replacement tailored for our table filter usage.
 * Uses a custom popper instance that renders the dropdown in body to prevent clipping
 * to table container.
 */
export const NoClipDropdown = <T, >({
  className,
  filterLabel,
  values,
  checked,
  onCheck,
  size,
  itemId,
  itemLabel,
  disabled,
  filterId,
  multiselect,
}:{
  className:string|undefined
  filterLabel:ReactNode|string|undefined,
  itemLabel:(item:T) => ReactNode,
  itemId:(item:T) => string,
  filterId:string,
  values:readonly T[],
  size:'sm'|'lg'|undefined,
  checked:(value:unknown) => boolean,
  onCheck:(checked:boolean, value:T) => void,
  disabled?: boolean,
  multiselect?: boolean,
}) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [popper, setPopper] = useState<Instance>();
  const [lastUpdatePosition, setLastUpdatePosition] = useState<DOMRect>();
  const [currentPosition, setCurrentPosition] = useState<DOMRect>();

  useEffect(() => {
    if (toggleRef.current && popperRef.current) {
      const popperInstance = createPopper(toggleRef.current, popperRef.current, {
        placement: 'left-start',
        strategy: 'fixed',
      });

      setPopper(popperInstance);

      return () => {
        setPopper(undefined);
        popperInstance.destroy();
      };
    }

    return undefined;
  }, [popperRef, toggleRef]);

  useEffect(() => {
    if (disabled || !popper) return undefined;

    const interval = setInterval(() => {
      const p = dropdownRef.current?.getBoundingClientRect();
      const needUpdate = p?.x !== lastUpdatePosition?.x
        || p?.y !== lastUpdatePosition?.y
        || p?.width !== lastUpdatePosition?.width
        || p?.top !== lastUpdatePosition?.top
        || p?.left !== lastUpdatePosition?.left;

      if (needUpdate) {
        setCurrentPosition(p);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [
    popper,
    disabled,
    filterId,
    lastUpdatePosition?.x,
    lastUpdatePosition?.y,
    lastUpdatePosition?.width,
    lastUpdatePosition?.top,
    lastUpdatePosition?.left,
  ]);

  useEffect(() => {
    if (popper) {
      popper.update();
      setLastUpdatePosition(currentPosition);
    }
    // We need to update popper when values change, in case that affects dropdown size and thus positioning
  }, [popper, currentPosition]);

  return disabled
    ? <div className="form-control form-control-sm readonly rbt-input">All</div>
    : (
      <Dropdown className={className} ref={dropdownRef}>
        <Dropdown.Toggle ref={toggleRef} as={CheckDropdownToggle} id="dropdown-basic">
          {filterLabel}
        </Dropdown.Toggle>
        <div ref={popperRef}>
          <Dropdown.Menu
            className="check-dropdown-menu"
            style={{ maxWidth: '200px' }}
          >
            {values.map((i) => (
              <Dropdown.Item
                key={`${filterId}_${itemId(i) ?? 'empty'}`}
                id={`${filterId}_${itemId(i) ?? 'empty'}`}
                as={CheckDropdownItem}
                value={i}
                label={itemLabel(i)}
                checked={checked(i)}
                onCheck={async (wasChecked:boolean, value:unknown) => onCheck(wasChecked, value as T)}
                size={size}
                type={multiselect ? 'checkbox' : 'radio'}
              />
            ))}
          </Dropdown.Menu>
        </div>
      </Dropdown>
    );
};
