import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import { Spinner, Tabs } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const defaultActiveTabGetParamName = 'tab';

export const StatefulTabs = ({
  children,
  defaultActiveKey,
  className,
  mountOnEnter,
  activeTabParamName,
  onActiveTabChanged,
}:{
  children:ReactNode,
  defaultActiveKey:string,
  className?:string,
  mountOnEnter?:boolean,
  activeTabParamName?:string,
  onActiveTabChanged?:(key:string) => void
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKeyState] = useState<string>();

  const setActiveKey = useCallback((key:string) => {
    setActiveKeyState(key);
    if (onActiveTabChanged) onActiveTabChanged(key);
  }, [onActiveTabChanged]);

  useEffect(() => {
    const eventKey = searchParams.get(activeTabParamName ?? defaultActiveTabGetParamName) ?? defaultActiveKey;
    setActiveKey(eventKey as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateTab = useCallback((eventKey:string|null) => {
    if (!eventKey) {
      searchParams.delete(activeTabParamName ?? defaultActiveTabGetParamName);
    } else if (eventKey !== defaultActiveKey) {
      searchParams.set(activeTabParamName ?? defaultActiveTabGetParamName, eventKey);
    } else {
      searchParams.delete(activeTabParamName ?? defaultActiveTabGetParamName);
    }
    if (eventKey) {
      setActiveKey(eventKey);
    }
    setSearchParams(searchParams, {
      replace: true,
    });
  }, [activeTabParamName, defaultActiveKey, searchParams, setActiveKey, setSearchParams]);

  useEffect(() => {
    const paramsActiveTab = searchParams.get(activeTabParamName ?? defaultActiveTabGetParamName) ?? defaultActiveKey;
    if (activeKey !== paramsActiveTab) {
      setActiveKey(paramsActiveTab);
    }
  }, [activeKey, activeTabParamName, defaultActiveKey, searchParams, setActiveKey]);

  return activeKey
    ? (
      <Tabs
        className={className ?? ''}
        activeKey={activeKey}
        mountOnEnter={mountOnEnter}
        defaultActiveKey={defaultActiveKey}
        onSelect={activateTab}
      >
        {children}
      </Tabs>
    )
    : <Spinner animation="border" />;
};
