import React, {
  ReactNode, useCallback, useMemo, useState,
} from 'react';
import {
  Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { NavigateOptions, SetURLSearchParams } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  SecurityLevel, Severity, Significance, VulnerabilityStatus,
} from './Types';
import { IAsset } from '../../types/AssetsTypes';
import { IModule, Module } from '../../types/AccessTypes';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import './VulnerabilityFilter.css';
import { useAccount } from '../../providers/AccountContext';
import { useModules } from '../../providers/ModuleContext';
import { IVulnerabilityListOptions } from '../../types/Types';

type SearchQueryKeys = 'assetId'|'securityLevel'|'sourceModuleId'|'framework'|'impact'|'probability'|'expanded'|'status'|'cve'|'securityAdvisory'|'severity'|'relativeSeverity'|'updatedAfter'|'updatedBefore'|'createdAfter'|'createdBefore';

export const searchQueryKeys:Record<SearchQueryKeys, string> = {
  assetId: 'asset_id',
  severity: 'severity',
  relativeSeverity: 'criticality',
  securityLevel: 'level',
  sourceModuleId: 'module_id',
  framework: 'framework',
  impact: 'impact',
  probability: 'probability',
  status: 'status',
  expanded: 'expanded',
  cve: 'cve',
  securityAdvisory: 'advisory',
  updatedAfter: 'updated_after',
  updatedBefore: 'updated_before',
  createdAfter: 'created_after',
  createdBefore: 'created_before',
};

const trueAsString = 'true';

/**
 * Helper class for mapping vulnerability list options to and from search query.
 */
export class QueryUtil<TFilter extends IVulnerabilityListOptions, > {
  protected searchParams:URLSearchParams;

  protected setSearchParams:SetURLSearchParams|undefined;

  protected customerSecurityLevel:SecurityLevel;

  protected filters:TFilter;

  protected expanded:boolean;

  isExpanded() {
    return this.expanded;
  }

  setExpanded(value:boolean) {
    this.expanded = value;
    this.setFiltersAndUpdateSearchParams(this.filters, true);
  }

  // eslint-disable-next-line no-use-before-define
  protected onChange:(queryUtil:QueryUtil<TFilter>) => void;

  constructor(
    customerSecurityLevel:SecurityLevel,
    onChange:(queryUtil:QueryUtil<TFilter>) => void,
    searchParamsTuple: [searchParams:URLSearchParams, setSearchParams:SetURLSearchParams|undefined],
  ) {
    this.customerSecurityLevel = customerSecurityLevel;
    this.onChange = onChange;
    [this.searchParams, this.setSearchParams] = searchParamsTuple;
    this.filters = this.getFilterFromQueryParams();
    this.expanded = this.searchParams.get(searchQueryKeys.expanded)?.toLowerCase() === trueAsString;
  }

  protected setFilters(filters:TFilter) {
    this.filters = filters;

    if (filters.asset) {
      this.searchParams.set(searchQueryKeys.assetId, filters.asset);
    } else {
      this.searchParams.delete(searchQueryKeys.assetId);
    }
    if (filters.securityLevel || this.customerSecurityLevel) {
      this.searchParams.set(searchQueryKeys.securityLevel, filters.securityLevel || this.customerSecurityLevel);
    } else {
      this.searchParams.delete(searchQueryKeys.securityLevel);
    }
    if (filters.sourceModuleIds?.length) {
      this.searchParams.set(searchQueryKeys.sourceModuleId, filters.sourceModuleIds[0].toString());
    } else {
      this.searchParams.delete(searchQueryKeys.sourceModuleId);
    }
    if (filters.projectToFramework) {
      this.searchParams.set(searchQueryKeys.framework, filters.projectToFramework);
    } else {
      this.searchParams.delete(searchQueryKeys.framework);
    }
    if (filters.relativeSeverity) {
      filters.relativeSeverity.forEach((p) => {
        this.searchParams.append(searchQueryKeys.relativeSeverity, p);
      });
    } else {
      this.searchParams.delete(searchQueryKeys.relativeSeverity);
    }
    if (filters.impact?.length) {
      this.searchParams.set(searchQueryKeys.impact, filters.impact[0]);
    } else {
      this.searchParams.delete(searchQueryKeys.impact);
    }
    if (filters.probability?.length) {
      this.searchParams.set(searchQueryKeys.probability, filters.probability[0]);
    } else {
      this.searchParams.delete(searchQueryKeys.probability);
    }
    if (filters.status?.length) {
      this.searchParams.set(searchQueryKeys.status, filters.status[0]);
    } else {
      this.searchParams.delete(searchQueryKeys.status);
    }
    if (filters.cveIds?.length) {
      this.searchParams.set(searchQueryKeys.cve, filters.cveIds[0]);
    } else {
      this.searchParams.delete(searchQueryKeys.cve);
    }
    if (filters.securityAdvisoryIds?.length) {
      this.searchParams.set(searchQueryKeys.securityAdvisory, filters.securityAdvisoryIds[0]);
    } else {
      this.searchParams.delete(searchQueryKeys.securityAdvisory);
    }
    if (filters.updatedAfter) {
      this.searchParams.set(searchQueryKeys.updatedAfter, filters.updatedAfter.toISOString());
    } else {
      this.searchParams.delete(searchQueryKeys.updatedAfter);
    }
    if (filters.updatedBefore) {
      this.searchParams.set(searchQueryKeys.updatedBefore, filters.updatedBefore.toISOString());
    } else {
      this.searchParams.delete(searchQueryKeys.updatedBefore);
    }
    if (filters.createdAfter) {
      this.searchParams.set(searchQueryKeys.createdAfter, filters.createdAfter.toISOString());
    } else {
      this.searchParams.delete(searchQueryKeys.createdAfter);
    }
    if (filters.createdBefore) {
      this.searchParams.set(searchQueryKeys.createdBefore, filters.createdBefore.toISOString());
    } else {
      this.searchParams.delete(searchQueryKeys.createdBefore);
    }

    // Remove the id property used for scrolling to control, assuming it has been consumed
    this.searchParams.delete('id');
  }

  public setFiltersAndUpdateSearchParams(filters:TFilter, replace:boolean) {
    if (!this.setSearchParams) {
      throw new Error('Method setFiltersAndUpdateSearchParams is not supported');
    }
    const paramsBefore = this.searchParams.toString();

    this.setFilters(filters);

    if (this.expanded) {
      this.searchParams.set(searchQueryKeys.expanded, trueAsString);
    } else {
      this.searchParams.delete(searchQueryKeys.expanded);
    }

    if (this.searchParams.toString() !== paramsBefore) {
      this.setSearchParams(this.searchParams, {
        replace,
      } as NavigateOptions);
    }

    this.onChange(this);
  }

  getFilterFromQueryParams() {
    const filters = { ...this.getFilters() };

    if (this.searchParams.has(searchQueryKeys.assetId)) {
      filters.asset = this.searchParams.get(searchQueryKeys.assetId) as string;
    } else {
      filters.asset = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.securityLevel)) {
      filters.securityLevel = this.searchParams.get(searchQueryKeys.securityLevel) as SecurityLevel;
    } else {
      filters.securityLevel = this.customerSecurityLevel;
    }
    if (this.searchParams.has(searchQueryKeys.sourceModuleId)) {
      const sourceModuleIdAsString = this.searchParams.get(searchQueryKeys.sourceModuleId);
      if (sourceModuleIdAsString) {
        const sourceModuleId = parseInt(sourceModuleIdAsString, 10);
        if (sourceModuleId && !Number.isNaN(sourceModuleId)) {
          filters.sourceModuleIds = [sourceModuleId];
        }
      }
    }
    if (this.searchParams.has(searchQueryKeys.framework)) {
      filters.projectToFramework = this.searchParams.get(searchQueryKeys.framework) as string;
    } else {
      filters.projectToFramework = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.relativeSeverity)) {
      filters.relativeSeverity = this.searchParams.getAll(searchQueryKeys.relativeSeverity) as Severity[];
    } else {
      filters.relativeSeverity = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.impact)) {
      filters.impact = [this.searchParams.get(searchQueryKeys.impact) as Significance];
    } else {
      filters.impact = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.probability)) {
      filters.probability = [this.searchParams.get(searchQueryKeys.probability) as Significance];
    } else {
      filters.probability = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.status)) {
      filters.status = [this.searchParams.get(searchQueryKeys.status) as VulnerabilityStatus];
    } else {
      filters.status = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.cve)) {
      filters.cveIds = [this.searchParams.get(searchQueryKeys.cve) as string];
    } else {
      filters.cveIds = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.securityAdvisory)) {
      filters.securityAdvisoryIds = [this.searchParams.get(searchQueryKeys.securityAdvisory) as string];
    } else {
      filters.securityAdvisoryIds = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.updatedAfter)) {
      const s = this.searchParams.get(searchQueryKeys.updatedAfter);
      filters.updatedAfter = s ? new Date(s) : undefined;
    } else {
      filters.updatedAfter = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.updatedBefore)) {
      const s = this.searchParams.get(searchQueryKeys.updatedBefore);
      filters.updatedBefore = s ? new Date(s) : undefined;
    } else {
      filters.updatedBefore = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.createdAfter)) {
      const s = this.searchParams.get(searchQueryKeys.createdAfter);
      filters.createdAfter = s ? new Date(s) : undefined;
    } else {
      filters.createdAfter = undefined;
    }
    if (this.searchParams.has(searchQueryKeys.createdBefore)) {
      const s = this.searchParams.get(searchQueryKeys.createdBefore);
      filters.createdBefore = s ? new Date(s) : undefined;
    } else {
      filters.createdBefore = undefined;
    }

    return filters;
  }

  getFilters() {
    return this.filters;
  }

  static getSearchQueryKeys() {
    const keys = Object.keys(searchQueryKeys) as (keyof typeof searchQueryKeys)[];
    return keys.map((k) => searchQueryKeys[k]);
  }

  resetFilters() {
    const searchQueryNames = QueryUtil.getSearchQueryKeys();
    searchQueryNames.forEach((n) => {
      if (n !== searchQueryKeys.expanded) {
        this.searchParams.delete(n);
      }
    });
    if (this.setSearchParams) {
      this.setSearchParams(this.searchParams);
    }
    this.filters = this.getFilterFromQueryParams();
    this.onChange(this);
  }

  isAssetIdDirty() {
    return !!this.filters?.asset;
  }

  isSecurityLevelDirty() {
    return this.filters?.securityLevel !== this.customerSecurityLevel;
  }

  isSourceModuleDirty() {
    return !!this.filters?.sourceModuleIds?.length;
  }

  isFrameworkDirty() {
    return !!this.filters?.projectToFramework && this.filters.projectToFramework !== 'ivolv';
  }

  isProbabilityDirty() {
    return !!this.filters?.probability;
  }

  isImpactDirty() {
    return !!this.filters?.impact;
  }

  isStatusDirty() {
    return !!this.filters?.status;
  }

  isDirty() {
    return this.isAssetIdDirty()
      || this.isSecurityLevelDirty()
      || this.isSourceModuleDirty()
      || this.isFrameworkDirty();
  }
}

interface IProps<
TFilter extends IVulnerabilityListOptions,
TQueryUtil extends QueryUtil<TFilter>
> {
  customerSecurityLevel:SecurityLevel,
  queryUtil:TQueryUtil,
  setFilters?:(filter:TFilter) => void,
  children?: ReactNode,
  childrenFilterLabel?: ReactNode|string
}

export const VulnerabilityFilter = <
TFilter extends IVulnerabilityListOptions,
TQueryUtil extends QueryUtil<TFilter>,
>(props:IProps<TFilter, TQueryUtil>) => {
  const {
    queryUtil,
    setFilters,
    // napshots,
    children,
  } = props;

  const { hasModuleRole } = useAccount();

  const [assetFilter, setAssetFilter] = useState<string>();

  const filters = queryUtil.getFilters();
  const currentFrameworkFriendlyId = queryUtil?.isFrameworkDirty() ? filters.projectToFramework : undefined;

  const syncFilters = useCallback((updatedFilters:TFilter) => {
    queryUtil.setFiltersAndUpdateSearchParams(updatedFilters, true);
    if (setFilters) setFilters(updatedFilters);
  }, [queryUtil, setFilters]);

  const { getModuleByIdOrUndefined } = useModules();

  const {
    data: pagedAssets,
    isLoading: isAssetsLoading,
  } = useApi<PagedResult<IAsset>>(
    assetFilter && 'assets',
    {
      search: assetFilter,
    },
  );

  const {
    data: filterAsset,
  } = useApi<IAsset>(
    hasModuleRole(Module.assets, 'read') && !!(filters?.asset?.length) && `assets/${encodeURIComponent(filters.asset)}`,
  );

  const selectableModules = useMemo(() => {
    const appendIfExists = (modules:IModule[], module:Module) => {
      const m = getModuleByIdOrUndefined(module);
      if (m) modules.push(m);
      return m;
    };

    const mSelectableModules:IModule[] = [];

    if (hasModuleRole(Module.m365, 'read')) {
      appendIfExists(mSelectableModules, Module.m365);
    }
    if (hasModuleRole(Module.entraId, 'read')) {
      appendIfExists(mSelectableModules, Module.entraId);
    }
    if (hasModuleRole(Module.assessment, 'read')) {
      appendIfExists(mSelectableModules, Module.assessment);
    }
    if (hasModuleRole(Module.mailAuthenticity, 'read')) {
      appendIfExists(mSelectableModules, Module.mailAuthenticity);
    }
    if (hasModuleRole(Module.defender, 'read')) {
      appendIfExists(mSelectableModules, Module.defender);
    }

    return mSelectableModules;
  }, [getModuleByIdOrUndefined, hasModuleRole]);

  return queryUtil.isDirty()
    ? (
      <Card className={`${queryUtil?.isDirty() ? 'dirty ' : ''}vulnerability-filter-card expanded`}>
        <Card.Header
          onClick={() => queryUtil.setExpanded(false)}
          role="button"
          aria-label="Collapse filters"
        >
          <Card.Title>
            <Icon name="filter" className="me-2" />
            Filters
          </Card.Title>
        </Card.Header>
        <Card.Body>

          <>
            { (currentFrameworkFriendlyId && currentFrameworkFriendlyId !== 'ivolv')
              ? (
                <Row>
                  { currentFrameworkFriendlyId && currentFrameworkFriendlyId !== 'ivolv'
                    ? (
                      <Col md={12} className="mb-3">
                        <Form.Label>Security control framework:</Form.Label>
                        <div className="form-control has-selection position-relative">
                          { currentFrameworkFriendlyId }
                          <Button
                            variant="text"
                            className="form-control-button"
                            onClick={() => {
                              syncFilters({
                                ...filters,
                                projectToFramework: undefined,
                              });
                            }}
                          >
                            <Icon name="x-square" />
                          </Button>
                        </div>
                      </Col>
                    )
                    : null}
                </Row>
              ) : null }

            <Row>
              { hasModuleRole(Module.assets, 'read') || filters?.asset
                ? (
                  <Col md={12} className="mb-3">
                    <Form.Label>Asset</Form.Label>
                    { hasModuleRole(Module.assets, 'read') && !filters?.asset
                      ? (
                        <AsyncTypeahead
                          id="vuln-filter-asset"
                          onSearch={(s) => {
                            setAssetFilter(s);
                          }}
                          filterBy={() => true}
                          labelKey="name"
                          clearButton
                          placeholder="All"
                          minLength={1}
                          options={pagedAssets?.items ?? []}
                          isLoading={!!assetFilter && isAssetsLoading}
                          renderMenuItemChildren={(o) => {
                            const asset = o as IAsset;
                            return <div>{asset.name}</div>;
                          }}
                          onChange={(o) => {
                            const assets = o as IAsset[];

                            if (assets.length > 0) {
                              syncFilters({
                                ...filters,
                                assetId: assets[0].id,
                              });
                            } else if (filters) {
                              filters.asset = undefined;
                              syncFilters(filters);
                            }
                          }}
                        />
                      )
                      : (
                        <div className="form-control position-relative has-selection">
                          {hasModuleRole(Module.assets, 'read')
                            ? filterAsset
                              ? filterAsset.name
                              : <Spinner animation="grow" size="sm" />
                            : filters?.asset}
                          <Button
                            variant="text"
                            className="form-control-button"
                            onClick={() => {
                              if (filters?.asset) {
                                filters.asset = undefined;
                                syncFilters(filters);
                              }
                            }}
                          >
                            <Icon name="x-square" />
                          </Button>
                        </div>
                      ) }
                  </Col>
                ) : null }
              { filters?.sourceModuleIds
                ? (
                  <Col md={12} className="mb-3">
                    <Form.Label>Source module</Form.Label>
                    <Form.Select
                      value={filters?.sourceModuleIds && !Number.isNaN(filters?.sourceModuleIds[0])
                        ? filters?.sourceModuleIds[0]
                        : 'all'}
                      className={queryUtil.isSourceModuleDirty() ? 'has-selection' : undefined}
                      onChange={(e) => {
                        const moduleId = parseInt(e.target.value, 10);
                        syncFilters({
                          ...filters,
                          sourceModuleId: moduleId && !Number.isNaN(moduleId) ? moduleId : undefined,
                        });
                      }}
                    >
                      <option value="all">All</option>
                      { selectableModules.map((m) => (
                        <option
                          key={m.id}
                          value={m.id}
                        >
                          { m.name }
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                ) : null }
            </Row>
            { children }
          </>
        </Card.Body>
      </Card>
    )
    : null;
};

export default VulnerabilityFilter;
