import React, { ReactElement } from 'react';
import { Icon } from '@ailibs/feather-react-ts';
import {
  Alert, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { IConsent, m365PricipalRoleName } from '../../types/AdminTypes';
import RenderHtml from '../../components/RenderHtml';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountContext';
import { useApi } from '../../query/GenericQuery';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { useModules } from '../../providers/ModuleContext';

export const useModuleConsent = () => {
  const { hasModuleRole } = useAccount();
  const { data: allConsents, isLoading } = useApi<IConsent[]>(
    'consents',
    undefined,
    {
      enabled: hasModuleRole(Module.customerAdmin, 'readWrite'),
      // Refetch on window focus as consent is given in a popup
      refetchOnWindowFocus: true,
    },
  );

  return {
    isLoading,
    hasPending: (
      !!allConsents?.find((c) => c.availableVersion > (c.customer?.version ?? c.missingRoles.length))
    ),
    consents: allConsents,
    getModuleConsent: (module:Module) => allConsents?.find((c) => c.moduleId === module),
  };
};

export const ConsentUpdateButton = ({
  consent,
  getAddConsentInfo,
}:{
  consent:IConsent|undefined,
  getAddConsentInfo?:(moduleId:number) => string|ReactElement|undefined|null
}) => {
  const { hasModuleRole } = useAccount();
  const { getModuleNameOrDefault } = useModules();

  if (!consent) {
    return null;
  }

  const moduleName = getModuleNameOrDefault(consent?.moduleId);

  return (
    <div>
      { consent.customer?.version === consent.availableVersion
        ? (
          <div>
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip className="text-nowrap">
                  TenantId:
                  {' '}
                  {consent.customer.targetId}
                </Tooltip>
              )}
            >
              <span>
                <Icon name="check-square" />
                {' '}
                {moduleName}
                {' '}
                { consent.missingRoles.length
                  ? 'is connected.'
                  : 'is connected and active.'}
              </span>
            </OverlayTrigger>
            { consent.missingRoles.length
              ? (
                <Alert variant="warning" className="p-3 mt-2">
                  <div>
                    The module is connected but cannot be activated due to a missing role requirement.
                    <div className="mt-3">
                      Please add Service Principal
                      {' '}
                      <code><ClipboardCopy>{m365PricipalRoleName}</ClipboardCopy></code>
                      {' '}
                      to the Azure AD Role
                      {' '}
                      <code><ClipboardCopy>Global Reader</ClipboardCopy></code>
                      {' '}
                      to enable the module. Contact
                      {' '}
                      <a href="mailto:support@ivolv.no">support@ivolv.no</a>
                      {' '}
                      if you have any questions or require assistance.
                    </div>
                  </div>
                </Alert>
              )
              : null }
          </div>
        )
        : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            { hasModuleRole(Module.customerAdmin, 'readWrite') ? (
              <div>
                { consent?.customer?.version
                  ? (
                    <div className="mb-3">
                      The required permissions for this service have changed.
                      To maintain uninterrupted service, please update the granted permissions.
                      <Alert variant="info" className="p-2 mt-3">
                        <RenderHtml>
                          {consent.changeNotes}
                        </RenderHtml>
                      </Alert>
                    </div>
                  )
                  : (
                    <div className="mb-3">
                      { (getAddConsentInfo && getAddConsentInfo(consent.moduleId))
                      ?? (
                        <>
                          <p>
                            You&apos;re just one step away from getting access to your
                            {' '}
                            {moduleName}
                            {' '}
                            vulnerabilities.
                          </p>
                          <p>
                            Please connect the service to activate it.
                          </p>
                        </>
                      ) }
                    </div>
                  )}
                <Button
                  className="mt-1"
                  onClick={() => window.open(consent.consentUrl)}
                >
                  <Icon name="external-link" size={20} className="me-1 pb-1" />
                  { consent?.customer?.version
                    ? `Update permissions for ${moduleName}`
                    : `Connect ${moduleName}` }
                </Button>
              </div>
            )
              : (
                <Alert key="warning" variant="warning" className="p-3">
                  <span>
                    <Icon name="message-square" />
                    {' '}
                    {moduleName}
                    {' '}
                    is not connected, and you lack the required privileges to connect.
                  </span>
                </Alert>
              )}
          </>
        )}
    </div>
  );
};
