import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useSettingsContext from '../contexts/SettingsContext';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import { useBearerTokenContext } from '../providers/BearerTokenContext';

export const WithAxiosBearer = (
  { children }: {children:React.ReactElement },
) => {
  const { activeCustomerId } = useSettingsContext();
  const { tokenData, refresh: refreshToken } = useBearerTokenContext();
  const [interceptorsAdded, setInterceptorsAdded] = useState(false);

  useEffect(() => {
    const interceptors:number[] = [];

    // Configure an interceptor witch shows a toast message on errors.
    interceptors.push(axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          refreshToken();
        }
        return Promise.reject(error);
      },
    ));

    interceptors.push(axios.interceptors.request.use(
      async (httpConfig) => {
        // eslint-disable-next-line no-param-reassign
        httpConfig.headers.Authorization = `Bearer ${tokenData.token}`;

        if (activeCustomerId) {
          // eslint-disable-next-line no-param-reassign
          httpConfig.headers['algiz-customer-id'] = activeCustomerId;
        }
        return httpConfig;
      },
      (error) => Promise.reject(error),
    ));

    setInterceptorsAdded(true);

    return () => {
      setInterceptorsAdded(false);
      interceptors.forEach((i) => axios.interceptors.request.eject(i));
    };
  }, [activeCustomerId, refreshToken, tokenData]);

  return interceptorsAdded
    ? children
    : (
      <LoadingTemplate>
        Adding Axios interceptors
      </LoadingTemplate>
    );
};
