import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TermsAndConditions } from './TermsAndConditions';

export const TermsAndConditionsCard = () => {
  const navigate = useNavigate();

  return (
    <Card className="card-md fill">
      <Card.Header>
        <Card.Title>
          Bruksvilkår for Ivolv Cybersecurity Platform
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <TermsAndConditions />
      </Card.Body>
      <Card.Footer>
        <Button
          onClick={() => { navigate('/'); }}
          variant="primary"
        >
          Close
        </Button>
      </Card.Footer>
    </Card>
  );
};
