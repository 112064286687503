import React, { useCallback, useMemo } from 'react';
import {
  Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { AdminAccount, AdminCustomer } from '../../types/AdminTypes';
import AdminUserAddForm from './AdminUserAddForm';
import { AdminAccountDetailsModalContent } from './AdminAccountDetailsModalContent';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useTranslation } from '../../providers/TranslationProvider';
import { AccountProvider, Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountContext';
import { useGetAccountProviderAsText } from '../../utils/TranslationUtils';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { ActiveStatusBadge } from '../../components/ActiveStatusBadge';
import { PagedTable, createPagedColumnHelper, usePagedTableFilter } from '../../common/table';

interface ICustomerAccountFilter {
    search?:string,
    externalId?:string,
    active?:boolean
}

export const AdminCustomerAccountTab = (props:{customer:AdminCustomer}) => {
  const { customer } = props;

  const getAccountProviderAsText = useGetAccountProviderAsText();
  const { hasModuleRole } = useAccount();
  const i18n = useTranslation();

  const { pushModal } = useNewModalContext();

  const handleShowAccount = (account: AdminAccount) => {
    pushModal({
      title: 'Account details',
      size: 'xl',
      ModalBodyAndFooter: ({ close, setValue, value }) => (
        <AdminAccountDetailsModalContent close={close} setValue={setValue} value={value} />
      ),
      value: {
        account,
        customer,
      },
    });
  };

  const columnHelper = createPagedColumnHelper<AdminAccount>();

  const tableFilters = usePagedTableFilter<ICustomerAccountFilter>(
    `admin-${customer.id}-customers-accounts`,
    { },
  );

  const { appendQuery } = tableFilters;

  const userColumns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        filterPropertyName: 'search',
        updateFilterFn: (values:string[]) => {
          appendQuery({
            search: values ? values[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('externalId', {
        header: 'ExternalId',
        cell: ({ value, row }) => (row.original.provider === 'azureAppRegistration'
          ? <code>{value}</code>
          : <span>{value}</span>),
        updateFilterFn: (values:string[]) => {
          appendQuery({
            externalId: values ? values[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('provider', {
        header: 'Provider',
        cell: ({ value }) => getAccountProviderAsText(value),
        formatter: (provider:AccountProvider) => getAccountProviderAsText(provider),
      }),
      columnHelper.accessor('status', {
        header: 'Enabled',
        cell: ({ value }) => <ActiveStatusBadge status={value} />,
        formatter: (value) => i18n.getString(`entity.status.${value}`),
      }),
    ],
    [appendQuery, columnHelper, getAccountProviderAsText, i18n],
  );

  const { data: pagedAccounts, invalidate: refreshAccounts } = useApi<PagedResult<AdminAccount>>(
    'module/admin/accounts',
    {
      ...tableFilters.query,
      customerId: customer.id,
    },
  );

  const pushAddAccountModal = useCallback(async () => {
    if (await pushModal({
      title: 'Add account',
      size: 'lg',
      ModalBodyAndFooter: ({ close }) => (
        <Modal.Body>
          <AdminUserAddForm
            customer={customer}
            handleClose={(update:boolean|undefined) => close(!!update, true)}
          />
        </Modal.Body>
      ),
    })) {
      await refreshAccounts();
    }
  }, [customer, pushModal, refreshAccounts]);

  return !pagedAccounts
    ? <Spinner animation="border" />
    : (
      <>
        <Row>
          <Col md={12} className="overflow-auto">
            <PagedTable
              data={pagedAccounts}
              filters={tableFilters}
              columnDefs={userColumns}
              disableColumnSelect
              disablePagination={pagedAccounts.items.length <= 10}
              onRowClick={(_, account) => {
                handleShowAccount(account);
              }}
              rowClassNameResolver={(account) => {
                if (account.provider === 'azureAppRegistration') {
                  if (!customer.canBeAdmin) {
                    return 'text-muted account-not-supported';
                  }
                }
                return !account.active ? 'text-muted' : undefined;
              }}
            />
          </Col>
        </Row>
        { !hasModuleRole(Module.admin, 'readWrite') ? null : (
          <Row className="mt-3">
            <Col md={12}>
              <Button onClick={async () => {
                await pushAddAccountModal();
              }}
              >
                Add account
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
};

export default AdminCustomerAccountTab;
