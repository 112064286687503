import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useApi } from '../query/GenericQuery';
import { IUser } from '../types/AccessTypes';

const extractTokenRange = (input:string, idx:number) => {
  const range = { start: -1, end: -1 };

  // Grow range to find potential start and end of token
  // Limiting to 256 chars in each direction, should be enough for normal accounts.
  for (let i = idx; i >= Math.max(0, idx - 256); i -= 1) {
    if (input[i] === '@' && i < input.length && input[i + 1] === '(') {
      range.start = i;
      break;
    }
  }
  for (let i = idx; i <= Math.min(input.length, idx + 256); i += 1) {
    if (input[i] === ')') {
      range.end = i + 1;
      break;
    }
  }

  if (range.start < 0 || range.end < 0) {
    return null;
  }

  const potentialToken = input.substring(range.start, range.end);
  return /^@\(([^)]*?)\)$/.test(potentialToken)
    ? range
    : null;
};

export const TextAreaWithMentions = ({
  value,
  disabled,
  onFocus,
  onBlur,
  className,
  placeholder,
  onChange,
}:{
  value:string,
  disabled?:boolean,
  onFocus: () => void,
  onBlur: () => void,
  className?: string,
  placeholder?: string,
  onChange: (value:string) => void,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef(null);
  const mirrorRef = useRef<HTMLDivElement>(null);

  const [popupVisible, setPopupDialogVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [cursorRestoreRange, setCursorRestoreRange] = useState({ start: 0, end: 0 });

  const { data: assignableUsers } = useApi<IUser[]>('vulnerabilities/assignableUsers');

  const assignableExternalIds = assignableUsers?.map((u) => u.externalId) ?? [];

  const selectAtSymbol = useCallback(() => {
    if (textareaRef.current) {
      const rangeStart = cursorRestoreRange.start;
      textareaRef.current.setSelectionRange(
        rangeStart - 1,
        rangeStart,
      );
    }
  }, [cursorRestoreRange.start]);

  const setPopupVisible = useCallback((v:boolean) => {
    if (popupVisible === v) return;
    setPopupDialogVisible(v);
    if (textareaRef.current) {
      textareaRef.current?.focus();
    }
  }, [popupVisible]);

  const getCursorPosition = useCallback(() => {
    if (!textareaRef.current || !mirrorRef.current) return null;

    const textarea = textareaRef.current;

    const cursorPosition = textarea.selectionStart;

    const textBeforeCursor = textarea.value.substring(0, cursorPosition);
    const textAfterCursor = textarea.value.substring(cursorPosition);

    const pre = document.createTextNode(textBeforeCursor);
    const post = document.createTextNode(textAfterCursor);
    const caretEle = document.createElement('span');
    caretEle.innerHTML = '&nbsp;';

    mirrorRef.current.innerHTML = '';
    mirrorRef.current.append(pre, caretEle, post);

    const rect = caretEle.getBoundingClientRect();

    return {
      top: rect.top - textareaRef.current.scrollTop,
      left: rect.left - textareaRef.current.scrollLeft,
    };
  }, []);

  const insertMention = useCallback((m:string) => {
    onChange(`${value.substring(0, cursorRestoreRange.start)}(${m})${value.substring(cursorRestoreRange.end)}`);
    setTimeout(() => {
      if (textareaRef.current) {
        const rangeStart = cursorRestoreRange.start + m.length + 2;
        textareaRef.current.setSelectionRange(
          rangeStart,
          rangeStart,
        );
      }
    });
    setPopupVisible(false);
  }, [cursorRestoreRange, onChange, setPopupVisible, value]);

  const handleKeyDown = useCallback((event:React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (popupVisible || event.metaKey || event.ctrlKey || event.shiftKey || event.altKey) {
      return;
    }

    if (event.key === 'Backspace' && textareaRef.current) {
      const textarea = textareaRef.current;
      if (textarea.selectionStart === textarea.selectionEnd) {
        const tokenRange = extractTokenRange(value, textarea.selectionStart - 1);
        if (tokenRange) {
          textarea.setSelectionRange(tokenRange.start, tokenRange.end, 'forward');
          event.preventDefault();
        }
      }
      return;
    }

    if (event.key === '@') {
      if (textareaRef.current) {
        setCursorRestoreRange({
          start: textareaRef.current.selectionStart + 1,
          end: textareaRef.current.selectionEnd + 1,
        });
      }
      setTimeout(() => {
        const position = getCursorPosition();
        if (position) setPopupPosition(position);
        setPopupVisible(true);
      });
    } else if (popupVisible) {
      setPopupVisible(false);
    }
  }, [getCursorPosition, popupVisible, setPopupVisible, value]);

  const handleWindowScroll = useCallback(() => {
    // If popup is visible, no account selected, mark @ when closing selector on scroll
    if (popupVisible) selectAtSymbol();
    setPopupVisible(false);
  }, [popupVisible, selectAtSymbol, setPopupVisible]);

  useEffect(() => {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.addEventListener('scroll', handleWindowScroll);
    }
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      if (mainContent) {
        mainContent.removeEventListener('scroll', handleWindowScroll);
      }
    };
  }, [handleWindowScroll]);

  return (
    <div ref={containerRef} style={{ position: 'relative' }} className="textarea-mentions">
      <Form.Control
        ref={textareaRef}
        as="textarea"
        value={value}
        onScroll={() => {
          if (popupVisible) setPopupVisible(false);
        }}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        className={className}
        placeholder={placeholder}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
      />
      <div
        ref={mirrorRef}
        className="textarea-mirror"
      />
      {popupVisible && (
        <div
          className="mention-popup"
          style={{
            top: popupPosition.top,
            left: popupPosition.left,
          }}
        >
          <div className="d-flex">
            <div className="symbol">
              <span>@</span>
            </div>
            <div className="flex-grow-1 mention-input">
              <Typeahead
                id="mention-selector"
                style={{ paddingLeft: '3px', paddingRight: '0' }}
                options={assignableExternalIds}
                onBlur={() => setPopupVisible(false)}
                highlightOnlyResult
                align="left"
                dropup
                onKeyDown={(e) => {
                  const target = e.target as HTMLInputElement|undefined;
                  const isEmptyBackspace = (e.key === 'Backspace' && !target?.value.length);
                  if (e.key === 'Escape' || isEmptyBackspace) {
                    e.preventDefault();
                    if (isEmptyBackspace) {
                      selectAtSymbol();
                    }
                    setPopupVisible(false);
                  }
                }}
                onChange={(e) => {
                  insertMention(e.length ? e[0].toString() : '');
                }}
                autoFocus
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
