import React, { useMemo } from 'react';
import {
  Alert,
  Button,
  Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CommonModal } from '../../common/modal';
import { ModulePermissionsSelect } from '../admin/ModulePermissions';
import { CustomerVisibleModules, IHaveModuleRoles, Module } from '../../types/AccessTypes';
import { ICustomerSettingsUser } from './CustomerTypes';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { useAccount } from '../../providers/AccountContext';
import { ActiveStatusBadge } from '../../components/ActiveStatusBadge';

interface IProps {
  user: ICustomerSettingsUser,
  disabled: boolean,
  show:boolean,
  handleClose: () => void
}

const CustomerUserDetailsModal = (props:IProps) => {
  const {
    user, handleClose, disabled, show,
  } = props;

  const {
    customer, user: currentUser, refreshAccess, hasModuleRole,
  } = useAccount();

  const {
    data: haveUserRoles,
    invalidate: invalidateUserRoles,
  } = useApi<IHaveModuleRoles>(
    `module/customer/accounts/${encodeURIComponent(user.id)}/permissions`,
    {
      includeInherited: false,
    },
  );

  const {
    data: haveEffectiveRoles,
  } = useApi<IHaveModuleRoles>(
    `module/customer/accounts/${encodeURIComponent(user.id)}/permissions`,
  );

  const invalidateCustomerUsers = useInvalidateQueries('module/customer/accounts');

  const saveUserMutation = useMutation({
    mutationFn: (data:ICustomerSettingsUser) => axios.put<ICustomerSettingsUser>(
      '/api/v1/module/customer/account',
      data,
    ),
    onSuccess: async () => {
      await invalidateUserRoles();
      await invalidateCustomerUsers();
      await refreshAccess();
    },
  });

  const initialValues = useMemo(() => (
    {
      ...user,
      moduleRoles: haveUserRoles?.moduleRoles,
    } as ICustomerSettingsUser
  ), [user, haveUserRoles]);

  const visibleModules = useMemo(() => (
    customer.customerModules.filter((m) => CustomerVisibleModules.includes(m.module.id))
  ), [customer.customerModules]);

  const isCurrentUser = currentUser?.externalId === user.externalId;

  const isDisabled = !hasModuleRole(Module.customerAdmin, 'readWrite') || disabled;

  return (
    <CommonModal
      title={user.name ?? 'Loading...'}
      show={show}
      handleClose={handleClose}
      size="lg"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, helpers) => {
          await saveUserMutation.mutateAsync({
            ...user,
            moduleRoles: values.moduleRoles,
            active: values.active,
          } as ICustomerSettingsUser);
          helpers.resetForm({ values });
          toast.success('User was saved', {
            toastId: 'user-save',
            updateId: 'user-save',
          });
        }}
      >
        {({
          values, handleSubmit, setFieldValue, dirty, handleReset, handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} className="mb-3">
                <>
                  <Form.Label>UPN:</Form.Label>
                  <Form.Control
                    name="upn"
                    value={values.externalId}
                    disabled
                    onChange={handleChange}
                  />
                </>
                { values.externalId !== user.externalId
                  ? (
                    <Alert className="mt-2 p-3" variant="warning">
                      Exercise caution when modifying the UPN, as it affects how the account is
                      linked to your directory. This action is advisable only if you&apos;ve made a
                      typographical error and the corrected UPN exists in the directory service.
                    </Alert>
                  )
                  : null }
              </Col>
            </Row>
            <Row>
              <Col sm={8} className="mb-3">
                <>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    name="name"
                    value={values.name}
                    disabled
                    onChange={handleChange}
                  />
                </>
                { values.name !== user.name
                  ? (
                    <Alert className="mt-2 p-3" variant="warning">
                      User&apos;s name originates from your directory service.
                      It will be refreshed with the directory when the user logs in.
                    </Alert>
                  )
                  : null }
              </Col>
              <Col sm={4} className="mb-3">
                <Form.Check
                  name="active"
                  checked={values.active}
                  label="Enabled?"
                  id={`active${user.id}`}
                  disabled={!['disabled', 'active'].includes(user.status) || isDisabled || isCurrentUser || !['disabled', 'active'].includes(user.status)}
                  onChange={handleChange}
                />
                { !['disabled', 'active'].includes(user.status)
                  ? <ActiveStatusBadge status={user.status} />
                  : null }
              </Col>
            </Row>
            { values.moduleRoles
              ? (
                <Row>
                  <Col md={8} className="mb-3">
                    <Form.Label>Module permissions:</Form.Label>
                    { customer?.id
                      ? (
                        <ModulePermissionsSelect
                          customerModules={visibleModules}
                          moduleRoles={values.moduleRoles}
                          effectiveRoles={haveEffectiveRoles?.moduleRoles}
                          showHeaders
                          disabled={(moduleId) => (isCurrentUser && moduleId === Module.customerAdmin) || isDisabled}
                          onChange={(r) => {
                            setFieldValue('moduleRoles', r);
                          }}
                        />
                      )
                      : <Spinner animation="border" /> }
                    <Alert variant="info" className="p-2">
                      Users can receive permissions explicitly here or through user groups.
                      The combined user and group permissions result in the effective permissions listed above.
                      Please note that currently only Ivolv personall can modify group permissions.
                    </Alert>
                  </Col>
                </Row>
              )
              : null }
            { hasModuleRole(Module.customerAdmin, 'readWrite')
              ? (
                <Row>
                  <Col md={12}>
                    { !values.moduleRoles
                      ? <Spinner animation="border" />
                      : (
                        <Stack direction="horizontal" gap={2}>
                          <Button type="submit" disabled={!dirty}>Save and close</Button>
                          <Button type="reset" variant="secondary" disabled={!dirty} onClick={handleReset}>Undo</Button>
                        </Stack>
                      ) }
                  </Col>
                </Row>
              )
              : null }
          </Form>
        )}
      </Formik>
    </CommonModal>
  );
};

export default CustomerUserDetailsModal;
