import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Form, Spinner, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useGetUserNotificationAsText } from '../../utils/TranslationUtils';
import { useAccount } from '../../providers/AccountContext';
import { useModules } from '../../providers/ModuleContext';
import {
  ALL_ROLES, IModule, Module, Role,
  VulnerabilityModules,
} from '../../types/AccessTypes';
import { useApiLoaderData } from '../../query/GenericQuery';

interface INotification {
  value:boolean|null
  role?: Role,
  global?: boolean
}

export interface IUserSetting {
  key:string,
  value:string,
  module:Module
}

type genericSettingIds = 'product-info';

type riskSettingIds = 'opened'|'closed'|'status-change';
type vulnerabilitySettingIds = 'opened'|'closed'|'status-change'|'assigned'|'assigned-commented'|'interacted-commented'|'mentioned';

const defaultGenericNotifications:Record<genericSettingIds, INotification> = {
  'product-info': {
    value: true,
  },
};

const defaultVulnerabilityNotifications:Record<vulnerabilitySettingIds, INotification> = {
  assigned: {
    value: true,
    role: 'readOwn',
    global: true,
  },
  mentioned: {
    value: true,
    role: 'read',
    global: true,
  },
  'assigned-commented': {
    value: true,
    role: 'readOwn',
    global: true,
  },
  'interacted-commented': {
    value: true,
    role: 'readOwn',
    global: true,
  },
  opened: {
    value: true,
    role: 'read',
  },
  closed: {
    value: false,
    role: 'read',
  },
  'status-change': {
    value: true,
    role: 'read',
  },
};

const defaultRiskNotifications:Record<riskSettingIds, INotification> = {
  opened: {
    value: false,
    role: 'readWrite',
  },
  closed: {
    value: false,
    role: 'readWrite',
  },
  'status-change': {
    value: false,
    role: 'readWrite',
  },
};

const getDefaults = (customerModules:IModule[], keepDefaultValues:boolean) => {
  const initialState:Record<number, Record<string, INotification>> = {};

  initialState[Module.none] = structuredClone(defaultGenericNotifications);
  initialState[Module.vulnerability] = structuredClone(defaultVulnerabilityNotifications);
  initialState[Module.risk] = structuredClone(defaultRiskNotifications);

  if (!keepDefaultValues) {
    Object.keys(initialState).forEach((moduleIdAsString) => {
      const moduleId = parseInt(moduleIdAsString, 10);
      Object.keys(initialState[moduleId]).forEach((key) => {
        initialState[moduleId][key].value = false;
      });
    });
  }

  customerModules.forEach((m) => {
    if (!m.jobTypeKeys?.includes('scan')) {
      return;
    }

    initialState[m.id] = {};
    Object.keys(defaultVulnerabilityNotifications).forEach((key) => {
      initialState[m.id][key] = {
        id: key,
        role: defaultVulnerabilityNotifications[key as vulnerabilitySettingIds].role,
        value: null,
        global: defaultVulnerabilityNotifications[key as vulnerabilitySettingIds].global,
      } as INotification;
    });
  });

  return initialState;
};

const notificationSettingPrefix = 'notification.';

const initializeFromSettings = (
  state:Record<number, Record<string, INotification>>,
  settings:IUserSetting[],
) => {
  const newState = { ...state };

  settings
    .filter((s) => s.key.startsWith(notificationSettingPrefix))
    .forEach((s) => {
      const settingId = s.key.substring(notificationSettingPrefix.length);
      if (newState[s.module] && newState[s.module][settingId]) {
        newState[s.module][settingId].value = s.value === 'true';
      }
    });

  return newState;
};

const getMinimumRequiredRole = (notifications:Record<string, INotification>) => (
  ALL_ROLES[
    Object.keys(notifications)
      .reduce((agg, key) => (
        notifications[key].role
          ? Math.min(agg, ALL_ROLES.indexOf(notifications[key].role))
          : 99
      ), 99)
  ] as Role
);

interface INotificationState {
  enabled: boolean,
  notifications: Record<number, Record<string, INotification>>,
  busy: Record<number, Record<string, boolean>>,
}

export const NotificationSettingsTab = () => {
  const { hasModuleRole } = useAccount();
  const { customerModules, getModuleNameOrDefault } = useModules();

  const [state, setState] = useState<INotificationState>();

  const userNotificationAsText = useGetUserNotificationAsText();

  const { data: settings, invalidate } = useApiLoaderData<IUserSetting[], IUserSetting[]>(
    'account/settings',
    (loaderData) => loaderData,
  );

  interface INotificationSetting {
    moduleId:Module,
    key:string,
    value:string
  }

  const setSettingsBusy = useCallback((items:{key:string, moduleId:Module}[], busy:boolean) => {
    if (!state) return;

    const newState = {
      ...state,
    };

    items.forEach(({ key, moduleId }) => {
      newState.busy = newState.busy ?? {};
      const mBusy = newState.busy[moduleId] ?? {};
      mBusy[key] = busy;
      newState.busy[moduleId] = mBusy;
    });

    setState(newState);
  }, [state]);

  const saveMultiple = useCallback(async (updatedSettings:INotificationSetting[]) => {
    if (!state) return;

    setSettingsBusy(updatedSettings, true);

    try {
      await axios.put(
        'api/v1/account/settings',
        updatedSettings,
      );
      await invalidate();
    } catch (err) {
      toast.error(`Unable to update notification settingss: ${err}`);
    } finally {
      setSettingsBusy(updatedSettings, false);
    }
  }, [invalidate, setSettingsBusy, state]);

  const save = useCallback(async (moduleId:number, settingKey:string, value:boolean|null) => {
    if (!state) return;

    setState({
      ...state,
      busy: {
        ...state.busy,
        [`${moduleId}`]: { [settingKey]: true },
      },
    });

    try {
      if (value === null) {
        await axios.delete(
          `api/v1/account/setting/${moduleId}/${notificationSettingPrefix}${settingKey}`,
        );
      } else {
        await axios.put(
          `api/v1/account/setting/${moduleId}/${notificationSettingPrefix}${settingKey}`,
          {
            value: value ? 'true' : 'false',
          },
        );
      }
      await invalidate();
    } catch (err) {
      toast.error(`Unable to update notification settings: ${err}`);
    } finally {
      setState({
        ...state,
        busy: {
          ...state.busy,
          [`${moduleId}`]: { [settingKey]: false },
        },
      });
    }
  }, [invalidate, state]);

  useEffect(() => {
    if (!customerModules || !settings) {
      return;
    }

    const hasAnyNotificationSettings = settings.find((s) => s.key.startsWith(notificationSettingPrefix) && s.value === 'true');
    const enabled = settings.find((s) => s.key === `${notificationSettingPrefix}enabled`)?.value === 'true';

    setState({
      enabled,
      busy: { },
      notifications: initializeFromSettings(
        getDefaults(customerModules, !hasAnyNotificationSettings),
        settings,
      ),
    });
  }, [customerModules, settings]);

  const vulnerabilityModuleIds = useMemo(() => (
    customerModules
      .filter((m) => m.jobTypeKeys?.includes('scan') && VulnerabilityModules.includes(m.id))
      .map((m) => m.id)
  ), [customerModules]);

  const hasValue = useCallback((moduleId:number, settingId:string) => {
    if (!state) return false;
    return moduleId === 0
    || state.notifications[moduleId][settingId].value !== null;
  }, [state]);

  const isChecked = useCallback((moduleId:number, settingId:string) => {
    if (!state) return false;
    if (moduleId === Module.none) {
      return state.notifications[moduleId][settingId]?.value ?? false;
    }
    if (vulnerabilityModuleIds.includes(moduleId)) {
      return (state.notifications[moduleId][settingId].value === null
        ? state.notifications[Module.vulnerability][settingId]?.value
        : state.notifications[moduleId][settingId].value) ?? false;
    }
    return state.notifications[moduleId][settingId]?.value ?? false;
  }, [state, vulnerabilityModuleIds]);

  const isBusy = useCallback((moduleId:number, settingId:string) => {
    if (!state) return false;
    return (state.busy[moduleId] && (state.busy[moduleId][settingId] || state.busy[moduleId].all))
    || (state.busy[Module.none] && (state.busy[Module.none][settingId] || state.busy[Module.none].all));
  }, [state]);

  const vulnerabilityModuleOrder = [
    Module.vulnerability,
    ...vulnerabilityModuleIds,
  ];

  return !state
    ? <Spinner animation="border" />
    : (
      <>
        <div>
          <Form.Check
            label="Enable notifications"
            checked={state.enabled}
            disabled={isBusy(Module.none, 'enabled')}
            id="enabled"
            onChange={async (e) => {
              const newState = {
                ...state,
                enabled: e.target.checked,
              };

              // If we're enabling, and no notifications are selected, select defaults
              const hasAnyNotificationSettings = settings.find((s) => s.key.startsWith(notificationSettingPrefix));
              if (newState.enabled && !hasAnyNotificationSettings) {
                const initialValues:INotificationSetting[] = [
                  {
                    key: `${notificationSettingPrefix}enabled`,
                    moduleId: Module.none,
                    value: newState.enabled ? 'true' : 'false',
                  },
                ];

                if (!settings.find((s) => s.key.startsWith(notificationSettingPrefix) && s.value === 'true')) {
                  newState.notifications = getDefaults(customerModules, true);
                }

                Object.entries(newState.notifications).forEach((moduleEntry) => {
                  Object.entries(moduleEntry[1]).forEach((settingEntry) => {
                    if (settingEntry[1].value) {
                      initialValues.push({
                        moduleId: parseInt(moduleEntry[0], 10),
                        key: `${notificationSettingPrefix}${settingEntry[0]}`,
                        value: settingEntry[1].value ?? false ? 'true' : 'false',
                      });
                    }
                  });
                });
                await saveMultiple(initialValues);
              } else {
                await save(Module.none, 'enabled', newState.enabled);
              }
            }}
          />
          { !state.enabled
            ? (
              <Alert variant="warning" className="p-3 mt-2">
                <div>
                  You will not receive any notifications from us.
                  <br />
                  To be notified about changes, enable notifications above
                  and select the events you are interested in.
                </div>
              </Alert>
            ) : null }
        </div>
        <div className={`notification-toggles ${state.enabled ? '' : 'text-muted' }`}>
          <fieldset className="pb-2 mb-4">
            <legend>Generic notifications</legend>
            { Object.keys(defaultGenericNotifications)
              .map((key) => (
                <Form.Check
                  id={`generic-${key}`}
                  key={key}
                  label={userNotificationAsText(Module.none, key)}
                  disabled={isBusy(Module.none, key) || !state.enabled}
                  checked={isChecked(Module.none, key)}
                  onChange={async (e) => {
                    const update = {
                      ...state?.notifications,
                    };

                    update[Module.none][key].value = e.target.checked;

                    await save(Module.none, key, update[Module.none][key].value);
                  }}
                />
              ))}
          </fieldset>
          { hasModuleRole(Module.risk, getMinimumRequiredRole(state.notifications[Module.risk]))
            ? (
              <fieldset className="pb-2 mb-4">
                <legend>
                  Risk notifications
                </legend>
                { ['opened', 'closed', 'status-change']
                  .filter((key) => (
                    state.notifications[Module.risk][key].role
                    && hasModuleRole(Module.risk, state.notifications[Module.risk][key].role)
                  ))
                  .map((key) => (
                    <Form.Check
                      id={`${Module.risk}-${key}`}
                      key={key}
                      label={userNotificationAsText(Module.risk, key)}
                      disabled={isBusy(Module.risk, key) || !state.enabled}
                      checked={isChecked(Module.risk, key)}
                      onChange={async (e) => {
                        const update = {
                          ...state?.notifications,
                        };

                        update[Module.risk][key].value = e.target.checked;

                        await save(Module.risk, key, update[Module.risk][key].value);
                      }}
                    />
                  ))}
              </fieldset>
            ) : null }
          <fieldset className="pb-2">
            <legend>
              Vulnerability notifications
            </legend>
            { vulnerabilityModuleOrder
              .map((moduleId) => {
                const entry = state.notifications[moduleId];

                if (moduleId !== Module.vulnerability && !vulnerabilityModuleIds.includes(moduleId)) {
                  return null;
                }

                if (moduleId !== 0 && !hasModuleRole(moduleId, getMinimumRequiredRole(entry))) {
                  return null;
                }

                return (
                  <div className={`${moduleId === Module.vulnerability ? 'mb-4' : 'mb-2'}`} key={moduleId}>
                    <Form.Label>
                      { moduleId === Module.vulnerability ? 'All modules' : getModuleNameOrDefault(moduleId) }
                    </Form.Label>
                    <Stack direction="vertical">
                      { Object.keys(defaultVulnerabilityNotifications)
                        .filter((key) => (
                          defaultVulnerabilityNotifications[key as vulnerabilitySettingIds]
                      && (
                        moduleId === Module.vulnerability
                        || (
                          !defaultVulnerabilityNotifications[key as vulnerabilitySettingIds].global
                            && defaultVulnerabilityNotifications[key as vulnerabilitySettingIds].role
                            && hasModuleRole(
                              moduleId,
                              defaultVulnerabilityNotifications[key as vulnerabilitySettingIds].role!,
                            )
                        )
                      )
                        ))
                        .map((settingId) => (
                          <Form.Check
                            key={settingId}
                            id={`${moduleId}-${settingId}`}
                            name={settingId}
                            disabled={isBusy(moduleId, settingId) || !state.enabled}
                            label={userNotificationAsText(moduleId, settingId)}
                            checked={isChecked(moduleId, settingId)}
                            className={`${hasValue(moduleId, settingId) ? '' : 'check-muted'}`}
                            onChange={async (e) => {
                              const defaultValue = state.notifications[Module.vulnerability][settingId];

                              const update = {
                                ...state.notifications,
                              };

                              update[moduleId][settingId].value = moduleId !== Module.vulnerability
                              && defaultValue.value === update[moduleId][settingId].value
                                ? null
                                : e.target.checked;

                              await save(moduleId, settingId, update[moduleId][settingId].value);
                            }}
                          />
                        )) }
                    </Stack>
                  </div>
                );
              })}
          </fieldset>
        </div>
      </>
    );
};
