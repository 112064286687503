import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PagedResult } from '../../types/PagedResult';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import {
  createPagedColumnHelper, PagedTable, TableCellDateFormatted, IPagedTableFilter,
} from '../../common/table';
import { useModules } from '../../providers/ModuleContext';
import { useAccount } from '../../providers/AccountContext';
import { Module } from '../../types/AccessTypes';
import { IIdentity, IIdentityListOptions } from './IdentitiesPage';
import { IKeyValue } from '../../types/Types';
import { allSignificances } from '../vulnerabilities/VulnerabilitiesTable';
import { compareSignificance } from '../vulnerabilities/Utils';
import { Significance } from '../vulnerabilities/Types';
import { useGetSignificanceAsText } from '../../utils/TranslationUtils';

const identityProvidingModules = [
  Module.entraId,
];

/**
 * Table for showing identities.
 */
export const IdentitiesTable = ({
  pagedIdentities,
  pagedFilters,
  isLoading,
}:{
  pagedIdentities?: PagedResult<IIdentity>,
  pagedFilters: IPagedTableFilter<IIdentityListOptions>,
  isLoading?: boolean
}) => {
  const navigate = useNavigate();
  const { getModuleNameOrDefault } = useModules();
  const { customerHasModule } = useAccount();
  const significanceAsText = useGetSignificanceAsText();

  const columnHelper = createPagedColumnHelper<IIdentity>();

  const { appendQuery } = pagedFilters;

  const assetModuleIds = useMemo(() => (
    identityProvidingModules.filter((m) => customerHasModule(m))
  ), [customerHasModule]);

  const columnDefs = React.useMemo(() => {
    const columns = [
      columnHelper.accessor('uniqueId', {
        header: 'Unique ID',
        cell: ({ value }) => (value ? <ClipboardCopy>{value}</ClipboardCopy> : null),
        disableColumnDefault: true,
        defaultHidden: true,
        updateFilterFn: (values: string[]) => {
          appendQuery({
            uniqueIds: values,
          });
        },
      }),
      {
        ...columnHelper.accessor('sourceModuleId', {
          header: 'Module',
          cell: ({ value }) => (value ? getModuleNameOrDefault(value) : null),
          filterPropertyName: 'sourceModuleId',
          formatter: (moduleId) => getModuleNameOrDefault(moduleId),
          defaultHidden: true,
          selectOptions: assetModuleIds,
          updateFilterFn: (values: string[]) => {
            const moduleId = values.length
              ? parseInt(values[0], 10)
              : undefined;
            appendQuery({
              sourceModuleId: Number.isNaN(moduleId) ? undefined : moduleId,
            });
          },
        }),
      },
      {
        ...columnHelper.accessor('name', {
          header: 'Name',
          cell: ({ value }) => <ClipboardCopy>{value}</ClipboardCopy>,
          filterPropertyName: 'name',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              name: values.length ? values[0] : undefined,
            });
          },
        }),
      },
      {
        ...columnHelper.accessor('email', {
          header: 'Email',
          cell: ({ value }) => <ClipboardCopy>{value}</ClipboardCopy>,
          filterPropertyName: 'email',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              email: values.length ? values[0] : undefined,
            });
          },
        }),
      },
      columnHelper.accessor('created', {
        header: 'Created',
        cell: ({ value }) => TableCellDateFormatted(value),
        disableFilter: true,
        filterPropertyName: 'created',
      }),
      columnHelper.accessor('significance', {
        header: 'Significance',
        cell: ({ value, row }) => significanceAsText(value ?? row.original.systemSignificance),
        formatter: (significance) => significanceAsText(significance),
        defaultHidden: true,
        selectOptions: allSignificances,
        sortFn: (a, b) => compareSignificance(a, b),
        updateFilterFn: (values: Significance[]) => {
          appendQuery({
            significance: values.length ? values : undefined,
          });
        },
      }),
      columnHelper.accessor('vulnerabilityCount', {
        header: 'Vulnerability count',
        cell: ({ value }) => TableCellDateFormatted(value),
        disableFilter: true,
        filterPropertyName: 'vulnerabilityCount',
      }),
      {
        ...columnHelper.accessor('properties', {
          header: 'Properties',
          cell: ({ value }) => (
            <ul>
              {value?.map((p) => (
                <li key={p.key}>
                  {p.key}
                  =
                  {p.value}
                </li>
              ))}
            </ul>
          ),
          defaultHidden: true,
          filterPropertyName: 'properties',
          updateFilterFn: (values: string[]) => {
            appendQuery({
              properties: values.length
                ? values[0].split(',').reduce((acc, pair) => {
                  const [key, value] = pair.split('=');
                  if (key && value) {
                    acc.push({ key: key.trim(), value: value.trim() });
                  }
                  return acc;
                }, [] as IKeyValue[])
                : undefined,
            });
          },
          filterFormatter: (values: unknown[]) => (values
            ? (values as IKeyValue[])
              .map((v) => `${v.key}=${v.value}`, '')
              .join(', ')
            : ''),
        }),
      },
    ];

    return columns;
  }, [columnHelper, assetModuleIds, appendQuery, getModuleNameOrDefault, significanceAsText]);

  const onRowClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, object: IIdentity) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/identity/${object?.id}`, '_blank');
      } else {
        navigate(`/identity/${object?.id}`);
      }
    }
  };

  return (
    <div>
      <PagedTable
        columnDefs={columnDefs}
        data={pagedIdentities}
        filters={pagedFilters}
        onRowClick={onRowClick}
        isLoading={isLoading}
      />
    </div>
  );
};
