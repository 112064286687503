import React, { RefObject, useCallback, useMemo } from 'react';
import {
  Badge, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import DOMPurify from 'dompurify';
import { IRisk, RiskResponse, RiskStatus } from '../../types/RiskTypes';
import {
  riskAsColorCssClassNames, isRiskManaged, asRiskSeverity,
} from './Utils';
import { useGetSeverityAsText, useGetSignificanceAsText, useRiskStatusAsText } from '../../utils/TranslationUtils';
import {
  compareSeverity, severityAsCssClassName, compareSignificance,
} from '../vulnerabilities/Utils';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountContext';
import { Severity, Significance } from '../vulnerabilities/Types';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { allSeverities, allSignificances } from '../vulnerabilities/VulnerabilitiesTable';
import {
  createPagedColumnHelper, PagedTable, useArrayAsPagedResult, TableCellDateFormatted, VisibilityState,
  IPagedTableFilter,
} from '../../common/table';

export interface IRiskFilters {
  riskId?:number,
  status?:RiskStatus,
  severity?:Severity,
  name?:string,
  responses?:RiskResponse[],
  owner?:string,
  impacts?:Significance[],
  probabilities?:Significance[],
}

interface IRiskRow extends IRisk {
  severity?: Severity
}

/**
 * React table for showing risks.
 */
export const RiskTable = ({
  openForm,
  risks:
  allRisks,
  defaultColumnVisibility,
  tableFilters,
}: {
  openForm: (risk: IRisk) => void,
  risks: IRisk[],
  defaultColumnVisibility: VisibilityState,
  tableFilters:IPagedTableFilter<IRiskFilters>,
}) => {
  const { hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const riskStatusAsText = useRiskStatusAsText();
  const severityAsText = useGetSeverityAsText();
  const significanceAsText = useGetSignificanceAsText();

  const { appendQuery } = tableFilters;

  const risks = useMemo(() => (
    allRisks.map((r) => (
      {
        ...r,
        severity: r.status === RiskStatus.Open
          ? asRiskSeverity(r)
          : undefined,
      }))
  ), [allRisks]);

  const refs = useMemo(
    () => (
      Object.values(risks).reduce(
        (acc, v) => {
          // eslint-disable-next-line no-param-reassign
          acc[v.riskId] = React.createRef<HTMLHeadingElement|null>();
          return acc;
        },
        {} as Record<string, RefObject<HTMLHeadingElement|null>>,
      )),
    [risks],
  );

  const columnHelper = createPagedColumnHelper<IRiskRow>();

  // Important: due to the way we initialize risk table columns, we need to transfer the
  // default visibility state to the defaultHidden property of our risk columns.
  const columns = useMemo(() => {
    const mColumns = [
      columnHelper.accessor(
        'riskId',
        {
          header: 'Risk ID',
          cell: ({ row, value }) => (
            <OverlayTrigger
              overlay={(
                <Tooltip>
                  <div className="status">
                    {row.original.status === RiskStatus.Closed
                      ? 'Risk is closed'
                      : 'Risk is open'}
                  </div>
                  {row.original.severity
                  && row.original.status === RiskStatus.Open
                    ? (
                      <div>
                        Severity:
                        {' '}
                        <Badge
                          style={{ fontSize: '0.7rem' }}
                          bg="none"
                          pill
                          className={`text-shadow ${severityAsCssClassName(
                            row.original.severity,
                          )}`}
                        >
                          {severityAsText(asRiskSeverity(row.original))}
                        </Badge>
                      </div>
                    ) : null}
                </Tooltip>
              )}
            >
              <Link to={`/risk/${row.original.riskId}`}>
                <div
                  id={row.original.riskId.toString()}
                  ref={refs[row.original.riskId]}
                  className="id-badge"
                >
                  <Badge
                    bg="none"
                    className={riskAsColorCssClassNames(row.original)}
                  >
                    {value}
                  </Badge>
                </div>
              </Link>
            </OverlayTrigger>
          ),
          disableHiding: true,
          filterPropertyName: 'riskId',
          updateFilterFn: (riskIds: number[]) => {
            appendQuery({
              riskId: riskIds.length ? riskIds[0] : undefined,
            });
          },
          isMatchFn: (value, filterValue) => {
            if (typeof filterValue !== 'string') return false;
            const filterValueAsInt = parseInt(filterValue, 10);
            return value === filterValueAsInt;
          },
        },
      ),
      columnHelper.accessor(
        'status',
        {
          header: 'Status',
          cell: ({ value }) => riskStatusAsText(value),
          formatter: (value: RiskStatus) => riskStatusAsText(value),
          defaultHidden: defaultColumnVisibility.status === false,
          updateFilterFn: (statuses: RiskStatus[]) => {
            appendQuery({
              status: statuses.length ? statuses[0] : undefined,
            });
          },
          selectOptions: [
            RiskStatus.Open,
            RiskStatus.Closed,
          ],
        },
      ),
      columnHelper.accessor(
        'severity',
        {
          header: 'Severity',
          cell: ({ row, value }) => (value && row.original.status === RiskStatus.Open ? (
            <Badge bg="none" className={severityAsCssClassName(value)}>
              {severityAsText(value)}
            </Badge>
          ) : null),
          formatter: (value?: Severity) => severityAsText(value),
          defaultHidden: defaultColumnVisibility.severity === false,
          optionsSortFn: compareSeverity,
          filterPropertyName: 'severity',
          updateFilterFn: (severities:Severity[]) => {
            appendQuery({
              severity: severities.length ? severities[0] : undefined,
            });
          },
          selectOptions: allSeverities,
          sortFn: (a, b) => compareSeverity(a, b),
        },
      ),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ({ value }) => DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }),
        defaultHidden: defaultColumnVisibility.name === false,
        updateFilterFn: (names:string[]) => {
          appendQuery({
            name: names.length ? names[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('impact', {
        header: 'Impact',
        cell: ({ value }) => (value ? significanceAsText(value) : null),
        defaultHidden: defaultColumnVisibility.impact === false,
        formatter: significanceAsText,
        optionsSortFn: compareSignificance,
        filterPropertyName: 'impacts',
        updateFilterFn: (impacts:Significance[]) => {
          appendQuery({
            impacts,
          });
        },
        selectOptions: allSignificances,
        supportMultiSelect: true,
        sortFn: (a, b) => compareSignificance(a, b),
      }),
      columnHelper.accessor('probability', {
        header: 'Probability',
        cell: ({ value }) => significanceAsText(value),
        defaultHidden: defaultColumnVisibility.probability === false,
        formatter: significanceAsText,
        optionsSortFn: compareSignificance,
        filterPropertyName: 'probabilities',
        updateFilterFn: (probabilities:Significance[]) => {
          appendQuery({
            probabilities,
          });
        },
        selectOptions: allSignificances,
        supportMultiSelect: true,
        sortFn: (a, b) => compareSignificance(a, b),
      }),
      columnHelper.accessor('owner', {
        header: 'Owner',
        cell: ({ value }) => (value ? <ClipboardCopy>{value}</ClipboardCopy> : null),
        defaultHidden: defaultColumnVisibility.owner === false,
        updateFilterFn: (owners:string[]) => {
          appendQuery({
            owner: owners.length ? owners[0] : undefined,
          });
        },
      }),
      columnHelper.accessor('response', {
        id: 'response',
        header: 'Response',
        defaultHidden: defaultColumnVisibility.response === false,
        filterPropertyName: 'responses',
        updateFilterFn: (responses:RiskResponse[]) => {
          appendQuery({
            responses,
          });
        },
        selectOptions: [
          RiskResponse.Pending,
          RiskResponse.Accept,
          RiskResponse.Mitigate,
          RiskResponse.Reduce,
          RiskResponse.Transfer,
        ],
        supportMultiSelect: true,
      }),
      columnHelper.accessor('created', {
        header: 'Created',
        cell: ({ value }) => TableCellDateFormatted(value),
        defaultHidden: defaultColumnVisibility.created === false,
        disableFilter: true,
      }),
      columnHelper.accessor('updated', {
        header: 'Updated',
        cell: ({ value }) => TableCellDateFormatted(value),
        defaultHidden: defaultColumnVisibility.updated === false,
        disableFilter: true,
      }),
    ];

    if (hasModuleRole(Module.riskUnmanaged, 'readWrite')) {
      mColumns.push(
        columnHelper.display({
          id: 'edit',
          header: '',
          cell: ({ row }) => (isRiskManaged(row.original) ? null : (
            <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
              <Button
                variant="link"
                className="p-0"
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  openForm(row.original);
                }}
              >
                <Icon name="edit-2" size="18px" />
              </Button>
            </OverlayTrigger>
          )),
          disableHiding: true,
        }),
      );
    }

    return mColumns;
  }, [
    columnHelper,
    defaultColumnVisibility.status,
    defaultColumnVisibility.severity,
    defaultColumnVisibility.name,
    defaultColumnVisibility.impact,
    defaultColumnVisibility.probability,
    defaultColumnVisibility.owner,
    defaultColumnVisibility.response,
    defaultColumnVisibility.created,
    defaultColumnVisibility.updated,
    significanceAsText,
    hasModuleRole,
    severityAsText,
    refs,
    appendQuery,
    riskStatusAsText,
    openForm,
  ]);

  const onRowClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, object: IRiskRow) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/risk/${object?.riskId}`, '_blank');
      } else {
        navigate(`/risk/${object?.riskId}`);
      }
    }
  }, [navigate]);

  const risksWithSeverity = useMemo(() => risks.map((r) => (
    {
      ...r,
      severity: r.status === RiskStatus.Open
        ? asRiskSeverity(r)
        : undefined,
    } as IRiskRow)), [risks]);

  const pagedResult = useArrayAsPagedResult(
    risksWithSeverity,
    tableFilters,
    columns,
  );

  return (
    <PagedTable
      data={pagedResult}
      filters={tableFilters}
      columnDefs={columns}
      onRowClick={onRowClick}
    />
  );
};
