import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ICustomerEssentials } from '../../types/AdminTypes';
import { EntityType } from '../../types/EntityTypes';
import { useApi } from '../../query/GenericQuery';
import { Setting } from '../../types/Types';
import { SettingsTable } from '../../components/SettingsTable';

export const AdminCustomerSettingsTab = ({ customer }:{customer:ICustomerEssentials}) => {
  const { data: settings, invalidate } = useApi<Setting[]>(
    `settings/${EntityType.Customer}/${encodeURIComponent(customer.id)}`,
  );

  return settings
    ? (
      <SettingsTable
        settings={settings}
        onChange={() => {
          invalidate();
        }}
      />
    )
    : <Spinner animation="border" />;
};
