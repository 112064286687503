import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  ICustomerModule, ICustomerUpdateModule, Module,
} from '../../types/AccessTypes';
import { useAdminPageRefresh } from '../../hooks';
import { AdminCustomer } from '../../types/AdminTypes';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import CustomerModuleSelector from './CustomerModuleSelector';
import { useAccount } from '../../providers/AccountContext';

export const AdminCustomerModulesTab = (props:{customer:AdminCustomer}) => {
  const { customer } = props;
  const { hasModuleRole, refreshAccess } = useAccount();
  const [customerUpdateModules, setCustomerUpdateModules] = useState<ICustomerUpdateModule[]>();
  const { refreshCustomers } = useAdminPageRefresh();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const invalidateCustomerDetails = useInvalidateQueries(`module/admin/customers/${encodeURIComponent(customer.id)}`);

  const {
    data: initialCustomerModules,
    invalidate: invalidateCustomerModules,
  } = useApi<ICustomerModule[]>(
    customer && `module/admin/customers/${customer?.id}/modules?includeExpired=true`,
  );

  useEffect(() => {
    if (initialCustomerModules) {
      setCustomerUpdateModules(initialCustomerModules.map((cm) => ({
        moduleId: cm.module.id,
        expires: cm.expires,
      })));
    }
  }, [initialCustomerModules]);

  const updateModules = useMutation({
    mutationFn: async (data:{customerUpdateModules:ICustomerUpdateModule[]}) => {
      await axios.put<ICustomerModule[]>(
      `/api/v1/module/admin/customers/${customer?.id}/modules`,
      data.customerUpdateModules,
      );

      setUnsavedChanges(false);

      toast.success('Customer modules are updated!', {
        toastId: 'module-update',
        updateId: 'module-update',
      });

      await refreshAccess();
      await refreshCustomers();
      await invalidateCustomerDetails();
      await invalidateCustomerModules();
    },
  });

  const onModulesSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (customerUpdateModules) {
      await updateModules.mutateAsync({ customerUpdateModules });
    }
  };

  return !customerUpdateModules
    ? <Spinner animation="border" />
    : (
      <Form onSubmit={onModulesSubmit}>
        <Row>
          <Col md={12}>
            { customerUpdateModules
              ? (
                <div>
                  <CustomerModuleSelector
                    customer={customer}
                    canBeAdmin={customer.canBeAdmin}
                    disabled={!hasModuleRole(Module.admin, 'readWrite')}
                    customerModules={customerUpdateModules}
                    onChange={(updatedModules) => {
                      setCustomerUpdateModules(updatedModules);
                      setUnsavedChanges(true);
                    }}
                  />
                </div>
              )
              : <Spinner animation="border" /> }
          </Col>
        </Row>
        { hasModuleRole(Module.admin, 'readWrite')
          ? (
            <Row>
              <Col sm={12}>
                <Button type="submit" disabled={!unsavedChanges}>
                  Save
                </Button>
              </Col>
            </Row>
          )
          : null }
      </Form>
    );
};

export default AdminCustomerModulesTab;
