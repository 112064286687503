import React, { createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { IAvailableCustomerData } from '../pages';
import { useApi, useInvalidateQueries } from '../query/GenericQuery';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';
import { useLogout } from './BearerTokenProvider';

const noData:IAvailableCustomerData = {
  hasConsents: false,
  hasRisks: false,
  hasAssets: false,
  hasIdentities: false,
  hasVulnerabilities: false,
  hasVulnerabilityScanJobs: false,
  hasVulnerabilityTrends: false,
  hasOpenSecurityAdvisories: false,
  hasAssignedVulnerabilities: false,
  hasAdminInformationReceivers: true,
};

const availableDataPath = 'module/customer/availableData';

const context = createContext<IAvailableCustomerData>(noData);

export const useAvailableCustomerData = () => useContext(context);

export const useInvalidateAvailableCustomerData = () => (
  useInvalidateQueries(availableDataPath)
);

export const AvailableCustomerDataProvider = ({ children }:{children:React.ReactElement}) => {
  const {
    data: availableCustomerData,
    error,
    status,
    isLoading,
  } = useApi<IAvailableCustomerData>(
    availableDataPath,
    undefined,
    {
      refetchOnWindowFocus: true,
      retry: false,
    },
  );

  const logout = useLogout();
  const axiosError = error as AxiosError;

  if (status === 'error' && axiosError?.response?.status === 403) {
    return (
      <NoSidebarTemplate>
        <Card className="card-sm">
          <Card.Header>
            <Card.Title>
              Unauthorized
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={12} className="mb-3">
                You are not allowed to access this resource.
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button onClick={logout}>
                  Log out
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </NoSidebarTemplate>
    );
  }

  return isLoading
    ? <LoadingTemplate>Loading customer data</LoadingTemplate>
    : (
      <context.Provider value={availableCustomerData ?? noData}>
        { children }
      </context.Provider>
    );
};
