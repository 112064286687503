import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useAccount } from '../providers/AccountContext';

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutDropdownItem = () => {
  const { logout } = useAccount();

  return (
    <NavDropdown.Item onClick={logout}>
      <Icon name="log-out" size={18} className="me-2" />
      Sign out
    </NavDropdown.Item>
  );
};

export default SignOutDropdownItem;
