import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import { IHaveSignificance } from '../types/AssetsTypes';
import { Significance } from '../pages/vulnerabilities/Types';
import { useGetSignificanceAsText } from '../utils/TranslationUtils';

export const FormSignificanceSelector = ({
  item,
  onChange,
}:{
  item:IHaveSignificance
  onChange: (event:React.ChangeEvent) => void
}) => {
  const significanceAsText = useGetSignificanceAsText();

  return (
    <div>
      <Form.Select
        value={item.significance}
        name="significance"
        onChange={onChange}
      >
        <option value="">
          {`Default (${(item.systemSignificance !== Significance.Medium ? significanceAsText(item.systemSignificance) : 'Neutral')})`}
        </option>
        { item.significance === Significance.VeryLow || item.significance === Significance.VeryHigh
          ? (
            <option value={item.significance}>
              {significanceAsText(item.significance)}
              {' *'}
            </option>
          )
          : null }
        <option value={Significance.Low}>{significanceAsText(Significance.Low)}</option>
        <option value={Significance.Medium}>Neutral</option>
        <option value={Significance.High}>{significanceAsText(Significance.High)}</option>
      </Form.Select>
      { item.significance === Significance.VeryLow || item.significance === Significance.VeryHigh
        ? (
          <Alert variant="info" className="p-2 mt-1">
            Entity is using a custom significance which cannot be reset after being unselected.
          </Alert>
        ) : null }
    </div>
  );
};
