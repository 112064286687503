import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Formik } from 'formik';
import {
  Button, Form, OverlayTrigger, Spinner, Table, Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import {
  Role, IHaveModuleRoles, Module, ICustomerModule,
} from '../../types/AccessTypes';
import { filterProperties } from '../../utils/Utils';
import { useGetRoleAsText } from '../../utils/TranslationUtils';
import { useApi } from '../../query/GenericQuery';
import { useAccount } from '../../providers/AccountContext';

interface IModulePermissionFormItemsProps {
  customerModules: ICustomerModule[],
  moduleRoles:Record<number, Role>,
  onChange?: {
    (moduleRoles:Record<number, Role>): Promise<void>|void;
  },
  disabled?: ((moduleId:number) => boolean) | boolean | undefined,
  showHeaders?: boolean,
  effectiveRoles?: Record<number, Role>
}

const moduleSupportReadOwn = (moduleId:number) => moduleId === Module.vulnerability;

export const ModulePermissionsSelect = (
  props: IModulePermissionFormItemsProps,
) => {
  const {
    disabled, customerModules, moduleRoles, onChange, showHeaders, effectiveRoles,
  } = props;

  const roleAsText = useGetRoleAsText();

  const isExplicitUserRole = (effectiveModuleRole:Role, userModuleRole:Role) => (
    effectiveModuleRole === userModuleRole
  );

  return (
    <Table size="sm" borderless>
      { showHeaders === true ? (
        <thead>
          <tr>
            <th>Module</th>
            <th>Account role</th>
            { effectiveRoles ? (
              <th>Effective role</th>
            ) : null }
          </tr>
        </thead>
      ) : null }
      <tbody>
        { customerModules
          ? customerModules.map((m) => {
            const moduleDisabled = typeof disabled === 'function' ? disabled(m.module.id) : disabled;
            return m.module.id > 0
              ? (
                <tr key={m.module.id}>
                  <td>
                    { m.module.name }
                  </td>
                  <td>
                    <Form.Select
                      disabled={moduleDisabled}
                      value={moduleRoles[m.module.id] ?? ''}
                      onChange={(e) => {
                        if (onChange) {
                          const newValue = { ...moduleRoles };
                          const role = e.target.value as Role;
                          if (role === 'none') {
                            delete newValue[m.module.id];
                          } else {
                            newValue[m.module.id] = role;
                          }
                          onChange(newValue);
                        }
                      }}
                    >
                      <option value="none">{roleAsText('none')}</option>
                      { moduleSupportReadOwn(m.module.id)
                        ? <option value="readOwn">{roleAsText('readOwn')}</option>
                        : null }
                      <option value="read">{roleAsText('read')}</option>
                      <option value="readWrite">{roleAsText('readWrite')}</option>
                      { m.module.id === Module.admin ? (<option value="admin">{roleAsText('admin')}</option>) : null }
                    </Form.Select>
                  </td>
                  { effectiveRoles ? (
                    <td>
                      { effectiveRoles[m.module.id] && (effectiveRoles[m.module.id] !== 'readOwn' || moduleSupportReadOwn(m.module.id))
                        ? (
                          <OverlayTrigger
                            overlay={(
                              <Tooltip>
                                { isExplicitUserRole(effectiveRoles[m.module.id], moduleRoles[m.module.id])
                                  ? 'Role is assiged directly to this user'
                                  : 'Role is assiged to the user from a user group'}
                              </Tooltip>
                            )}
                          >
                            <span>
                              { roleAsText(effectiveRoles[m.module.id])}
                              { ' '}
                              <Icon name={isExplicitUserRole(effectiveRoles[m.module.id], moduleRoles[m.module.id]) ? 'user' : 'users'} size={18} />
                            </span>
                          </OverlayTrigger>
                        )
                        : roleAsText(undefined) }
                    </td>
                  ) : null }
                </tr>
              )
              : null;
          })
          : <Spinner animation="border" />}
      </tbody>
    </Table>
  );
};

interface IEditModulePermissionsProps {
  entityId: string,
  entity: 'users' | 'groups',
  customerModules: ICustomerModule[],
  onSuccessCustom?: () => void,
  disabled?: boolean,
}

export const ModulePermissionsForm = (props: IEditModulePermissionsProps) => {
  const {
    onSuccessCustom, entityId, entity, disabled, customerModules,
  } = props;
  const { refreshAccess } = useAccount();

  const {
    data: haveModuleRoles,
    invalidate: invalidateModuleRoles,
  } = useApi<IHaveModuleRoles>(
    `module/admin/${entity}/${encodeURIComponent(entityId)}/permissions`,
  );

  const updateAccessLevels = useMutation({
    mutationFn: async (data: Record<number, Role>) => axios.put(
      `/api/v1/module/admin/${entity}/${entityId}/permissions`,
      filterProperties(data, (value:Role) => value === 'none'),
    ),
    onSuccess: async () => {
      toast.success('Module permissions have been saved');
      await invalidateModuleRoles();
      await refreshAccess();
      if (onSuccessCustom) onSuccessCustom();
    },
  });

  return !haveModuleRoles?.moduleRoles ? (<div />) : (
    <Formik
      initialValues={{
        moduleRoles: { ...haveModuleRoles?.moduleRoles },
        unsavedChanges: false,
      }}
      enableReinitialize
      onSubmit={async (values) => updateAccessLevels.mutateAsync(values.moduleRoles)}
    >
      {({
        values, handleSubmit, setFieldValue,
      }) => (
        <Form
          onSubmit={handleSubmit}
        >
          <ModulePermissionsSelect
            disabled={disabled}
            customerModules={customerModules}
            moduleRoles={values.moduleRoles}
            onChange={(moduleRoles) => {
              setFieldValue('moduleRoles', moduleRoles);
              setFieldValue('unsavedChanges', true);
            }}
          />
          {disabled ? null : (
            <Button type="submit" disabled={!values.unsavedChanges}>Update permissions</Button>
          )}
        </Form>
      )}
    </Formik>
  );
};
