import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  ITopicAndFunctionDto, IVulnerabilityTrend,
  SecurityLevel,
} from './Types';
import { getOrFetchFromApi, useApi } from '../../query/GenericQuery';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { VulnerabilityTrendChart } from './VulnerabilityTrendChart';
import { useAccount } from '../../providers/AccountContext';
import { Module } from '../../types/AccessTypes';
import { ITrendOptions } from './loader';

interface IData {
  trendItems: IVulnerabilityTrend[],
  topicMap: Record<string, ITopicAndFunctionDto>,
  options: ITrendOptions
}

interface IParams {
  topic: string|null|undefined
}

export const topicSearchParamName = 'topic';
const trendSearchParamName = 'trend_id';

const initialTrendOptions:ITrendOptions = {
  granularity: 'logarithmic',
  maxItems: 100,
  sourceModules: [],
};

export const VulnerabilityTrendPage:IComponentWithLoader<IData, IParams> = {
  loader: async (
    queryClient:QueryClient,
  ) : Promise<IData> => {
    const trendItems = await getOrFetchFromApi<IVulnerabilityTrend[], ITrendOptions>(
      queryClient,
      'vulnerabilityTrend',
      initialTrendOptions,
    );
    const topicMap = await getOrFetchFromApi<Record<string, ITopicAndFunctionDto>>(
      queryClient,
      'controls/ivolv/topics',
    );

    return {
      trendItems,
      topicMap,
      options: initialTrendOptions,
    };
  },
  Component: () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTrendItem, setSelectedTrendItem] = useState<IVulnerabilityTrend>();
    const { options: initialOptions } = useLoaderData() as Awaited<IData>;
    const [moduleIds, setModuleIds] = useState<number[]>(
      initialOptions.sourceModules ? initialOptions.sourceModules : [],
    );

    const options = useMemo(() => ({
      ...initialOptions,
      sourceModules: moduleIds,
    }), [initialOptions, moduleIds]);

    const { getCustomerSetting } = useAccount();
    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Advanced);

    const {
      data: loadedTrendItems,
      isLoading: isLoadingMetrics,
    } = useApi<IVulnerabilityTrend[], ITrendOptions>(
      'vulnerabilityTrend',
      options,
    );

    const [trendItems, setTrendItems] = useState<IVulnerabilityTrend[]>();

    useEffect(() => {
      if (trendItems && searchParams.get(trendSearchParamName)) {
        setSelectedTrendItem(
          trendItems.find((t) => t.id.toString() === searchParams.get(trendSearchParamName)),
        );
      }
    }, [searchParams, trendItems]);

    useEffect(() => {
      setTrendItems(loadedTrendItems);
    }, [loadedTrendItems]);

    const setActiveTrendItem = useCallback((trend:IVulnerabilityTrend|undefined) => {
      if (!trend) {
        if (searchParams.has(trendSearchParamName)) {
          searchParams.delete(trendSearchParamName);
        }
      } else {
        searchParams.set(trendSearchParamName, trend.id.toString());
      }
      setSearchParams(searchParams);
      setSelectedTrendItem(trend);
    }, [searchParams, setSearchParams]);

    return (
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                Vulnerability trend
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  { trendItems && !isLoadingMetrics
                    ? (
                      <VulnerabilityTrendChart
                        selectedItem={selectedTrendItem}
                        securityLevel={customerSecurityLevel}
                        onItemSelected={setActiveTrendItem}
                        onActiveModulesUpdated={setModuleIds}
                        useModuleFilter
                        trendItems={trendItems}
                      />
                    )
                    : <Spinner animation="border" /> }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  },
};

export default VulnerabilityTrendPage;
