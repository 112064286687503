import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import axios, { AxiosError } from 'axios';
import {
  Button, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Bounce, Flip, toast } from 'react-toastify';
import { useAccount } from '../providers/AccountContext';
import { useApi, useInvalidateQueries } from '../query/GenericQuery';
import { Module } from '../types/AccessTypes';
import { IErrorDetails, IJob, JobStatus } from '../types/Types';
import { useModuleConsent } from '../pages/customersettings/ConsentUpdateButton';
import { useModules } from '../providers/ModuleContext';

export const startVulnerabilityScan = async (
  module:Module,
  customerId:string|undefined,
  retrySeconds?:number|undefined,
) => {
  const { data: startedJob } = await axios.post<IJob>(
    `/api/v1/module/vulnerabilityJobs/customerStart${retrySeconds ? `?retrySeconds=${retrySeconds}` : ''}`,
    {
      moduleId: module,
      lookupKey: 'scan',
    },
    customerId
      ? {
        headers: {
          'algiz-customer-id': customerId,
        },
      }
      : undefined,
  );
  return startedJob.id;
};

export const useCanSync = (module:Module) => {
  const { customer, customerHasModule, hasModuleRole } = useAccount();
  return !!customer?.id
  && customerHasModule(module)
  && (hasModuleRole(Module.customerAdmin, 'readWrite') || hasModuleRole(module, 'readWrite'));
};

interface IProps {
  onComplete?: () => void,
  module:Module,
  label?:string|ReactElement|undefined|((busy:boolean) => ReactElement),
  size?: 'sm' | 'lg',
  className?: string|undefined,
  disabled?: boolean|undefined,
  customerId?: string
}

export const ScanVulnerabilitiesButton = (props:IProps) => {
  const {
    onComplete, module, label, size, className, disabled, customerId,
  } = props;

  const syncBtnId = `${module}-sync-btn`;
  const canSync = useCanSync(module);
  const { getModuleNameOrDefault } = useModules();

  const { getModuleConsent } = useModuleConsent();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');
  const invalidateRisks = useInvalidateQueries('risks');

  const invalidateRelatedQueries = useCallback(() => {
    invalidateVulnerabilities();
    invalidateRisks();
  }, [invalidateVulnerabilities, invalidateRisks]);

  const [hasSyncError, setHasSyncError] = useState(false);
  const [activeJobId, setActiveJobId] = useState<number|undefined>();
  const [syncClicked, setSyncClicked] = useState(false);

  const refetchInterval = 5000;

  const { data: job, error } = useApi<IJob>(
    activeJobId ? `jobs/${activeJobId}?notifyChanges=true` : undefined,
    undefined,
    {
      refetchInterval,
      enabled: canSync && !hasSyncError && !!activeJobId,
    },
  );

  useEffect(() => {
    if (error) {
      const axiosError = error as AxiosError;
      toast.warn(`Unable to get job (${axiosError.response?.status} ${axiosError.response?.statusText})`, {
        toastId: syncBtnId,
        updateId: syncBtnId,
      });
      setActiveJobId(undefined);
      setSyncClicked(false);
    }
  }, [error, syncBtnId]);

  useEffect(() => {
    if (error) {
      setHasSyncError(true);
      return;
    }

    setHasSyncError(false);

    if (!job) {
      return;
    }
    if (job.status === JobStatus.Failed) {
      setActiveJobId(undefined);
      setSyncClicked(false);
      toast.info(`${getModuleNameOrDefault(module)} vulnerabilities failed to complete successfully.`, {
        updateId: syncBtnId,
        toastId: syncBtnId,
        type: 'error',
        autoClose: 5000,
        transition: Bounce,
      });
      return;
    }
    if (job.status === JobStatus.Finalized) {
      setActiveJobId(undefined);
      setSyncClicked(false);
      invalidateRelatedQueries();
      if (onComplete) onComplete();
      // Hide in progress toast, and show completed toast after removal
      setTimeout(() => {
        toast.info(`${getModuleNameOrDefault(module)} update is complete.`, {
          updateId: syncBtnId,
          toastId: syncBtnId,
          type: 'success',
          autoClose: 5000,
          transition: Flip,
        });
      }, 1000);
      return;
    }

    toast.info(`${getModuleNameOrDefault(module)} update is in progress...`, {
      toastId: syncBtnId,
      updateId: syncBtnId,
      type: 'info',
      transition: Flip,
      autoClose: false,
      icon: <Spinner animation="border" />,
    });
  }, [job, error, onComplete, module, syncBtnId, getModuleNameOrDefault, invalidateRelatedQueries]);

  const triggerSync = async () => {
    setSyncClicked(true);
    try {
      setActiveJobId(await startVulnerabilityScan(module, customerId, 10));
      toast.info(`${getModuleNameOrDefault(module)} update is in progress...`, {
        toastId: syncBtnId,
        autoClose: false,
        icon: <Spinner animation="border" />,
      });
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError) {
        if (axiosError.response?.status === 409) {
          const errorDetails = axiosError.response?.data as IErrorDetails;
          if (errorDetails) {
            const details = errorDetails.errorDetails as {conflictingJobId:number};
            if (details && details.conflictingJobId) {
              toast.info(`Connected to active ${getModuleNameOrDefault(module)} vulnerabilities update...`, {
                toastId: syncBtnId,
                autoClose: refetchInterval + 5000,
              });
              setActiveJobId(details.conflictingJobId);
            }
          }
        } else {
          setSyncClicked(false);
          setHasSyncError(true);
          const errorObj = axiosError.response?.data as IErrorDetails;
          toast.warn(`Unable to start ${getModuleNameOrDefault(module)} scan: ${errorObj?.detail ?? axiosError.message}`, {
            toastId: syncBtnId,
            autoClose: refetchInterval + 5000,
          });
        }
      } else {
        toast.info(`${getModuleNameOrDefault(module)} update is in progress...`, {
          toastId: syncBtnId,
          autoClose: false,
          icon: <Spinner animation="border" />,
        });
      }
    }
  };

  useEffect(() => () => {
    toast.dismiss(syncBtnId);
  }, [syncBtnId]);

  const moduleConsent = getModuleConsent(module);
  const hasConsent = !moduleConsent || moduleConsent?.customer?.version;

  return (
    <OverlayTrigger
      overlay={(
        <Tooltip>
          { hasConsent
            ? (
              <span>
                Trigger
                {' '}
                {getModuleNameOrDefault(module)}
                {' '}
                vulnerability scan
              </span>
            )
            : (
              <span>
                Customer has not connected
                {' '}
                {getModuleNameOrDefault(module)}
              </span>
            ) }
        </Tooltip>
      )}
    >
      <span>
        <Button
          variant="secondary"
          onClick={async (e) => {
            e.preventDefault();
            await triggerSync();
          }}
          className={className}
          disabled={disabled || syncClicked || !!activeJobId || !hasConsent}
          size={size}
        >
          { typeof label === 'function'
            ? label(syncClicked || !!activeJobId)
            : (
              <>
                { label ?? `Refresh ${getModuleNameOrDefault(module)} vulnerabilities` }
                { syncClicked || !!activeJobId
                  ? <Spinner className="ms-2" animation="border" size="sm" />
                  : null }
              </>
            )}
        </Button>
      </span>
    </OverlayTrigger>
  );
};
