import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Tab } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { IAsset } from '../../types/AssetsTypes';
import ROUTES from '../../routing/Routes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import { IAccountDetails } from '../../providers/AccountContext';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { PropertiesTable } from '../../components/PropertiesTable';
import { ValidPageSizes } from '../../common/table/Pagination';
import { AssetDetailTab } from './AssetDetailTab';
import { AssetVulnerabilitiesTab } from './AssetVulnerabilitiesTab';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { EntityType } from '../../types/EntityTypes';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { StatefulTabs } from '../../components/StatefulTabs';

interface IData {
  asset: IAsset,
}

/**
 * React component (using hooks) for showing details about a given asset.
 */
export const AssetsDetailPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{id?:string}|undefined,
  ) => {
    const asset = await getOrFetchFromApi<IAsset>(
      queryClient,
      args?.id && `assets/${encodeURIComponent(args?.id)}`,
    );

    return {
      asset,
    };
  },
  Component: () => {
    const { id } = useParams();

    // TODO: Refactor to use https://nodejs.org/api/querystring.html
    const { data: asset } = useApiLoaderData<IAsset, IData>(
      id && `assets/${encodeURIComponent(id)}`,
      (loaderData) => loaderData.asset,
    );

    return (
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name={ROUTES.assets.icon} />
            {' '}
            {asset.name}
            <CardCloseBackButton />
          </Card.Title>
        </Card.Header>
        <Card.Body className="card-details-footer-margin">
          <StatefulTabs
            defaultActiveKey="details"
            className="mb-3"
            mountOnEnter
          >
            <Tab eventKey="details" title="Details">
              <AssetDetailTab asset={asset} />
            </Tab>
            { asset.properties?.length
              ? (
                <Tab eventKey="properties" title="Properties">
                  <PropertiesTable
                    entityId={asset.id}
                    properties={asset.properties}
                  />
                </Tab>
              ) : null }
            <Tab eventKey="vulnerabilities" title="Vulnerabilities">
              <AssetVulnerabilitiesTab asset={asset} />
            </Tab>
          </StatefulTabs>
          <EntityCardFooter
            timestamps={asset}
            entity={{ entityId: asset.id, entityType: EntityType.Asset }}
            visibleId={asset.friendlyId ?? asset.id}
            linkRoute={ROUTES.assets}
            additionalDetails={(
              <span>
                <span>
                  Id:
                  <ClipboardCopy className="ms-1 me-2">
                    {asset.id}
                  </ClipboardCopy>
                </span>
                { asset.friendlyId ? (
                  <span>
                    Unique ID:
                    <ClipboardCopy className="ms-1 me-2">
                      {asset.friendlyId}
                    </ClipboardCopy>
                  </span>
                ) : null}
              </span>
            )}
          />
        </Card.Body>
      </Card>
    );
  },
};

export default AssetsDetailPage;
