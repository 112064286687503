import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col, Form, ListGroup, ListGroupItem, Row, Spinner, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AdminCustomer, ICustomerEssentials } from '../../types/AdminTypes';
import CustomerSelector from './CustomerSelector';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { useAccount } from '../../providers/AccountContext';
import { Module } from '../../types/AccessTypes';

interface IProps {
  customer:AdminCustomer,
  associatedCustomers?:ICustomerEssentials[]
}

interface IData {
  canAssociate: boolean,
  associatedCustomers?: ICustomerEssentials[]
}

const getDataSummary = (data:IData) => ({
  canAssociate: data.canAssociate,
  associatedCustomers: (data.associatedCustomers ?? []).map((c) => c.id).sort(),
});

export const AdminCustomerAssociationsTab = (props:IProps) => {
  const { customer, associatedCustomers } = props;
  const { customer: activeCustomer, primaryCustomer, hasModuleRole } = useAccount();
  const initialState = {
    canAssociate: customer.canAssociate,
    associatedCustomers,
  };
  const [state, setState] = useState<IData>({
    canAssociate: customer.canAssociate,
    associatedCustomers,
  });
  const [busy, setBusy] = useState<boolean>(false);

  const invalidateCustomers = useInvalidateQueries('module/admin/customers');

  const { data: serviceProviders } = useApi<ICustomerEssentials[]>(
    customer && `module/admin/customers/${encodeURIComponent(customer.id)}/serviceProviders`,
  );

  useEffect(() => {
    setState((s) => ({
      ...s,
      associatedCustomers,
    }));
  }, [associatedCustomers]);

  const excludeCustomerIds = useMemo(() => {
    const mExcludeCustomerIds = [primaryCustomer.id];
    if (activeCustomer.id !== primaryCustomer.id) {
      mExcludeCustomerIds.push(activeCustomer.id);
    }
    if (state.associatedCustomers) {
      state.associatedCustomers.forEach((c) => mExcludeCustomerIds.push(c.id));
    }

    return mExcludeCustomerIds;
  }, [primaryCustomer.id, activeCustomer.id, state.associatedCustomers]);

  const removeItem = async (removeCustomer:ICustomerEssentials) => {
    if (!associatedCustomers) {
      return;
    }

    state.associatedCustomers = state.associatedCustomers
      ? state.associatedCustomers.filter((c) => c.id !== removeCustomer.id)
      : [];

    setState({ ...state });
  };

  const addCustomer = async (customerToAdd:AdminCustomer|undefined) => {
    if (!associatedCustomers || !customerToAdd) {
      return;
    }

    const newCustomers = (state.associatedCustomers ?? []).concat([customerToAdd]);
    // Make sure new item is sorted to match service query result
    newCustomers.sort((a, b) => a.name.localeCompare(b.name));

    setState({
      ...state,
      associatedCustomers: newCustomers,
    });
  };

  const toggleCanAssociate = async (canAssociate:boolean) => {
    setState({
      ...state,
      canAssociate,
    });
  };

  const saveMutation = useMutation({
    mutationFn: async () => {
      setBusy(true);
      await axios.put(`/api/v1/module/admin/customers/${customer.id}/association`, {
        canAssociate: state.canAssociate,
        customerIds: (state.associatedCustomers ?? []).map((c) => c.id),
      });
    },
    onSuccess: () => {
      if (initialState.canAssociate !== state.canAssociate) {
        toast.success(`Customer ${state.canAssociate ? 'has been enabled as a Managed Service Provider' : 'has been disabled as a Managed Service Provider'}`, {
          toastId: 'can-associate',
          updateId: 'can-associate',
        });
      } else {
        toast.success('Associated customers are updated.');
      }
      invalidateCustomers();
    },
    onSettled: () => {
      setBusy(false);
    },
  });

  const isDirty = JSON.stringify(getDataSummary(initialState)) !== JSON.stringify(getDataSummary(state));

  const hasWriteAccess = hasModuleRole(Module.admin, 'readWrite');

  return (
    <>
      <Row>
        { serviceProviders?.length
          ? (
            <Col md={12}>
              <Alert variant="info" className="p-3">
                <span>
                  Customer&apos;s service provider
                  {serviceProviders.length > 1 ? 's' : ''}
                  :
                  {' '}
                  <strong>
                    {serviceProviders.map((sp) => sp.name).join(', ')}
                  </strong>
                </span>
              </Alert>
            </Col>
          )
          : null }
        <Col md={6}>
          <Form.Check
            type="checkbox"
            id="canAssociate"
            label="Managed Service Provider (MSP)"
            checked={state.canAssociate}
            disabled={!hasWriteAccess}
            onChange={(e) => toggleCanAssociate(e.target.checked)}
          />
          <p className="mt-3">
            Managed Service Providers can be associated with their customers,
            giving them the ability to access their customers from their own
            tenant.
          </p>
        </Col>
        <Col md={6}>
          { hasWriteAccess
            ? (
              <div className="mb-3">
                <CustomerSelector
                  selectedCustomer={undefined}
                  onCustomerSelected={addCustomer}
                  placeholder="Select customer to manage..."
                  excludeCustomerIds={excludeCustomerIds}
                />
              </div>
            )
            : null }

          { state.associatedCustomers?.length
            ? (
              <>
                <p>Managed Service Provider for:</p>
                <ListGroup className="item-selector">
                  { state.associatedCustomers.map((associatedCustomer) => (
                    <ListGroupItem
                      className={customer.canAssociate ? '' : 'text-muted'}
                      key={associatedCustomer.id}
                    >
                      <span className={hasWriteAccess ? 'has-remove' : ''}>
                        {associatedCustomer.name}
                      </span>
                      { hasWriteAccess
                        ? (
                          <Button
                            className="remove"
                            onClick={() => removeItem(associatedCustomer)}
                          >
                            <Icon name="trash-2" size={20} />
                            <span className="visually-hidden">Clear</span>
                          </Button>
                        )
                        : null }
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </>
            ) : null }
        </Col>
      </Row>
      { hasWriteAccess
        ? (
          <Row>
            <Col md="12">
              <Stack direction="horizontal" gap={2}>
                <Button
                  disabled={!isDirty || busy}
                  onClick={async () => saveMutation.mutateAsync()}
                >
                  Save
                  { busy ? <Spinner animation="border" size="sm" className="ms-2" /> : null }
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setState(initialState)}
                  disabled={!isDirty || busy}
                >
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
        )
        : null }
    </>
  );
};
