import React, { useMemo } from 'react';
import {
  Col, Container, Modal, Row, Card,
} from 'react-bootstrap';
import {
  Link, useLoaderData,
} from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import {
  IControlAlias, IControlDetails, IVulnerability,
} from './Types';
import { useSecurityLevelAsText } from '../../utils/TranslationUtils';
import { getOrFetchFromApi, useApi } from '../../query/GenericQuery';
import { ControlVulnerabilitiesTable } from './VulnerabilitiesTable';
import ROUTES from '../../routing/Routes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import RenderHtml from '../../components/RenderHtml';
import { PagedResult } from '../../types/PagedResult';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { IAccountDetails } from '../../providers/AccountContext';
import { ValidPageSizes } from '../../common/table/Pagination';
import { IVulnerabilityListOptions } from '../../types/Types';
import { Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { PagedTable, useArrayAsPagedResult, createPagedColumnHelper } from '../../common/table';

interface IData {
  control:IControlDetails,
  controlId:string
}

interface IParams {
  id:string|undefined,
}

export const ControlPage:IComponentWithLoader<IData, IParams> = (
  {
    loader: async (
      queryClient:QueryClient,
      account:IAccountDetails,
      pageSize:ValidPageSizes,
      params?:IParams,
    ) : Promise<IData> => {
      const { id } = params ?? {};
      const control = await getOrFetchFromApi<IControlDetails>(
        queryClient,
        id && `controls/${encodeURIComponent(id)}`,
      );

      // If we're projecting an alias framework, fetch vulnerabilities from main library
      const controlId = control?.equivalents?.length
        ? control.equivalents.find((c) => c.frameworkFriendlyId === 'ivolv')?.id ?? control.id
        : control?.id;

      return {
        control,
        controlId,
      };
    },
    Component: () => {
      const securityLevelAsText = useSecurityLevelAsText();

      const { control, controlId } = useLoaderData() as Awaited<IData>;

      const pagedTableFilter = usePagedTableFilter<IVulnerabilityListOptions>(
        `control-${control.id}-vulnerabilities`,
        {},
        {
          initialQuery: {
            control: controlId,
            // status: [VulnerabilityStatus.Open],
          },
          initialSorting: [
            { property: 'severity', direction: Direction.desc },
          ],
        },
      );

      const { data: vulnerabilities } = useApi<PagedResult<IVulnerability>, IVulnerabilityListOptions>(
        'vulnerabilities',
        {
          ...pagedTableFilter.query,
          control: controlId,
        },
      );

      const { data: controlHasVulnerabilities } = useApi<PagedResult<IVulnerability>, IVulnerabilityListOptions>(
        'vulnerabilities',
        {
          control: controlId,
          pageSize: 1,
        },
      );

      const columnHelper = createPagedColumnHelper<IControlAlias>();

      const equivalentsColumns = useMemo(() => [
        columnHelper.accessor('frameworkFriendlyId', {
          header: 'Framework',
        }),
        columnHelper.accessor('frameworkVersion', {
          header: 'Version',
        }),
        columnHelper.accessor('friendlyId', {
          header: 'Control',
          disableHiding: true,
        }),
        columnHelper.accessor('name', {
          header: 'Name',
        }),
        columnHelper.accessor('securityLevel', {
          header: 'Security level',
          cell: ({ value }) => securityLevelAsText(value),
        }),
        columnHelper.display({
          id: 'link',
          cell: ({ row }) => (
            <Link to={`${ROUTES.control.uri}/${row.original.id}`} className="btn btn-light">
              <Icon size="20" name="arrow-right-circle" />
            </Link>
          ),
          className: 'text-end',
        }),
      ], [columnHelper, securityLevelAsText]);

      const equivalentsTableFilter = usePagedTableFilter<object>(
        'equivalents-table',
        {},
      );

      const equivalentsPagedResult = useArrayAsPagedResult(
        control.equivalents,
        equivalentsTableFilter,
        equivalentsColumns,
      );

      return (
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <Icon name={ROUTES.control.icon} />
                  {' '}
                  { control.friendlyId }
                  {' '}
                  { control.name }
                  <CardCloseBackButton />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={2} className="mb-3">
                    <div className="form-label">Friendly Id:</div>
                    <div className="form-control">{control.friendlyId}</div>
                  </Col>
                  <Col md={8} className="mb-3">
                    <div className="form-label">Name:</div>
                    <div className="form-control">{control.name}</div>
                  </Col>
                  <Col md={2} className="mb-3">
                    <div className="form-label">Security level:</div>
                    <div>
                      {securityLevelAsText(control.securityLevel)}
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="form-label">Function:</div>
                    <div className="form-control">{control.functionName}</div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <div className="form-label">Topic:</div>
                    <div className="form-control">{control.topicName}</div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <div className="form-label">Description:</div>
                    <RenderHtml className="form-control">
                      {control.description}
                    </RenderHtml>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  Vulnerabilties
                </Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <ControlVulnerabilitiesTable
                  control={control}
                  pagedVulnerabilities={vulnerabilities}
                  pagedTableFilter={pagedTableFilter}
                  hasVulnerabilities={!!controlHasVulnerabilities?.items.length}
                />
              </Card.Body>
            </Card>
          </Col>

          { !control?.equivalents || control?.equivalents.length === 0 ? null : (
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Associated frameworks
                  </Card.Title>
                </Card.Header>
                <Card.Body className="overflow-auto">
                  <Row>
                    <Modal.Title />
                    <Container>
                      <PagedTable
                        columnDefs={equivalentsColumns}
                        data={equivalentsPagedResult}
                        filters={equivalentsTableFilter}
                        disableFilters
                        disableColumnSelect
                      />
                    </Container>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}

        </Row>
      );
    },
  });

export default ControlPage;
