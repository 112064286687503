import React from 'react';
import { Card } from 'react-bootstrap';

export const SimpleCard = ({
  header,
  children,
}:{
  header:string,
  children:string|React.ReactElement|(string|React.ReactElement)[]
}) => (
  <Card className="card-sm">
    <Card.Header>
      <Card.Title className="mb-0">
        {header}
      </Card.Title>
    </Card.Header>
    <Card.Body>
      {children}
    </Card.Body>
  </Card>
);
