import React, { useMemo } from 'react';
import PagedTable, { createPagedColumnHelper } from './PagedTable';
import { IIdNameDescriptionDto } from '../../pages/vulnerabilities/Types';
import { Direction, usePagedTableFilter } from './PagedResultFilter';
import { useArrayAsPagedResult } from './useArrayAsPagedResult';

export const IdNameTable = <T extends IIdNameDescriptionDto>({
  items,
  onRowClick,
}:{
  items:T[],
  onRowClick?: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    row: T
  ) => void,
}) => {
  const tableFilter = usePagedTableFilter(
    'id-name-desc-table',
    {},
    {
      initialSorting: [
        { property: 'name', direction: Direction.asc },
      ],
    },
  );

  const { appendQuery } = tableFilter;
  const columnHelper = useMemo(() => createPagedColumnHelper<IIdNameDescriptionDto>(), []);

  const columns = useMemo(() => ([
    columnHelper.accessor('id', {
      header: 'Id',
      defaultHidden: true,
      updateFilterFn: (values:string[]) => {
        appendQuery({
          id: values?.length ? values[0] : undefined,
        });
      },
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      updateFilterFn: (values:string[]) => {
        appendQuery({
          name: values?.length ? values[0] : undefined,
        });
      },
    }),
  ]), [appendQuery, columnHelper]);

  const pagedItems = useArrayAsPagedResult(
    items,
    tableFilter,
    columns,
  );

  return (
    <PagedTable
      data={pagedItems}
      filters={tableFilter}
      columnDefs={columns}
      onRowClick={onRowClick}
    />
  );
};
