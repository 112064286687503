import React, { useMemo, lazy } from 'react';
import { IStringDictionary } from '../../types/Types';
import DefaultApexOptions from '../../utils/DefaultApexOptions';
import {
  IFriendlyIdNameDescriptionDto,
  ImplementationCount, ITopicAndFunctionDto, IVulnerability, SecurityLevel, VulnerabilityStatus,
} from './Types';
import { resolveVulnerabilityStatus, containsSecurityLevel } from './Utils';

const Chart = lazy(() => import('react-apexcharts'));

/**
 * Generate radio chart implementation percentage for the selected security level or all
 * security levels if `securityLevel` is not provided or `SecurityLevel.Unknown`.
 */
export const createImplementationPercentRadarData = (
  vulns: IVulnerability[]|undefined,
  securityLevel:SecurityLevel = SecurityLevel.Unknown,
) => {
  const stats = {} as IStringDictionary<ImplementationCount>;

  if (vulns === undefined) {
    return stats;
  }

  for (const vuln of vulns) {
    const functionName = vuln.control.topicShortName ?? vuln.control.functionName ?? vuln.control.function ?? 'Unknown';
    stats[functionName] = stats[functionName] ?? { total: 0, mitigated: 0, target: 0 };
    stats[functionName].total += 1;

    if (containsSecurityLevel(securityLevel, vuln.control.securityLevel)) {
      stats[functionName].target += 1;

      if (vuln.status === VulnerabilityStatus.Mitigated) {
        stats[functionName].mitigated += 1;
      }
      if (vuln.status === VulnerabilityStatus.Open) {
        const mitigatedStatus = resolveVulnerabilityStatus(vuln)
          .find((v) => v.status === VulnerabilityStatus.Mitigated);
        stats[functionName].mitigated += mitigatedStatus?.fraction ?? 0;
      }
    }
  }
  return stats;
};

/**
 * React component (using hooks) for displaying a M365 radar chart of implementation status.
 */
export const ImplementationPercentRadarChart = ({
  data,
  topics,
} : {
  data: IStringDictionary<ImplementationCount>,
  topics: Record<string, ITopicAndFunctionDto>
}) => {
  const { series, categories } = useMemo(() => {
    const mCategories:unknown[] = [];

    const functionMap:Record<string, IFriendlyIdNameDescriptionDto> = {};
    Object.values(topics).forEach((t) => { functionMap[t.function.friendlyId] = t.function; });

    const mSeries:{data:number[], name:string}[] = [];

    mSeries.push({ name: 'Implemented', data: [] });
    mSeries.push({ name: 'Security level target', data: [] });

    Object.values(functionMap).forEach((f) => {
      mCategories.push(f.name.split(' '));
      mSeries[0].data.push(
        Math.round(data[f.name] && data[f.name].mitigated > 0 ? data[f.name].mitigated / data[f.name].total * 100 : 0),
      );
      mSeries[1].data.push(
        Math.round(data[f.name] && data[f.name].target > 0 ? data[f.name].target / data[f.name].total * 100 : 0),
      );
    });

    return {
      categories: mCategories,
      series: mSeries,
    };
  }, [data, topics]);

  const options: ApexCharts.ApexOptions = useMemo(() => (
    {
      chart: {
        ...DefaultApexOptions.chart,
      },
      xaxis: {
        categories,
      },
      plotOptions: {
        radar: {
          offsetY: 20,
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        floating: true,
      },
      colors: ['#2c8', '#fb3'],
    }
  ), [categories]);

  return (
    <Chart
      options={options}
      series={series}
      type="radar"
      height="330"
    />
  );
};

export default ImplementationPercentRadarChart;
