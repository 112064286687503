import { IAsset } from './AssetsTypes';
import { IVulnerability } from '../pages/vulnerabilities/Types';
import { IUser } from './AccessTypes';

export enum EntityType {
  Risk ='risk_register',
  Asset = 'asset_register',
  Vulnerability = 'vulnerability',
  Customer = 'customer',
  User = 'user',
  Cve = 'cve',
  Software = 'software',
  Advisory = 'advisory',
  Identity = 'identity'
}

export interface IHaveEntityId {
  entityType:EntityType,
  entityId:string|number,
}

export interface ITaggedEntity extends IHaveEntityId {
  customerId:string|undefined,
  name:string,
}

export const assetAsEntity = (entity:IAsset) : IHaveEntityId => (
  { entityId: entity.id, entityType: EntityType.Asset }
);
export const vulnAsEntity = (entity:IVulnerability) : IHaveEntityId => (
  { entityId: entity.id.toString(), entityType: EntityType.Vulnerability }
);

export interface IEntityComment extends IHaveEntityId {
  id:string,
  author:IUser,
  created:Date,
  updated?:Date|undefined,
  comment:string
}

export interface IEntityUpdateComment extends IHaveEntityId {
  comment:string
}
