/* eslint-disable class-methods-use-this */
import { create } from 'zustand';
import {
  persist, PersistStorage, StorageValue,
} from 'zustand/middleware';
import Queue from 'queue';
import { useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  IAssessmentControl, IAssessmentQuestion, IAssessmentSyncAsset, IAssessmentSyncVulnerability,
} from './AssessmentTypes';
import { IStringDictionary } from '../../types/Types';
import { ICompany, Module } from '../../types/AccessTypes';

interface IAssessmentStackItem {
  questionIndex:number[],
  vulnerabilityKey?:string
}

export enum Actions {
  default,
  resumeQuestion
}

export interface IState {
  index:number[],
  current:{
    action:Actions,
    params?:unknown,
  },
  currentControl?:IAssessmentControl,
  currentQuestion?:IAssessmentQuestion,
  vulnerabilities:IStringDictionary<IAssessmentSyncVulnerability[]>,
  answers:IStringDictionary<IAssessmentQuestion & {answer:string}>
  assets:IAssessmentSyncAsset[],
  stack:IAssessmentStackItem[],
  skipped:Record<number, number[][]>,
  // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-check-if-my-store-has-been-hydrated
  hasHydrated:boolean,
  hasStateSaveError:boolean,
}

export interface IStateOperations {
  clear: () => void,
  update:(newState:IState) => void,
  // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-check-if-my-store-has-been-hydrated
  setHasHydrated:(hydrated:boolean) => void
  setHasStateSaveError:(hasError:boolean) => void
}

export const createEmptyState = () => (
  {
    index: [] as number[],
    current: {
      action: Actions.default,
    },
    vulnerabilities: {} as IStringDictionary<IAssessmentSyncVulnerability[]>,
    answers: {} as IStringDictionary<IAssessmentQuestion & {answer:string}>,
    assets: [],
    stack: [],
    skipped: {},
    hasHydrated: false,
    hasStateSaveError: false,
  } as IState
);

interface ISetting {
    value: string|undefined
}

class UserSettingStorage implements PersistStorage<IState & IStateOperations> {
  private p_queue:Queue;

  constructor(queue:Queue) {
    this.p_queue = queue;
  }

  async getItem(name: string) : Promise<StorageValue<IState & IStateOperations>|null> {
    const { data: setting } = await axios.get<ISetting>(
      `/api/v1/account/setting/${Module.assessment}/${encodeURIComponent(name)}`,
    );
    return setting?.value ? JSON.parse(setting.value) : null;
  }

  async setItem(name: string, value: StorageValue<IState & IStateOperations>) : Promise<void> {
    this.p_queue.push(async () => {
      try {
        await axios.put(
          `/api/v1/account/setting/${Module.assessment}/${encodeURIComponent(name)}`,
          { value: JSON.stringify(value) },
        );
      } catch {
        toast.error('Unable to save assessment answer.', {
          toastId: 'assessment-state-save-error',
        });
        if (!value.state.hasStateSaveError) {
          value.state.setHasStateSaveError(true);
        }
      }
    });
  }

  async removeItem(name: string) : Promise<void> {
    this.p_queue.push(async () => axios.delete(
      `/api/v1/account/setting/${Module.assessment}/${encodeURIComponent(name)}`,
    ));
  }
}

const stores: Record<string, ReturnType<ReturnType<typeof create<IState & IStateOperations>>>> = {};

export const useAssessmentStateStore = (customer?:ICompany, assessmentSha512?:string) : IState & IStateOperations => {
  // Zustand does no synchronization of async storage persisters.
  // Adding a no-concurrency queue to prevent state updates from being stored
  // in an incorrect order. This was an initial problem encountered when
  // switching to database backed zustand store.
  const queueRef = useRef<Queue>(new Queue({
    concurrency: 1,
    autostart: true,
  }));

  useEffect(() => {
    const queue = queueRef.current;

    return () => {
      if (queue) queue.end();
    };
  }, []);

  const key = `${customer?.id ?? 'no-customer'}_assessment_${assessmentSha512 ?? 'unknown'}`;

  if (!stores[key]) {
    const store = create<IState & IStateOperations>()(
      persist(
        (set) => ({
          ...createEmptyState(),
          hasStateSaveError: false,
          setHasStateSaveError: (hasError:boolean) => {
            set({
              hasStateSaveError: hasError,
            });
          },
          hasHydrated: false,
          setHasHydrated: (hydrated:boolean) => {
            set({
              hasHydrated: hydrated,
            });
          },
          clear: () => {
            set({
              ...createEmptyState(),
              // Since clearing does not involve dehydration/hydration, we need to set the
              // hydrated flag as the UI will not render until its hydrated.
              hasHydrated: true,
            });
          },
          update: (newState:IState) => set(() => (newState)),
        }),
        {
          name: key,
          onRehydrateStorage: () => (state) => {
            if (state) {
              state.setHasHydrated(true);
            }
          },
          storage: new UserSettingStorage(queueRef.current),
        },
      ),
    );

    stores[key] = store;
  }

  return stores[key]();
};
