import React from 'react';
import {
  Card, Tab, Tabs,
} from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import CustomerUserManagementTab from './CustomerUserManagementTab';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import Microsoft365Tab from './Microsoft365Tab';
import CustomerDetailsTab from './CustomerDetailsTab';
import CustomerNotificationTab from './CustomerNotificationTab';
import { ICustomerSettingsCustomer } from './CustomerTypes';
import { Module } from '../../types/AccessTypes';
import MailAuthenticity from './MailAuthenticityTab';
import { useAccount } from '../../providers/AccountContext';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ConnectedServices } from '../../components/ConnectedServices';
import { CustomerReporting } from './CustomerReporting';
import { ICustomerEssentials } from '../../types/AdminTypes';

interface IParams {
    customer: ICustomerSettingsCustomer,
    serviceProviders: ICustomerEssentials[]
}

/**
 * React component for the Customer settings page (and tabs)
 *
 * Shows Customer info tab, Users info tab and risk categories (tab) for the customer
 */
export const CustomerSettingsPage:IComponentWithLoader<IParams, undefined> = {
  loader: async (queryClient:QueryClient) => (
    {
      customer: await getOrFetchFromApi<ICustomerSettingsCustomer>(
        queryClient,
        'module/customer',
      ),
      serviceProviders: await getOrFetchFromApi<ICustomerEssentials[]>(
        queryClient,
        'module/customer/serviceProviders',
      ),
    }
  ),
  Component: () => {
    const {
      hasModuleRole, customerHasModule,
    } = useAccount();

    const { data: customer } = useApiLoaderData<ICustomerSettingsCustomer, IParams>(
      'module/customer',
      (loaderData) => loaderData.customer,
    );

    const { data: serviceProviders } = useApiLoaderData<ICustomerEssentials[], IParams>(
      'module/customer/serviceProviders',
      (loaderData) => loaderData.serviceProviders,
    );

    const [searchParams, setSearchParams] = useSearchParams();

    const setTab = (tab:string|null) => {
      if (!tab) return;
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tab,
      });
    };

    const activeTab = searchParams.get('tab') ?? 'details';

    return (
      <Card>
        <Card.Body>
          <Tabs
            className="mb-3"
            defaultActiveKey={activeTab}
            mountOnEnter
            onSelect={(t) => setTab(t)}
          >
            <Tab className="default-form-layout" eventKey="details" title="Customer details">
              <CustomerDetailsTab customer={customer} />
            </Tab>
            { customer.notificationEmails.length
              ? (
                <Tab className="default-form-layout" eventKey="notifications" title="Notification">
                  <CustomerNotificationTab customer={customer} />
                </Tab>
              )
              : null }
            { hasModuleRole(Module.customerAdmin, 'read') ? (
              <Tab eventKey="users" title="Users">
                <CustomerUserManagementTab />
              </Tab>
            ) : null}
            <Tab className="default-form-layout" eventKey="services" title="Connected services">
              <ConnectedServices />
            </Tab>
            { hasModuleRole(Module.vulnerability, 'read') && hasModuleRole(Module.reporting, 'read') ? (
              <Tab eventKey="reporting" title="Reporting">
                <CustomerReporting />
              </Tab>
            ) : null}
            { customerHasModule(Module.m365) || customerHasModule(Module.entraId) ? (
              <Tab eventKey="m365" title="Microsoft">
                <Microsoft365Tab />
              </Tab>
            ) : null}
            { hasModuleRole(Module.mailAuthenticity, 'read') ? (
              <Tab eventKey="mailAuthenticity" title="Mail authenticity">
                <MailAuthenticity />
              </Tab>
            ) : null}
          </Tabs>
        </Card.Body>
        { serviceProviders.length
          ? (
            <Card.Footer>
              Associated organizations:
              {' '}
              <strong>{serviceProviders.map((sp) => sp.name).join(', ')}</strong>
            </Card.Footer>
          )
          : null }
      </Card>
    );
  },
};

export default CustomerSettingsPage;
