import React, { useRef } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { PagedResult } from '../../types/PagedResult';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ValidPageSizes } from '../../common/table/Pagination';
import { convertSortingToSortBy, Direction, usePagedTableFilter } from '../../common/table/PagedResultFilter';
import { IKeyValue, ISupportProperties } from '../../types/Types';
import { IdentitiesTable } from './IdentitiesTable';
import { IHaveSignificance } from '../../types/AssetsTypes';
import { Significance } from '../vulnerabilities/Types';

export const securityAdvisoryParamName = 'advisory';

export interface IIdentity extends IHaveSignificance, ISupportProperties {
  id:string,
  sourceModuleId: number,
  uniqueId?:string,
  name?:string,
  email?:string,
  created: Date,
  updated?: Date,
  manager?: IIdentity,
  vulnerabilityCount?: number
}

export interface IIdentityListOptions {
  name?:string,
  email?:string,
  manager?:string,
  uniqueIds?:string[],
  sourceModuleId?:number,
  properties?:IKeyValue[],
  significance?:Significance[]
}

interface IData {
  identities: PagedResult<IIdentity>,
  options: IIdentityListOptions
}

const defaultSort = [
  { property: 'name', direction: Direction.asc },
];

export const IdentitiesPage:IComponentWithLoader<IData, IIdentityListOptions> = {
  loader: async (queryClient:QueryClient, _, pageSize:ValidPageSizes, options): Promise<IData> => (
    {
      identities: await getOrFetchFromApi<PagedResult<IIdentity>>(
        queryClient,
        'identities',
        {
          page: 1,
          pageSize,
          sortBy: convertSortingToSortBy(defaultSort),
        },
      ),
      options: options ?? {},
    }),
  Component: () => {
    const { options } = useLoaderData() as Awaited<IData>;
    const pagedTableFilter = usePagedTableFilter<IIdentityListOptions>(
      'identities-table',
      {},
      {
        initialQuery: options,
        initialSorting: defaultSort,
      },
    );

    const { data: pagedIdentities, isLoading } = useApiLoaderData<PagedResult<IIdentity>, IData>(
      'identities',
      (loaderData) => loaderData.identities,
      pagedTableFilter.query,
    );

    const scrollContainerRef = useRef(null);

    return (
      <div>
        <Row>
          <Col>
            <Card className="fill-content">
              <Card.Header>
                <Card.Title>Identities</Card.Title>
              </Card.Header>
              <Card.Body ref={scrollContainerRef} className="overflow-auto" id="assets-card">
                <IdentitiesTable
                  pagedIdentities={pagedIdentities}
                  pagedFilters={pagedTableFilter}
                  isLoading={isLoading}
                />
              </Card.Body>
            </Card>
            <ElementScrollRestoration targetId="assets-card" />
          </Col>
        </Row>
      </div>
    );
  },
};

export default IdentitiesPage;
