import React from 'react';
import {
  Button, Card, Col, Row, Stack,
} from 'react-bootstrap';
import { useLogout } from '../../providers/BearerTokenProvider';

export const SignupConfirmCard = () => {
  const logout = useLogout();

  return (
    <Card className="fill card-md">
      <Card.Header>
        <Card.Title>
          Thank you for signing up!
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          We&apos;ve sent you an activation email.
          <br />
          <br />
          Please click the link in the email to activate
          your account and start using the Ivolv Cybersecurity Platform.
        </p>
      </Card.Body>
      <div className="card-button-footer">
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="primary"
                autoFocus
                onClick={logout}
              >
                Continue
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
