import React from 'react';
import {
  Button, Form, Modal, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { IColumn } from './useTableModel';

/**
 * A Modal for selecting columns for out table.
 */
export const SelectColumnsModal = <TObject, TValue, TFilterValue, >({
  show,
  handleClose,
  resetColumnVisibility,
  columns,
}:{
  show: boolean,
  handleClose: () => void,
  resetColumnVisibility: () => void,
  columns: IColumn<TObject, TValue, TFilterValue>[]
}) => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleClose();
  };

  const onColumnReset = () => {
    resetColumnVisibility();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Select columns to view
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          onReset={onColumnReset}
        >
          <Stack direction="vertical" gap={1}>
            {columns.map((col) => {
              const isColFiltered = col.isFiltered;

              return col.canHide
                ? (
                  <Form.Check
                    key={`${col.id}-${col.isVisible}`}
                    checked={col.isVisible}
                    id={col.id}
                    label={(
                      <>
                        <span>
                          {col.header.name}
                        </span>
                        {isColFiltered ? <Icon name="filter" size="12" className="ms-1" /> : null }
                      </>
                    )}
                    onChange={() => col.toggleVisibility()}
                    disabled={isColFiltered}
                  />
                ) : null;
            })}
          </Stack>
          <br />
          <Stack direction="horizontal" gap={1}>
            <Button variant="danger" type="reset">Reset</Button>
            <Button type="submit">Close</Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SelectColumnsModal;
