import React from 'react';
import { Button } from 'react-bootstrap';
import { Severity } from './Types';
import { severityAsLighterCssClassName } from './Utils';
import { useGetSeverityAsText } from '../../utils/TranslationUtils';

export const CriticalityBar = ({
  criticalitySummary,
  isSelected,
  onClick,
}:{
  criticalitySummary:Record<Severity, number>
  isSelected?:(severity:Severity) => boolean,
  onClick?:(severity:Severity) => void
}) => {
  const severityAsText = useGetSeverityAsText();

  return (
    <div className="criticality-bar d-flex align-items-strech">
      { Object.values(Severity).map((s) => {
        if (s === Severity.Unknown) {
          return null;
        }

        return (
          <div
            key={s}
            className={`item text-top ${isSelected && isSelected(s) ? ' highlight' : ''} d-flex`}
          >
            <Button
              className={`${severityAsLighterCssClassName(s, false, 'text')}`}
              disabled={!criticalitySummary[s]}
              onClick={() => {
                if (onClick) onClick(s);
              }}
            >
              <div className={`background ${severityAsLighterCssClassName(s, true)}`} style={{ zIndex: 1 }} />
              <div style={{ zIndex: 2, position: 'relative' }}>
                <h3>{severityAsText(s)}</h3>
                <span>
                  { criticalitySummary[s] ?? 0 }
                </span>
              </div>
            </Button>
          </div>
        );
      })}
    </div>
  );
};
