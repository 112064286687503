import { createContext, useContext } from 'react';
import {
  IAccount, ICompany, Module, Role,
} from '../types/AccessTypes';
import { ICustomerEssentials } from '../types/AdminTypes';
import { SettingValue } from '../types/Types';

export interface IAccountDetails {
  user: IAccount,
  customer: ICompany,
  primaryCustomer: ICustomerEssentials,
  canAssociate: boolean,
  isAssociatedTo: ICustomerEssentials[]|undefined,
  customerSettings:Record<number, Record<string, SettingValue>>,
  getCustomerSetting:<T, >(module:Module, key:string, defaultValue:T) => T,
  hasOnlyModuleRole: (module:Module, role:Role) => boolean,
  hasAnyRole: boolean,
  hasModuleRole: (moduleId:number, requiredRole:Role) => boolean,
  hasAnyModuleRole: (moduleId:number) => boolean,
  customerHasModule: (moduleId:number) => boolean,
  refreshAccess:() => void,
  logout:() => void,
}

export const AccountContext = createContext<IAccountDetails|null>(null);

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('No AccountContext found when calling useAccount');
  }
  return context;
};
