import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routing/Routes';
import { useAccount } from '../../providers/AccountContext';

export const SignupCustomerExists = () => {
  const { customer } = useAccount();
  const navigate = useNavigate();

  return (
    <Card className="card-sm">
      <Card.Header>
        <Card.Title>
          Customer exists
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <p>
          You already have an active account with an existing customer
          {' '}
          {customer?.name}
          .
        </p>

        <Button
          onClick={() => navigate(ROUTES.dashboard.uri)}
        >
          Go to
          {' '}
          {ROUTES.dashboard.sidebar}
        </Button>
      </Card.Body>
    </Card>
  );
};
