import { createContext, useContext } from 'react';
import { IModule } from '../types/AccessTypes';

interface IModuleData {
  modules: IModule[],
  customerModules: IModule[],
  getModuleByIdOrUndefined: (moduleId:number) => IModule|undefined,
  getModuleNameOrDefault: (moduleId:number, defaultName?:string) => string|typeof defaultName;
}

export const ModuleContext = createContext<IModuleData|null>(null);

export const useModules = () => {
  const context = useContext(ModuleContext);
  if (!context) {
    throw new Error('No ModuleContext found when calling useModules');
  }
  return context;
};
