import React, { useCallback, useMemo } from 'react';
import {
  Alert,
  Button, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Setting } from '../types/Types';
import { Direction, usePagedTableFilter } from '../common/table/PagedResultFilter';
import { useArrayAsPagedResult, valueFilterWeakIncludes } from '../common/table/useArrayAsPagedResult';
import { Module } from '../types/AccessTypes';
import { createPagedColumnHelper, PagedTable } from '../common/table';
import { useNewModalContext } from '../providers/NewModalProvider';
import { useAccount } from '../providers/AccountContext';
import { useModules } from '../providers/ModuleContext';

interface ISettingFilter {
  module?:Module,
  key?:string,
  value?:string
}

const toastOptions = {
  toastId: 'accountSettingId',
  updateId: 'accountSettingId',
};

export const SettingsTable = ({
  settings,
  maxValueLength,
  onChange,
}:{
  settings:Setting[],
  maxValueLength?:number,
  onChange?: () => void,
}) => {
  const { hasModuleRole } = useAccount();
  const { getModuleNameOrDefault } = useModules();
  const { pushConfirm } = useNewModalContext();

  const columnHelper = useMemo(() => createPagedColumnHelper<Setting>(), []);

  const tableFilters = usePagedTableFilter<ISettingFilter>(
    'settings-table',
    {},
    {
      initialSorting: [
        { property: 'module', direction: Direction.asc },
        { property: 'key', direction: Direction.asc },
      ],
    },
  );

  const deleteSetting = useCallback(async (setting:Setting) => {
    if (await pushConfirm({
      title: 'Confirm delete',
      content: (
        <div>
          Are you sure you would like to delete this setting?
          <Alert variant="danger" className="mt-3 p-2">
            <div>
              Please make sure you know what you&apos;re doing!
              <br />
              Deleting a required setting might
              break beaviour.
            </div>
          </Alert>
        </div>
      ),
      okLabel: 'Yes, delete it',
      cancelLabel: "No, don't delete it",
    })) {
      await axios.delete(`/api/v1/module/admin/settings/${setting.id}`);
      toast.success('Setting was deleted', toastOptions);
      if (onChange) onChange();
    }
  }, [onChange, pushConfirm]);

  const { appendQuery } = tableFilters;

  const columns = useMemo(
    () => ([
      columnHelper.accessor('module', {
        header: 'Module',
        cell: ({ value }) => {
          const moduleId = value ?? 0;
          return moduleId === 0
            ? ''
            : getModuleNameOrDefault(moduleId);
        },
        isMatchFn: (module:Module, filterValue:unknown) => {
          const moduleName = getModuleNameOrDefault(module);
          return moduleName
            ? valueFilterWeakIncludes(moduleName, `${filterValue}`)
            : false;
        },
        updateFilterFn: (modules:Module[]) => {
          appendQuery({
            module: modules.length ? modules[0] : undefined,
          });
        },
        sortFn: (a:Module, b:Module) => {
          const moduleA = a as number;
          const moduleB = b as number;
          if (moduleA === 0 && moduleB === 0) {
            return 0;
          }
          if (moduleA === 0) {
            return -1;
          }
          if (moduleB === 0) {
            return 1;
          }
          const nameA = getModuleNameOrDefault(moduleA);
          const nameB = getModuleNameOrDefault(moduleB);
          if (nameA === nameB) return 0;
          return (nameA ?? '') < (nameB ?? '') ? -1 : 1;
        },
      }),
      columnHelper.accessor('key', {
        header: 'Key',
        cell: ({ value }) => (
          <span className="font-monospace small">
            { value }
          </span>
        ),
        updateFilterFn: (keys:string[]) => {
          appendQuery({
            key: keys.length ? keys[0] : undefined,
          });
        },
      }),
      columnHelper.accessor(
        'value',
        {
          header: 'Value',
          cell: ({ value }) => (!maxValueLength || value.length < maxValueLength
            ? (
              <span className="font-monospace small">
                { value }
              </span>
            ) : (
              <span className="text-muted small">
                (hidden due to size)
              </span>
            )),
          updateFilterFn: (values:string[]) => {
            appendQuery({
              value: values.length ? values[0] : undefined,
            });
          },
        },
      ),
      columnHelper.display({
        id: 'actions',
        header: '',
        disableHiding: true,
        cell({ row }) {
          return hasModuleRole(Module.admin, 'readWrite')
            ? (
              <div className="justify-content-end text-nowrap">
                <OverlayTrigger
                  overlay={<Tooltip>Delete setting</Tooltip>}
                >
                  <Button
                    variant="link"
                    onClick={() => deleteSetting(row.original)}
                  >
                    <Icon size="18" name="trash" />
                  </Button>
                </OverlayTrigger>
              </div>
            ) : null;
        },
      }),
    ]),
    [appendQuery, columnHelper, deleteSetting, getModuleNameOrDefault, hasModuleRole, maxValueLength],
  );

  const pagedResult = useArrayAsPagedResult(
    settings,
    tableFilters,
    columns,
  );

  if (!pagedResult) return <Spinner animation="border" />;

  return (
    <PagedTable
      columnDefs={columns}
      data={pagedResult}
      filters={tableFilters}
    />
  );
};
