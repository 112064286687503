import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, IconComponentProps } from '@ailibs/feather-react-ts';
import { copyClipboard } from '../utils/Utils';

export const ClipboardCopy = ({
  children,
  size,
  className,
  hideValue,
  iconName,
  tooltip,
}:{
  children:string|number|undefined|null,
  size?:string|number|undefined,
  className?:string,
  hideValue?:boolean,
  iconName?:IconComponentProps['name'],
  tooltip?:string
}) => (children
  ? (
    <OverlayTrigger
      overlay={<Tooltip>{ tooltip ?? 'Copy to clipboard' }</Tooltip>}
    >
      <Button
        variant="link"
        className={`clipboard-copy px-0 py-0 text-start ${className ?? ''}`}
        onMouseUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          copyClipboard(`${children}`, 'Copied');
        }}
      >
        { hideValue ? '' : children}
        <Icon name={iconName ?? 'copy'} className="ms-1" size={size ?? 16} />
      </Button>
    </OverlayTrigger>
  ) : null);
