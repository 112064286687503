import React, {
  useCallback, useEffect, useMemo, lazy, Suspense,
} from 'react';
import { Button } from 'react-bootstrap';
import { containsSecurityLevel, vulnerabilityStatusAsHexColor } from '../vulnerabilities/Utils';
import DefaultApexOptions from '../../utils/DefaultApexOptions';
import { getStringDateOnly } from '../../utils/StringUtils';
import {
  IVulnerabilityTrend, SecurityLevel, VulnerabilityStatus,
} from './Types';
import { useModules } from '../../providers/ModuleContext';
import { Module, VulnerabilityModules } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountContext';
import { useLocalStorage } from '../../utils/StorageUtils';

const Chart = lazy(() => import('react-apexcharts'));

export const VulnerabilityTrendChart = ({
  trendItems,
  height,
  compact,
  animations,
  selectedItem,
  onItemSelected,
  securityLevel,
  onActiveModulesUpdated,
  useModuleFilter,
}:{
  trendItems:IVulnerabilityTrend[],
  height?:string|number|undefined,
  compact?:boolean,
  animations?:boolean,
  securityLevel:SecurityLevel,
  selectedItem?:IVulnerabilityTrend|undefined,
  onItemSelected?:(item:IVulnerabilityTrend|undefined) => void,
  onActiveModulesUpdated?:(moduleIds:number[]) => void,
  useModuleFilter?:boolean
 }) => {
  const { modules } = useModules();
  const { customer, customerHasModule } = useAccount();

  const selectableModules = useMemo(() => {
    if (!modules) return undefined;
    return modules.filter((m) => (
      customerHasModule(m.id)
      && VulnerabilityModules.includes(m.id) && (m.jobTypeKeys?.includes('scan') || m.id === Module.assessment)
    ));
  }, [customerHasModule, modules]);

  const hiddenModuleStorage = useLocalStorage<number[]>(`vuln-trend-exmodules-${customer.id}`, []);

  const activeModules = useMemo(() => (
    modules.map((m) => m.id).filter((id) => (
      customerHasModule(id) && (!hiddenModuleStorage.value || !hiddenModuleStorage.value.includes(id))
    ))
  ), [customerHasModule, hiddenModuleStorage.value, modules]);

  useEffect(() => {
    if (onActiveModulesUpdated) {
      onActiveModulesUpdated(activeModules);
    }
  });

  const updateModuleFilter = useCallback((filteredModuleIds:number[]) => {
    hiddenModuleStorage.setter(filteredModuleIds);
    if (onActiveModulesUpdated) {
      onActiveModulesUpdated(activeModules);
    }
  }, [activeModules, hiddenModuleStorage, onActiveModulesUpdated]);

  const onMarkerClick = useCallback((
    event: MouseEvent,
    _2: never,
    opt: { seriesIndex: number, dataPointIndex: number },
  ) => {
    // Ref. https://github.com/apexcharts/apexcharts.js/issues/1790#issuecomment-1021941450
    const item = trendItems ? trendItems[opt.dataPointIndex] : undefined;
    if (onItemSelected) {
      setTimeout(() => {
        if (!item || item.id === selectedItem?.id) {
          onItemSelected(undefined);
        } else {
          onItemSelected(item);
        }
      });
    }
  }, [onItemSelected, selectedItem?.id, trendItems]);

  const series = useMemo<ApexAxisChartSeries>(() => ([
    /* {
      name: 'Unknown',
      data: trendItems ? trendItems.map(({ details }) => (
        details.reduce((acc, d) => (
          containsSecurityLevel(securityLevel, d.securityLevel) ? acc + d.countStatusUnknown : acc
        ), 0)
      )) : [],
    }, */
    {
      name: 'Open',
      data: trendItems ? trendItems.map(({ details }) => (
        details.reduce((acc, d) => (
          containsSecurityLevel(securityLevel, d.securityLevel) ? acc + d.countStatusOpen : acc
        ), 0)
      )) : [],
    },
    /* {
      name: 'Mitigated',
      data: trendItems ? trendItems.map(({ details }) => (
        details.reduce((acc, d) => (
          containsSecurityLevel(securityLevel, d.securityLevel) ? acc + d.countStatusMitigated : acc
        ), 0)
      )) : [],
    }, */
  ]), [securityLevel, trendItems]);

  const options = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mOptions:any = {
      xaxis: {
        categories: trendItems
          ? trendItems.map(({ year, month, day }) => getStringDateOnly(new Date(year, month - 1, day)))
          : [],
        labels: {
          show: !compact,
        },
      },
      yaxis: {
        seriesName: 'Amount',
        labels: {
          show: !compact,
        },
      },
      colors: [
        // '#888',
        vulnerabilityStatusAsHexColor(VulnerabilityStatus.Open),
        // vulnerabilityStatusAsHexColor(VulnerabilityStatus.Mitigated),
      ],
      legend: {
        position: 'bottom',
      },
      stroke: {
        curve: 'smooth',
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        ...DefaultApexOptions.chart,
        events: {
          markerClick: onMarkerClick,
        },
        stacked: false,
        animations: {
          ...DefaultApexOptions.chart?.animations,
          enabled: !compact && animations !== false,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            zoom: false,
          },
        },
      },
      fill: {
        type: 'solid',
        opacity: 0,
      },
    };

    if (selectedItem && trendItems) {
      const item = trendItems.find((m) => m.id === selectedItem.id);
      const timestampAsString = item ? getStringDateOnly(new Date(item.year, item.month - 1, item.day)) : '';

      mOptions.annotations = {
        xaxis: [
          {
            x: timestampAsString,
            label: {
              text: timestampAsString,
              borderColor: '#86724d',
              style: {
                color: '#333',
                background: '#fff2da',
                fontSize: '13px',
              },
            },
          },
        ],
      };
    } else {
      mOptions.annotations = {
        xaxis: [],
      };
    }

    return mOptions;
  }, [trendItems, compact, onMarkerClick, animations, selectedItem]);

  return trendItems?.length
    ? (
      <>
        {useModuleFilter && selectableModules
          ? selectableModules.filter((m) => m.jobTypeKeys).map((m) => (
            <Button
              key={m.id}
              size="sm"
              onClick={(e) => {
                if (e.shiftKey) {
                  if (!hiddenModuleStorage.value || hiddenModuleStorage.value.includes(m.id)) {
                    updateModuleFilter(selectableModules.filter((mm) => mm.id !== m.id).map((mm) => mm.id));
                  } else {
                    updateModuleFilter(selectableModules.filter((mm) => mm.id === m.id).map((mm) => mm.id));
                  }
                } else {
                  const newValue = !hiddenModuleStorage.value || !hiddenModuleStorage.value.includes(m.id)
                    ? [...hiddenModuleStorage.value ?? [], m.id]
                    : hiddenModuleStorage.value.filter((id) => id !== m.id);
                  updateModuleFilter(newValue);
                }
              }}
              variant={hiddenModuleStorage.value
                ? hiddenModuleStorage.value.includes(m.id) ? 'secondary' : 'primary'
                : 'primary'}
              className="me-1 mb-1 px-2 badge rounded-pill toggle"
            >
              {m.name}
            </Button>
          ))
          : null }
        <Suspense fallback={<div style={{ height: `${height ?? 500}px` }} />}>
          <Chart
            height={height ?? 500}
            options={options}
            series={series}
            type="area"
          />
        </Suspense>
      </>
    )
    : 'There is no trend data.';
};
