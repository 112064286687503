import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createMsalConfig } from './authConfig';
import App from './App';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ConfigContextProvider, loadConfig } from './contexts/ConfigContext';
import SettingsProvider from './providers/SettingsProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

const maxAttempts = 10;
let loadAttempts = 0;

const initializeApplication = () => {
  loadConfig()
    .then((config) => {
      const msalInstance = new PublicClientApplication(createMsalConfig({
        redirectUri: config.AZURE_REDIRECT_URI,
      }));

      msalInstance.initialize().then(() => {
        const queryClient = new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false, // default: true
            },
          },
        });

        root.render(
          <React.StrictMode>
            <MsalProvider instance={msalInstance}>
              <ConfigContextProvider config={config}>
                <SettingsProvider>
                  <QueryClientProvider client={queryClient}>
                    <App />
                  </QueryClientProvider>
                </SettingsProvider>
              </ConfigContextProvider>
            </MsalProvider>
          </React.StrictMode>,
        );
      });
    })
    .catch(() => {
      if (loadAttempts < maxAttempts) {
        loadAttempts += 1;
        setTimeout(() => initializeApplication(), 1000);
      } else {
        // We were unable to load configuration

        const rootEl = document.getElementById('root');
        if (rootEl) {
          rootEl.innerHTML = '<div class="alert alert-warning mt-5 py-2 px-3 mx-auto w-50 text-center">'
                           + '  <div class="w-100">'
                           + '    <strong>Configuration could not be loaded</strong>'
                           + '    <br/><br/>Please reload to retry and contact support@ivolv.no if the problem persists.'
                           + '    <div class="mt-4 mb-3">'
                           + '      <button class="btn btn-primary" type="button" onclick="window.location.reload()">'
                           + '        Reload'
                           + '      </button>'
                           + '    </div>'
                           + '  </div>'
                           + '</div>';
        } else {
          // eslint-disable-next-line no-alert
          alert('Configuration could not be loaded. Please reload to retry and contact support@ivolv.no if the problem persists.');
        }
      }
    });
};

initializeApplication();
