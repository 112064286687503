import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createUnauthenticatedAlgizRouter } from './UnauthenticatedRouter';

export const UnauthenticatedApp = () => {
  const router = createUnauthenticatedAlgizRouter();

  return (
    <RouterProvider
      router={router}
    />
  );
};
