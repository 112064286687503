import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Tab } from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import ROUTES from '../../routing/Routes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import { IAccountDetails, useAccount } from '../../providers/AccountContext';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { PropertiesTable } from '../../components/PropertiesTable';
import { ValidPageSizes } from '../../common/table/Pagination';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { EntityType } from '../../types/EntityTypes';
import { ClipboardCopy } from '../../components/ClipboardCopy';
import { IIdentity } from './IdentitiesPage';
import { IdentityDetailTab } from './IdentityDetailTab';
import { IdentityAssetsTable } from '../assets/AssetsTable';
import { StatefulTabs } from '../../components/StatefulTabs';
import { Module } from '../../types/AccessTypes';
import { SimpleVulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';

interface IData {
  identity: IIdentity,
}

export const IdentityDetailPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{id?:string}|undefined,
  ) => {
    const identity = await getOrFetchFromApi<IIdentity>(
      queryClient,
      args?.id && `identities/${encodeURIComponent(args?.id)}`,
    );

    return {
      identity,
    };
  },
  Component: () => {
    const { id } = useParams();
    const { hasModuleRole } = useAccount();

    const { data: identity } = useApiLoaderData<IIdentity, IData>(
      id && `identities/${encodeURIComponent(id)}`,
      (loaderData) => loaderData.identity,
    );

    return (
      <Card>
        <Card.Header>
          <Card.Title>
            <Icon name={ROUTES.identities.icon} />
            {' '}
            {identity.name}
            <CardCloseBackButton />
          </Card.Title>
        </Card.Header>
        <Card.Body className="card-details-footer-margin">
          <StatefulTabs
            defaultActiveKey="details"
            className="mb-3"
            mountOnEnter
          >
            <Tab eventKey="details" title="Details">
              <IdentityDetailTab identity={identity} />
            </Tab>
            { identity.properties?.length
              ? (
                <Tab eventKey="properties" title="Properties">
                  <PropertiesTable
                    entityId={identity.id}
                    properties={identity.properties}
                  />
                </Tab>
              ) : null }
            { hasModuleRole(Module.assets, 'read') && identity.uniqueId
              ? (
                <Tab eventKey="assets" title="Assets">
                  <IdentityAssetsTable identityId={identity.id} />
                </Tab>
              ) : null }
            { hasModuleRole(Module.vulnerability, 'read') && identity.uniqueId
              ? (
                <Tab eventKey="vulnerabilities" title="Vulnerabilities">
                  <SimpleVulnerabilitiesTable baseQuery={{
                    identityModuleId: identity.sourceModuleId,
                    identityUniqueId: identity.uniqueId,
                  }}
                  />
                </Tab>
              ) : null }
          </StatefulTabs>
          <EntityCardFooter
            timestamps={identity}
            entity={{ entityId: identity.id, entityType: EntityType.Identity }}
            visibleId={identity.uniqueId ?? identity.id}
            linkRoute={ROUTES.assets}
            additionalDetails={(
              <span>
                <span>
                  Id:
                  <ClipboardCopy className="ms-1 me-2">
                    {identity.id}
                  </ClipboardCopy>
                </span>
                { identity.uniqueId ? (
                  <span>
                    Unique ID:
                    <ClipboardCopy className="ms-1 me-2">
                      {identity.uniqueId}
                    </ClipboardCopy>
                  </span>
                ) : null}
              </span>
            )}
          />
        </Card.Body>
      </Card>
    );
  },
};

export default IdentityDetailPage;
