import { createContext, useContext } from 'react';

export interface IBearerTokenData {
  token: string,
  userName: string,
  tenantId: string,
  expiresOn: Date|null
}

export interface IBearerTokenContext {
  tokenData: IBearerTokenData,
  refresh: () => void,
}

export const BearerTokenContext = createContext<IBearerTokenContext|undefined>(undefined);

export const useBearerTokenContext = () => {
  const context = useContext(BearerTokenContext);
  if (!context) {
    throw new Error('No BearerTokenContext found when calling useBearerTokenContext');
  }
  return context;
};
